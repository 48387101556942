import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Flex, Splitter, Tabs, Tag, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSegmentSummary } from '../../api/CodeFrames';
import { useMultipleResponseOptions } from '../../api/Questions';
import { useQuestions, useSurvey } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import {
  QCPhase,
  SegmentType,
  SurveyDashboardTabs,
  SurveyStatus,
  UserRoleType,
} from '../../constants';
import useParams from '../../hooks/useParams';
import useRoles from '../../hooks/useRoles';
import useSelfServeOrganization from '../../hooks/useSelfServeOrganization';
import ResponsesTab from './ResponsesTab';
import SegmentsSummaryPanel from './SegmentsSummaryPanel';
import SentimentsTab from './SentimentsTab';
import SummaryTab from './SummaryTab';
import ThemesTab from './ThemesTab';

const { Panel } = Splitter;
const { Title } = Typography;

const StyledSplitter = styled(Splitter)`
  && .ant-splitter-bar-collapse-bar {
    opacity: unset;
    font-size: 14px;
    width: 14px;
    height: 40px;
  }

  && .ant-splitter-bar-dragger:after {
    height: 40px;
  }
`;

const StyledDiv = styled.div`
  padding: 0px 24px 24px;
  height: 100%;
  overflow: auto;
`;

function SurveyDashboard() {
  const navigate = useNavigate();
  const { roles } = useRoles();
  const { isLoading: organizationLoading, selfServeEnabled } = useSelfServeOrganization();
  const { surveyId } = useParams();
  const [searchParams] = useSearchParams();
  const hasInitialized = useRef(false);

  const [activeTab, setActiveTab] = useState(SurveyDashboardTabs.SUMMARY);
  const [selectedQuestionId, setSelectedQuestionId] = useState(undefined);
  const [selectedSegmentQuestionId, setSelectedSegmentQuestionId] = useState(undefined);
  const [statAssistEnabled, setStatAssistEnabled] = useState(true);
  const [activeSegmentTypes, setActiveSegmentTypes] = useState([]);
  const [refetchInterval, setRefetchInterval] = useState(false);

  const {
    data: questions,
    isLoading: questionsLoading,
    refetch: refetchQuestions,
  } = useQuestions({ surveyId });

  const { data: survey, isLoading: surveyLoading } = useSurvey(
    { surveyId },
    {
      refetchInterval,
      onSuccess: (data) => {
        if (!refetchInterval && data.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS) {
          setRefetchInterval(5000);
        } else if (
          refetchInterval &&
          data.data.survey_status !== SurveyStatus.PUBLISH_IN_PROGRESS
        ) {
          refetchQuestions();
          setRefetchInterval(false);
        }
      },
    },
  );

  useEffect(() => {
    if (!questionsLoading && questions?.data && !hasInitialized.current) {
      hasInitialized.current = true;

      const questionId = parseInt(searchParams.get('questionId'), 10);
      if (questions.data.some((q) => q.has_themes && q.id === questionId)) {
        setSelectedQuestionId(questionId);
        setActiveTab(SurveyDashboardTabs.THEMES);
      } else {
        setSelectedQuestionId(questions.data.find((q) => q.has_themes)?.id);
      }
    }
  }, [questionsLoading, questions, searchParams]);

  const questionResponseOptions = useMultipleResponseOptions(
    questions?.data
      .filter((q) => q.is_filterable)
      .map((q) => ({
        questionId: q.id,
        surveyId,
        select: (data) => ({ ...data, questionId: q.id }),
      })),
  );

  const { data: segmentSummary, isLoading: segmentSummaryLoading } = useSegmentSummary(
    {
      codeFrameId: questions?.data.find((q) => q.id === selectedQuestionId)?.code_frame_id,
      segmentId: selectedSegmentQuestionId,
      surveyId,
    },
    {
      enabled: !!(selectedQuestionId && selectedSegmentQuestionId),
    },
  );

  const showSegmentSummary =
    activeTab === SurveyDashboardTabs.THEMES &&
    selectedSegmentQuestionId &&
    statAssistEnabled &&
    activeSegmentTypes.every((st) => st === SegmentType.ATTRIBUTE) &&
    questions?.data
      .find((q) => q.id === selectedQuestionId)
      ?.significant_segments.includes(selectedSegmentQuestionId);

  if (organizationLoading || surveyLoading || questionsLoading) {
    return <Loading />;
  }

  const tabData = [
    {
      key: SurveyDashboardTabs.SUMMARY,
      label: 'Summary',
      children: (
        <SummaryTab
          questions={questions}
          setActiveTab={setActiveTab}
          setSelectedOpenEndedQuestionId={setSelectedQuestionId}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.THEMES,
      label: 'Themes',
      children: (
        <ThemesTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
          questionResponseOptions={questionResponseOptions}
          selectedSegmentQuestionId={selectedSegmentQuestionId}
          setSelectedSegmentQuestionId={setSelectedSegmentQuestionId}
          statAssistEnabled={statAssistEnabled}
          setStatAssistEnabled={setStatAssistEnabled}
          highlightThemeIds={segmentSummary?.data?.theme_ids}
          setActiveSegmentTypes={setActiveSegmentTypes}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.SENTIMENTS,
      label: 'Sentiments',
      children: (
        <SentimentsTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
        />
      ),
    },
    {
      key: SurveyDashboardTabs.RESPONSES,
      label: 'Responses',
      children: (
        <ResponsesTab
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={setSelectedQuestionId}
          questionResponseOptions={questionResponseOptions}
        />
      ),
    },
  ];

  return (
    <StyledSplitter>
      <Panel defaultSize="70%" min="60%">
        <StyledDiv>
          {selfServeEnabled && roles.includes(UserRoleType.ADMINISTRATOR || UserRoleType.EDITOR) ? (
            <BackButton
              onClick={() => navigate(`/surveys/${surveyId}/hub`)}
              text="Back to Survey Hub"
            />
          ) : (
            <BackButton onClick={() => navigate('/')} text="Back to My Surveys" />
          )}
          <Flex gap={12} align="baseline">
            <Title level={4}>{survey.data.name}</Title>
            {questions.data.some((q) => q.has_themes && q.qc_phase !== QCPhase.COMPLETE) && (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                <strong>Work in progress!</strong> Coding isn&apos;t complete, and results may still
                shift.
              </Tag>
            )}
            {survey.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS && (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                <strong>Analysis underway!</strong> StatAssist and reporting are in progress and
                will appear once ready.
              </Tag>
            )}
          </Flex>
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            items={tabData}
            tabBarExtraContent={
              <Flex>
                {!selfServeEnabled && survey.data.survey_status === SurveyStatus.PUBLISHED && (
                  <Button type="primary" onClick={() => navigate(`/surveys/${surveyId}/hub`)}>
                    Survey Hub
                  </Button>
                )}
              </Flex>
            }
          />
        </StyledDiv>
      </Panel>
      {showSegmentSummary && (
        <Panel min="20%" collapsible>
          <SegmentsSummaryPanel
            question={questions?.data.find((q) => q.id === selectedQuestionId)}
            segmentQuestion={questions?.data.find((q) => q.id === selectedSegmentQuestionId)}
            summary={segmentSummary?.data}
            loading={segmentSummaryLoading}
            responseOptions={
              questionResponseOptions.find(
                (opt) => opt.data?.questionId === selectedSegmentQuestionId,
              )?.data.data
            }
          />
        </Panel>
      )}
    </StyledSplitter>
  );
}

export default SurveyDashboard;
