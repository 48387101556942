import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys, SURVEY_ID_HEADER } from '../constants';
import useFetch from './useFetch';

export function useUpdateDisplaySentiment() {
  const fetcher = useFetch();
  return ({ questionId, data }) =>
    fetcher({
      path: `v1/questions/${questionId}/update-display-sentiment/`,
      method: 'PATCH',
      data,
    });
}

export function useRawExport() {
  const { getAccessTokenSilently } = useAuth0();
  return async ({ queryKey }) => {
    const [, { questionId, filters, surveyId }] = queryKey;
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/questions/${questionId}/raw/${
        filters ? `?${new URLSearchParams(filters).toString()}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          [SURVEY_ID_HEADER]: surveyId,
        },
      },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return { body: await response.blob(), headers: response.headers };
  };
}

// CUSTOM REACT-QUERY HOOKS BELOW - WE SHOULD TRANSITION TO THESE

export function useAggregateResponses({ questionId, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.AGGREGATE_RESPONSES, { questionId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/responses/aggregate/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useQuestionResponses({ questionId, filters, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.QUESTION_RESPONSES, { questionId, filters, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/responses/`,
        filters,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useResponseOptions({ questionId, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.RESPONSE_OPTIONS, { questionId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/response-options/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useMultipleResponseOptions(queries = []) {
  const fetcher = useFetch();
  return useQueries({
    queries: queries.map(({ questionId, surveyId, ...queryOptions }) => ({
      queryKey: [QueryKeys.RESPONSE_OPTIONS, { questionId, surveyId }],
      queryFn: () =>
        fetcher({
          path: `v1/questions/${questionId}/response-options/`,
          headers: { [SURVEY_ID_HEADER]: surveyId },
        }),
      ...queryOptions,
    })),
  });
}

export function useQuestion({ questionId, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.QUESTION, { questionId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useSuggestedGroups({ questionId }) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SUGGESTED_GROUPS, { questionId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/suggested-groups/`,
      }),
  });
}

export function useUpdateQuestionPhase({ questionId, surveyId }) {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({
        path: `v1/questions/${questionId}/update-phase/`,
        method: 'PATCH',
        data: JSON.stringify(data),
      }),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.QUESTIONS, { surveyId }]);
      queryClient.invalidateQueries([QueryKeys.QUESTION, { questionId, surveyId }]);
    },
    onError: () => {
      message.error('Something went wrong updating question phase');
    },
  });
}

export function useRemapQuestion({ questionId, surveyId }) {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/remap/`,
        method: 'PUT',
      }),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.QUESTIONS, { surveyId }]);
      queryClient.invalidateQueries([QueryKeys.QUESTION, { questionId, surveyId }]);
    },
    onError: () => {
      message.error('Something went wrong remapping themes');
    },
  });
}

export function useSentiments({ questionId, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SENTIMENTS, { questionId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/sentiments/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useCodeFrame({ questionId, filters, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.CODE_FRAME, { questionId, filters, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/codeframe/`,
        filters,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useCodeFrames(queries = [], options = {}) {
  const fetcher = useFetch();
  return useQueries({
    queries: queries.map(({ questionId, filters, surveyId, hiddenThemes, ...queryOptions }) => ({
      queryKey: [QueryKeys.CODE_FRAME, { questionId, filters, surveyId, hiddenThemes }],
      queryFn: () =>
        fetcher({
          path: `v1/questions/${questionId}/codeframe/`,
          filters,
          headers: { [SURVEY_ID_HEADER]: surveyId },
        }),
      ...queryOptions,
      ...options,
    })),
  });
}

export function useUpdateQuestion({ surveyId }) {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ questionId, data }) =>
      fetcher({
        path: `v1/questions/${questionId}/update/`,
        method: 'PATCH',
        data: JSON.stringify(data),
      }),
    onSettled: (_data, _error, { questionId }) => {
      queryClient.invalidateQueries([QueryKeys.QUESTIONS, { surveyId }]);
      queryClient.invalidateQueries([QueryKeys.QUESTION, { questionId, surveyId }]);
    },
    onError: () => {
      message.error('Something went wrong updating question');
    },
  });
}

export function useCreateCodeFrame({ surveyId }) {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ questionId }) =>
      fetcher({
        path: `v1/questions/${questionId}/codeframe/create/`,
        method: 'POST',
      }),
    onSettled: (_data, _error, { questionId }) => {
      queryClient.invalidateQueries([QueryKeys.QUESTIONS, { surveyId }]);
      queryClient.invalidateQueries([QueryKeys.QUESTION, { questionId, surveyId }]);
      queryClient.invalidateQueries([QueryKeys.CODE_FRAME, { questionId, surveyId }]);
    },
    onError: () => {
      message.error('Something went wrong creating code frame');
    },
  });
}
