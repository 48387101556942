import {
  Button,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Typography,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUpdateQuestion } from '../../api/Questions';
import { useUpdateSurvey } from '../../api/Surveys';
import { SurveyTools, SurveyTypes } from '../../constants';
import { questionType, surveyType } from '../../types';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

function EditSurveyDrawer({ survey, questions, open, onClose }) {
  const [form] = Form.useForm();
  const [editedQuestions, setEditedQuestions] = useState({});

  const { mutateAsync: updateSurvey, isLoading: updateSurveyLoading } = useUpdateSurvey({
    surveyId: survey.id,
  });
  const { mutateAsync: updateQuestion, isLoading: updateQuestionLoading } = useUpdateQuestion({
    surveyId: survey.id,
  });

  useEffect(() => {
    if (open) {
      form.setFieldsValue(survey);
    }
  }, [open, form, survey]);

  const closeDrawer = () => {
    setEditedQuestions({});
    onClose();
  };

  const handleSave = async (formValues) => {
    try {
      const surveyPromise = updateSurvey({ data: formValues });
      const questionPromises = Object.keys(editedQuestions).map((questionId) =>
        updateQuestion({ questionId, data: { name: editedQuestions[questionId] } }),
      );
      await Promise.all([surveyPromise, ...questionPromises]);
      message.success('Survey details updated successfully');
    } catch {
      // error is handled in mutation hooks
    } finally {
      closeDrawer();
    }
  };

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      closable={false}
      width={570}
      title="Edit survey details"
      footer={
        <Space>
          <Button onClick={closeDrawer}>Cancel</Button>
          <Button
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  handleSave(values);
                })
                .catch(() => {});
            }}
            type="primary"
            loading={updateSurveyLoading || updateQuestionLoading}
            disabled={updateSurveyLoading || updateQuestionLoading}
          >
            Save changes
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Name is required.' }]}
        >
          <Input placeholder="How your survey will be labeled" />
        </Form.Item>
        <Form.Item label="Type" name="survey_type">
          <Select allowClear placeholder="Select">
            {SurveyTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Tool/Method" name="survey_tool">
          <Select allowClear placeholder="Select">
            {SurveyTools.map((tool) => (
              <Option key={tool} value={tool}>
                {tool}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Goals" name="survey_goals">
          <TextArea placeholder="Add research questions, goals and desired outcomes" />
        </Form.Item>
      </Form>
      <Flex vertical gap={8}>
        <Text strong>Edit Questions</Text>
        <div data-cy="question-list">
          {questions.map((question) => (
            <React.Fragment key={question.id}>
              <Text
                data-cy="edit-question"
                editable={{
                  onChange: (value) => {
                    setEditedQuestions({ ...editedQuestions, [question.id]: value });
                  },
                }}
              >
                {editedQuestions[question.id] || question.text}
              </Text>
              <StyledDivider />
            </React.Fragment>
          ))}
        </div>
        <Text strong>Need something else?</Text>
        <Text>
          To make advanced changes or to delete a survey,{' '}
          <a href="mailto:support@fathomthat.ai">contact us</a>
        </Text>
      </Flex>
    </Drawer>
  );
}

EditSurveyDrawer.propTypes = {
  survey: surveyType.isRequired,
  questions: PropTypes.arrayOf(questionType).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditSurveyDrawer;
