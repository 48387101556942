import { Flex, Form, Input, Select, Typography } from 'antd';
import PropTypes, { oneOfType } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ThemeEnginePanel } from '../../../constants';
import { Gray6 } from '../../../styles';
import { suggestedThemeType, themeType } from '../../../types';
import BaseModal from './BaseModal';

const { Text } = Typography;
const { TextArea } = Input;

const StyledForm = styled(Form)`
  padding: 0 12px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-extra {
    font-size: 12px;
    color: ${Gray6};
  }
`;

function MergeModal({ panel, visible, setVisible, onMerge, themes = [] }) {
  const [form] = Form.useForm();
  const selectedThemeId = Form.useWatch('themeId', form);

  const onThemeChange = (id) => {
    const { name, description } = themes.find((t) => t.id === id);
    form.setFieldsValue({
      name,
      description,
    });
  };

  return (
    <BaseModal
      title="Merge"
      open={visible}
      destroyOnClose
      closable={false}
      okText={panel === ThemeEnginePanel.EXPLORE_THEMES ? 'Merge and add' : 'Merge'}
      okDisabled={!selectedThemeId}
      onCancel={() => setVisible(false)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onMerge(values);
            setVisible(false);
          })
          .catch(() => {});
      }}
    >
      <StyledForm
        form={form}
        layout="vertical"
        autoComplete="off"
        preserve={false}
        requiredMark={false}
      >
        <Flex vertical gap={16}>
          <Form.Item
            label={<Text type="secondary">Select primary</Text>}
            name="themeId"
            extra="Select the theme that best matches the name and description you want"
            rules={[{ required: true, message: 'theme is required' }]}
          >
            <Select
              options={themes.map((t) => ({ label: t.name, value: t.id }))}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              onChange={onThemeChange}
              showSearch
            />
          </Form.Item>
          <Form.Item
            label={<Text type="secondary">Theme name</Text>}
            name="name"
            rules={[{ required: true, message: 'name is required' }]}
          >
            <Input type="text" disabled={!selectedThemeId} />
          </Form.Item>
          <Form.Item
            label={<Text type="secondary">Description</Text>}
            name="description"
            rules={[{ required: true, message: 'description is required' }]}
          >
            <TextArea
              rows={6}
              placeholder="Describe the theme in a sentence"
              disabled={!selectedThemeId}
            />
          </Form.Item>
        </Flex>
      </StyledForm>
    </BaseModal>
  );
}

MergeModal.propTypes = {
  panel: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired,
  themes: PropTypes.arrayOf(oneOfType([themeType, suggestedThemeType])),
};

export default MergeModal;
