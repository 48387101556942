import { Button, Card, Empty, Flex, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactFC from 'react-fusioncharts';
import { useCodeFrame, useSentiments } from '../../../api/Questions';
import Loading from '../../../components/Loading';
import { getSentimentsBarChart, getSentimentsDonutChart } from '../../../fusion-charts/ChartHelper';
import useParams from '../../../hooks/useParams';
import { queryType } from '../../../types';
import ManageThemesModal from '../ManageThemesModal';
import OpenEndedQuestionsSelect from '../OpenEndedQuestionsSelect';

function SentimentsTab({ questions, selectedQuestionId = undefined, setSelectedQuestionId }) {
  const { surveyId } = useParams();
  const openEndedQuestions = questions.data.filter((q) => q.has_themes);

  const [isManageThemesModalVisible, setManageThemesModalVisible] = useState(false);
  const [hiddenThemes, setHiddenThemes] = useState([]);
  const [barChartConfig, setBarChartConfig] = useState(undefined);

  const { data: codeFrame, isLoading: codeFrameLoading } = useCodeFrame(
    { questionId: selectedQuestionId, surveyId },
    {
      enabled: !!selectedQuestionId,
      select: (data) => {
        if (!data.data) {
          return { themes: [], response_count: 0 };
        }
        return data.data.themes_data;
      },
      onSuccess: (data) => {
        setBarChartConfig(getSentimentsBarChart(data.themes));
      },
      cacheTime: 0,
    },
  );

  const { data: sentiments, isLoading: sentimentsLoading } = useSentiments(
    { questionId: selectedQuestionId, surveyId },
    { enabled: !!selectedQuestionId },
  );

  useEffect(() => {
    if (codeFrame) {
      // Filter out themes that are not selected for display
      const visibleThemes = codeFrame.themes
        .filter((t) => !hiddenThemes.includes(t.data.id))
        .map((t) => {
          const children = t.children.filter((c) => !hiddenThemes.includes(c.data.id));
          return { ...t, children };
        });
      const { dataSource } = getSentimentsBarChart(visibleThemes);
      setBarChartConfig((prev) => ({ ...prev, dataSource }));
    }
  }, [codeFrame, hiddenThemes]);

  const handleChangeQuestion = (questionId) => {
    // Reset the bar chart to prevent issues with populating the chart
    setBarChartConfig(undefined);
    setSelectedQuestionId(questionId);
  };

  if (codeFrameLoading || sentimentsLoading) {
    return <Loading />;
  }

  return (
    <Flex vertical gap={16}>
      <OpenEndedQuestionsSelect
        openEndedQuestions={openEndedQuestions}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={handleChangeQuestion}
      />
      {questions.data.find((q) => q.id === selectedQuestionId)?.display_sentiment ? (
        <>
          <Card>
            <ReactFC
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getSentimentsDonutChart({
                caption: openEndedQuestions.find((q) => q.id === selectedQuestionId).name,
                sentiments: sentiments.data,
              })}
            />
          </Card>
          {codeFrame.themes.length > 0 && (
            <>
              <Row justify="end">
                <Button size="small" onClick={() => setManageThemesModalVisible(true)}>
                  Manage themes
                </Button>
              </Row>
              <Card>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <ReactFC {...barChartConfig} />
              </Card>
              <ManageThemesModal
                themes={codeFrame.themes}
                saveThemes={setHiddenThemes}
                hiddenThemes={hiddenThemes}
                isModalVisible={isManageThemesModalVisible}
                setIsModalVisible={setManageThemesModalVisible}
                codeFrameId={questions.data.find((q) => q.id === selectedQuestionId).code_frame_id}
                surveyId={surveyId}
              />
            </>
          )}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Sentiment analysis has been hidden for this question. Please use the Summary Tab to enable sentiment analysis, or contact your organization's administrator."
        />
      )}
    </Flex>
  );
}

SentimentsTab.propTypes = {
  questions: queryType.isRequired,
  selectedQuestionId: PropTypes.number,
  setSelectedQuestionId: PropTypes.func.isRequired,
};

export default SentimentsTab;
