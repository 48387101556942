import { Empty, Flex, Typography } from 'antd';
import he from 'he';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useQuestionResponses } from '../../../api/Questions';
import SearchableTable from '../../../components/SearchableTable';
import { SegmentType } from '../../../constants';
import useParams from '../../../hooks/useParams';
import { queryType } from '../../../types';
import OpenEndedQuestionsSelect from '../OpenEndedQuestionsSelect';
import SegmentResponsesCard from './SegmentResponsesCard';

const { Text } = Typography;

const StyledSearchableTable = styled(SearchableTable)`
  .ant-table-tbody > tr > td {
    border: none;
  }
`;

const StyledResponseText = styled(Text)`
  margin-right: 8px;
`;

function ResponsesTab({
  questions,
  questionResponseOptions,
  selectedQuestionId = undefined,
  setSelectedQuestionId,
}) {
  const { surveyId } = useParams();
  const [segments, setSegments] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const openEndedQuestions = questions.data.filter((q) => q.has_themes);
  const RESPONSE_SEGMENTS_CACHE_KEY = `responseSegments${selectedQuestionId}`;

  const filters = useMemo(() => {
    const params = new URLSearchParams(segments.find((s) => s.active)?.segmentParams || '');
    params.set('with_theme_ids', 'true');
    return params.toString();
  }, [segments]);

  const { data: responses, isFetching: responsesLoading } = useQuestionResponses(
    {
      questionId: selectedQuestionId,
      filters,
      surveyId,
    },
    {
      enabled: !!selectedQuestionId,
      onSuccess: (data) => {
        if (!segments.length) {
          setSegments([
            {
              id: uuidv4(),
              active: true,
              text: `All Respondents (${data.data.length})`,
              segmentParams: null,
            },
          ]);
        } else if (segments.find((s) => s.active).newlyCreated) {
          // if the segment is new add response count to text and remove newlyCreated
          const updatedSegments = segments.map((s) => {
            if (s.active) {
              const { newlyCreated, ...rest } = s;
              return { ...rest, text: `${s.text} (${data.data.length})` };
            }
            return s;
          });
          setSegments(updatedSegments);
        }
      },
    },
  );

  useEffect(() => {
    const cachedItems = JSON.parse(localStorage.getItem(RESPONSE_SEGMENTS_CACHE_KEY));
    setSegments(cachedItems || []);
  }, [selectedQuestionId, RESPONSE_SEGMENTS_CACHE_KEY]);

  useEffect(() => {
    localStorage.setItem(RESPONSE_SEGMENTS_CACHE_KEY, JSON.stringify(segments));
  }, [segments, RESPONSE_SEGMENTS_CACHE_KEY]);

  // whenever the raw data changes (e.g., clicking on a different segment)
  // set page back to 1
  useEffect(() => {
    setPage(1);
  }, [responses]);

  // whenever page changes, scroll back to top
  useEffect(() => {
    window.scroll(0, 0);
  }, [page]);

  const saveSegment = async (selectedChoices) => {
    const segmentText = Object.keys(selectedChoices).flatMap((key) => selectedChoices[key]);
    const params = new URLSearchParams();
    Object.keys(selectedChoices).forEach((key) => {
      if (selectedChoices[key]?.length) {
        params.append(key, selectedChoices[key].join('_$SEPARATOR$_'));
      }
    });
    const newSegment = {
      id: uuidv4(),
      active: true,
      text: `${segmentText.join(', ')}`,
      segmentParams: params.toString(),
      segmentType: SegmentType.CUSTOM,
      newlyCreated: true,
    };
    const updatedSegments = segments.map((s) => ({ ...s, active: false })).concat([newSegment]);
    setSegments(updatedSegments);
  };

  const toggleSegment = (id) => {
    const updatedSegments = segments.map((s) => ({ ...s, active: false }));
    if (segments.find((s) => s.id === id).active) {
      updatedSegments.find((s) => !s.segmentType).active = true;
    } else {
      updatedSegments.find((s) => s.id === id).active = true;
    }
    setSegments(updatedSegments);
  };

  const clearSegment = () => {
    const allRespondentSegment = segments.find((s) => !s.segmentType);
    if (allRespondentSegment.active) {
      allRespondentSegment.text = `All Respondents (${responses.data.length})`;
      setSegments([allRespondentSegment]);
    } else {
      setSegments([]);
    }
  };

  const editSegmentName = (id, value) => {
    const updatedSegments = segments.map((s) => (s.id === id ? { ...s, text: value } : s));
    setSegments(updatedSegments);
  };

  const removeSegment = (id) => {
    const updatedSegments = segments.filter((s) => s.id !== id);
    if (segments.find((s) => s.id === id).active) {
      updatedSegments.find((s) => !s.segmentType).active = true;
    }
    setSegments(updatedSegments);
  };

  const handlePageChange = (newPage, newPageSize) => {
    // if changing page size, return to page 1
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setPage(1);
    } else {
      setPage(newPage);
    }
  };

  const afterSearch = useCallback(() => {
    setPage(1);
  }, [setPage]);

  const columns = [
    {
      dataIndex: 'text',
      // do not display theme tags until we determine if we want to show them
      render: (text) => <StyledResponseText italic>{`"${he.decode(text)}"`}</StyledResponseText>,
    },
  ];

  return (
    <Flex vertical gap={16}>
      <OpenEndedQuestionsSelect
        openEndedQuestions={openEndedQuestions}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={setSelectedQuestionId}
      />
      <SegmentResponsesCard
        questions={questions.data.filter((q) => q.is_filterable)}
        questionResponseOptions={questionResponseOptions}
        segments={segments}
        toggleSegment={toggleSegment}
        saveSegment={saveSegment}
        clearSegment={clearSegment}
        editSegmentName={editSegmentName}
        removeSegment={removeSegment}
      />
      <StyledSearchableTable
        searchPlaceholder="Search responses..."
        showHeader={false}
        pagination={{
          defaultPageSize: 50,
          onChange: handlePageChange,
          current: page,
          pageSize,
        }}
        baseData={responses?.data}
        columns={columns}
        loading={responsesLoading}
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No responses available" />
          ),
        }}
        rowKey="id"
        searchColumns={['text']}
        afterSearch={afterSearch}
      />
    </Flex>
  );
}

ResponsesTab.propTypes = {
  questions: queryType.isRequired,
  questionResponseOptions: PropTypes.arrayOf(queryType).isRequired,
  selectedQuestionId: PropTypes.number,
  setSelectedQuestionId: PropTypes.func.isRequired,
};

export default ResponsesTab;
