import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export default function useSuperuser() {
  const [isSuperuser, setSuperuser] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const userOrganizationId = user['https://avalancheinsights.com/organizationId'];
      const superuserOrganizationId = parseInt(process.env.REACT_APP_SUPERUSER_ORGANIZATION_ID, 10);
      setSuperuser(userOrganizationId === superuserOrganizationId);
      setOrganizationId(userOrganizationId);
    }
  }, [user, isAuthenticated]);

  return { isSuperuser, isLoading, organizationId };
}
