import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys } from '../constants';
import useFetch from './useFetch';

export function useCreateOrder() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: 'v1/responses/orders/',
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useUpdateOrder() {
  const fetcher = useFetch();
  return ({ orderId, data }) =>
    fetcher({
      path: `v1/responses/orders/${orderId}/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

// CUSTOM REACT-QUERY HOOKS BELOW - WE SHOULD TRANSITION TO THESE

export const useTagResponses = ({ codeFrameId }, options = {}) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({ path: 'v1/responses/tag/', method: 'PUT', data: JSON.stringify(data) }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }],
      });
      const prevResponses = queryClient.getQueriesData({
        queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }],
      });

      prevResponses.forEach(([queryKey]) => {
        queryClient.setQueryData(queryKey, (prev) => {
          if (prev) {
            const {
              response_ids: responseIds,
              theme_id: themeId,
              is_theme: isTheme,
            } = variables.data;
            const { filters } = queryKey[1];

            // if untagging from the theme remove responses entirely
            if (!isTheme && filters.theme_id === themeId) {
              return {
                ...prev,
                data: prev.data.filter((r) => !responseIds.includes(r.id)),
              };
            }

            // otherwise update themes for affected responses
            if (filters.with_theme_ids) {
              return {
                ...prev,
                data: prev.data.map((r) =>
                  responseIds.includes(r.id)
                    ? {
                        ...r,
                        theme_ids: isTheme
                          ? [...new Set([...r.theme_ids, themeId])]
                          : r.theme_ids.filter((id) => id !== themeId),
                      }
                    : r,
                ),
              };
            }
            return prev;
          }
          return undefined;
        });
      });
      return { prevResponses };
    },
    onSettled: (_data, _error, variables, context) => {
      context.prevResponses.forEach(([queryKey]) => {
        const { filters } = queryKey[1];
        if (filters.theme_id === variables.data.theme_id && variables.data.is_theme) {
          queryClient.refetchQueries({ queryKey });
        } else {
          queryClient.invalidateQueries({ queryKey });
        }
      });
      queryClient.invalidateQueries([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);
      queryClient.invalidateQueries([QueryKeys.THEME_COVERAGE, { codeFrameId }]);
    },
    onError: (_error, _variables, context) => {
      message.error('Something went wrong tagging responses');
      context.prevResponses.forEach(([queryKey, data]) => {
        queryClient.setQueryData(queryKey, data);
      });
    },
    ...options,
  });
};

export const useUpdateSentiment = ({ codeFrameId }) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({ path: 'v1/responses/sentiment/', method: 'PATCH', data: JSON.stringify(data) }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }],
      });
      const prevResponses = queryClient.getQueriesData({
        queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }],
      });

      prevResponses.forEach(([queryKey]) => {
        queryClient.setQueryData(queryKey, (prev) => {
          if (prev) {
            const { response_ids: responseIds, sentiment } = variables.data;
            const { filters } = queryKey[1];

            // if changing from the sentiment remove responses entirely
            if (filters.sentiment !== sentiment) {
              return {
                ...prev,
                data: prev.data.filter((r) => !responseIds.includes(r.id)),
              };
            }

            // otherwise update sentiment for affected responses
            return {
              ...prev,
              data: prev.data.map((r) =>
                responseIds.includes(r.id)
                  ? {
                      ...r,
                      sentiment,
                    }
                  : r,
              ),
            };
          }
          return undefined;
        });
      });
      return { prevResponses };
    },
    onSettled: (_data, _error, variables, context) =>
      context.prevResponses.forEach(([queryKey]) => {
        const { filters } = queryKey[1];
        if (filters.sentiment === variables.data.sentiment) {
          queryClient.refetchQueries({ queryKey });
        } else {
          queryClient.invalidateQueries({ queryKey });
        }
      }),
    onError: (_error, _variables, context) => {
      message.error('Something went wrong updating sentiment');
      context.prevResponses.forEach(([queryKey, data]) => {
        queryClient.setQueryData(queryKey, data);
      });
    },
  });
};
