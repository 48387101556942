import { Button, Flex, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useRemapQuestion } from '../../../api/Questions';

const { Text, Title } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 2px 12px 12px;
  }

  .ant-modal-footer {
    padding-top: 8px;
  }
`;

const StyledText = styled(Text)`
  text-align: center;
`;

function BeginMappingModal({ visible, setVisible, numThemes, questionId, surveyId, onStart }) {
  const { mutate: remapQuestion, isLoading: remapQuestionLoading } = useRemapQuestion({
    questionId,
    surveyId,
  });

  const handleRemapQuestion = () => {
    remapQuestion(undefined, {
      onSuccess: () => {
        onStart();
        setVisible(false);
      },
    });
  };

  return (
    <StyledModal
      open={visible}
      destroyOnClose
      closable={false}
      width={400}
      footer={[
        <Flex justify="space-between" key="footer">
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button type="primary" onClick={handleRemapQuestion} loading={remapQuestionLoading}>
            Start
          </Button>
        </Flex>,
      ]}
    >
      <Flex vertical align="center" gap={8}>
        <Title level={4}>Begin mapping?</Title>
        <StyledText type="secondary">
          You&apos;ve confirmed <Text strong>{numThemes} themes</Text>, and Fathom AI is ready to
          map all responses to them.
        </StyledText>
        <StyledText type="secondary">Make sure your code frame is good to go!</StyledText>
      </Flex>
    </StyledModal>
  );
}

BeginMappingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  numThemes: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  surveyId: PropTypes.number.isRequired,
  onStart: PropTypes.func.isRequired,
};

export default BeginMappingModal;
