import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { GridLoader } from 'react-spinners';
import styled from 'styled-components';
import { useUpdateQuestionPhase } from '../../api/Questions';
import { QCPhase } from '../../constants';
import useParams from '../../hooks/useParams';
import { BluePrimary, GreenPrimary } from '../../styles';

const { Text, Title } = Typography;

const StyledFlex = styled(Flex)`
  margin-top: calc(100vh * 0.175);
`;

const StyledCheckCircle = styled(CheckCircleFilled)`
  color: ${GreenPrimary};
  font-size: 70px;
`;

function Remapping({ qcPhase }) {
  const { questionId, surveyId } = useParams();

  const { mutate: updateQuestionPhase, isLoading: updateQuestionPhaseLoading } =
    useUpdateQuestionPhase({ questionId, surveyId });

  const handleUpdateQuestionPhase = () => {
    updateQuestionPhase({ data: { qc_phase: QCPhase.REVIEW } });
  };

  return (
    <StyledFlex vertical align="center" gap={16}>
      {qcPhase === QCPhase.REMAPPING && (
        <>
          <GridLoader color={BluePrimary} />
          <Flex vertical align="center" gap={2}>
            <Title level={4}>Mapping code frame...</Title>
            <Text type="secondary">
              Fathom AI is mapping responses to your confirmed code frame.
            </Text>
            <Text type="secondary">
              No need to wait around - we&apos;ll email you when it&apos;s ready.
            </Text>
          </Flex>
        </>
      )}
      {qcPhase === QCPhase.REMAPPING_COMPLETE && (
        <>
          <StyledCheckCircle />
          <Flex vertical align="center">
            <Title level={4}>Mapping complete!</Title>
            <Text type="secondary">
              Fathom AI has done the heavy lifting - now it&apos;s your turn to fine tune with your
              expert eye and strategic insight.
            </Text>
          </Flex>
          <Button
            type="primary"
            onClick={handleUpdateQuestionPhase}
            loading={updateQuestionPhaseLoading}
          >
            Start review
          </Button>
        </>
      )}
    </StyledFlex>
  );
}

Remapping.propTypes = {
  qcPhase: PropTypes.string.isRequired,
};

export default Remapping;
