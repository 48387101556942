import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys } from '../constants';
import useFetch from './useFetch';

export function useValidateInvitation() {
  return async ({ token }) => {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/invitations/validate/`,
      { method: 'POST', body: JSON.stringify({ token }) },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}

// CUSTOM REACT-QUERY HOOKS BELOW - WE SHOULD TRANSITION TO THESE

export function useInvitations(options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.INVITATIONS],
    queryFn: () => fetcher({ path: 'v1/invitations/' }),
    ...options,
  });
}

export const useCreateInvitation = ({ surveyId } = {}) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({
        path: 'v1/invitations/create/',
        method: 'POST',
        data: JSON.stringify(data),
      }),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.INVITATIONS]);
      if (surveyId) {
        queryClient.invalidateQueries([QueryKeys.SURVEY_INVITATIONS], { surveyId });
      }
    },
    onError: () => {
      message.error('Something went wrong inviting user');
    },
  });
};

export const useDeleteInvitation = ({ surveyId } = {}) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ invitationId }) =>
      fetcher({
        path: `v1/invitations/${invitationId}/delete/`,
        method: 'DELETE',
      }),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.INVITATIONS]);
      if (surveyId) {
        queryClient.invalidateQueries([QueryKeys.SURVEY_INVITATIONS], { surveyId });
      }
    },
    onError: () => {
      message.error('Something went wrong deleting invitation');
    },
  });
};
