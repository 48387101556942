import { Flex, Spin, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useThemeCoverage } from '../../../api/CodeFrames';
import useParams from '../../../hooks/useParams';
import { Blue2, Blue3, Blue4, BluePrimary, RedPrimary, White } from '../../../styles';

const { Text } = Typography;

const StyledFlex = styled(Flex)`
  padding: 0 8px 4px;
`;

const StyledProgressFlex = styled(Flex)`
  position: relative;
  width: 100%;
  height: 14px;
`;

const StyledSegment = styled(Flex)`
  position: absolute;
  left: ${(props) => props.$left};
  width: ${(props) => props.$percentage}%;
  background-color: ${(props) => props.$color};
  border-radius: 10px;
  border: 1px solid ${White};
  height: 100%;
  padding-right: 6px;
  z-index: ${(props) => props.$zindex};
`;

const StyledText = styled(Text)`
  color: ${White};
  font-size: 10px;
`;

const StyledPercentageText = styled(Text)`
  font-size: 12px;
  min-width: 16px;
  width: ${(props) => props.$width}%;
  text-align: right;
  text-wrap: nowrap;
`;

const COLORS = {
  '4+': BluePrimary,
  3: Blue4,
  2: Blue3,
  1: Blue2,
  0: RedPrimary,
};

function ThemeCoverage() {
  const { codeFrameId } = useParams();

  const { data: themeCoverage, isLoading: isThemeCoverageLoading } = useThemeCoverage(
    {
      codeFrameId,
    },
    {
      select: (data) =>
        data.data
          .filter(({ count }) => count)
          .map((tc) => ({ ...tc, percentage: Math.round(tc.percentage) })),
    },
  );

  const getTextWidth = (numThemes, percentage) => {
    if (numThemes === '0' || numThemes === '4+') {
      return percentage;
    }

    const getPreviousPercentage = (prevNumThemes) =>
      themeCoverage.find((tc) => tc.num_themes === prevNumThemes)?.percentage || 0;

    if (numThemes === '3') {
      return percentage - getPreviousPercentage('4+');
    }
    if (numThemes === '2') {
      return percentage - getPreviousPercentage('3');
    }
    return percentage - getPreviousPercentage('2'); // numThemes is 1
  };

  return (
    <Spin spinning={isThemeCoverageLoading}>
      <StyledFlex vertical gap={8}>
        <Text>Theme coverage</Text>
        <Flex vertical gap={4}>
          <StyledProgressFlex>
            {themeCoverage?.map(({ num_themes: numThemes, percentage }) => (
              <StyledSegment
                align="center"
                justify="end"
                key={numThemes}
                $percentage={Math.round(percentage)}
                $color={COLORS[numThemes]}
                $zindex={100 - percentage}
                $left={numThemes === '0' ? `${100 - percentage}%` : 0}
              >
                <StyledText strong>{numThemes}</StyledText>
              </StyledSegment>
            ))}
          </StyledProgressFlex>
          <Flex>
            {themeCoverage?.map(({ num_themes: numThemes, percentage }) => (
              <StyledPercentageText
                key={numThemes}
                type="secondary"
                $width={getTextWidth(numThemes, percentage)}
              >
                {percentage}%
              </StyledPercentageText>
            ))}
          </Flex>
        </Flex>
      </StyledFlex>
    </Spin>
  );
}

export default ThemeCoverage;
