import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys } from '../constants';
import useFetch from './useFetch';

export function useUpdateRole() {
  const fetcher = useFetch();
  return ({ userId, data }) =>
    fetcher({
      path: `v1/onboarding/users/${userId}/role/update/`,
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useDeleteUser() {
  const fetcher = useFetch();
  return ({ userId }) =>
    fetcher({
      path: `v1/onboarding/users/${userId}/delete/`,
      method: 'DELETE',
    });
}

export function useUpdateOrganization() {
  const fetcher = useFetch();
  return ({ data }) =>
    fetcher({
      path: 'v1/onboarding/organizations/update/',
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useVerifyEmail() {
  const fetcher = useFetch();
  return () => fetcher({ path: 'v1/onboarding/users/verify/', method: 'POST' });
}

// the following apis do not require authentication
export function useOrganizationByName() {
  return async ({ queryKey }) => {
    const [, { name }] = queryKey;
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/onboarding/organizations/${name}/`,
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}

export function useValidateAuth0JWT() {
  return async ({ data }) => {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/onboarding/validate-auth0-jwt/`,
      { method: 'POST', body: JSON.stringify(data) },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}

export function useSignup() {
  return async ({ data }) => {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/onboarding/users/signup/`,
      { method: 'PUT', body: JSON.stringify(data) },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return response.json();
  };
}

// CUSTOM REACT-QUERY HOOKS BELOW - WE SHOULD TRANSITION TO THESE

export function useUsers({ organizationId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.USERS, { organizationId }],
    queryFn: () => fetcher({ path: `v1/onboarding/organizations/${organizationId}/users/` }),
    ...options,
  });
}

export function useOrganization(options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.ORGANIZATION],
    queryFn: () => fetcher({ path: 'v1/onboarding/organizations/me/' }),
    ...options,
  });
}

export function useOrganizations() {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.ORGANIZATIONS],
    queryFn: () => fetcher({ path: 'v1/onboarding/organizations/' }),
  });
}

export function useCreateUpdateOrganization() {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({
        path: 'v1/onboarding/organizations/update/',
        method: 'PUT',
        data: JSON.stringify(data),
      }),
    onSettled: () => queryClient.invalidateQueries([QueryKeys.ORGANIZATIONS]),
    onError: () => {
      message.error('Something went wrong creating or updating organization');
    },
  });
}

export function useAddTokens() {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId, data }) =>
      fetcher({
        path: `v1/onboarding/organizations/${organizationId}/tokens/add/`,
        method: 'POST',
        data: JSON.stringify(data),
      }),
    onSettled: () => queryClient.invalidateQueries([QueryKeys.ORGANIZATIONS]),
    onError: () => {
      message.error('Something went wrong adding tokens');
    },
  });
}

export function useDeductTokens() {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId, data }) =>
      fetcher({
        path: `v1/onboarding/organizations/${organizationId}/tokens/deduct/`,
        method: 'POST',
        data: JSON.stringify(data),
      }),
    onSettled: () => queryClient.invalidateQueries([QueryKeys.ORGANIZATIONS]),
    onError: () => {
      message.error('Something went wrong deducting tokens');
    },
  });
}

export function useOrganizationLedger({ organizationId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.ORGANIZATION_LEDGER, { organizationId }],
    queryFn: () =>
      fetcher({
        path: `v1/onboarding/organizations/${organizationId}/ledger/`,
      }),
    ...options,
  });
}
