import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys } from '../constants';
import useFetch from './useFetch';

// eslint-disable-next-line import/prefer-default-export
export const useDismissSuggestedThemes = ({ codeFrameId }) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ suggestedThemeIds }) =>
      fetcher({ path: `v1/llm/${suggestedThemeIds.join()}/dismiss/`, method: 'PUT' }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries([QueryKeys.SUGGESTED_THEMES, { codeFrameId }]);
      const prevThemes = queryClient.getQueryData([QueryKeys.SUGGESTED_THEMES, { codeFrameId }]);

      queryClient.setQueryData([QueryKeys.SUGGESTED_THEMES, { codeFrameId }], (prev) => {
        return {
          ...prev,
          data: prev.data.filter((t) => !variables.suggestedThemeIds.includes(t.id)),
        };
      });
      return { prevThemes };
    },
    onSettled: () => queryClient.invalidateQueries([QueryKeys.SUGGESTED_THEMES, { codeFrameId }]),
    onError: (_error, _variables, context) => {
      message.error('Something went wrong dismissing suggested themes');
      queryClient.setQueryData([QueryKeys.SUGGESTED_THEMES, { codeFrameId }], context.prevThemes);
    },
  });
};
