import { Badge, Button, Modal, Space, Table } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSurveys } from '../../api/Surveys';
import { SurveyStatus, SurveyStatusText } from '../../constants';

function UploadsModal({ visible, onCancel, organization = null }) {
  // Fetch surveys for the organization
  const { data: surveys, isLoading } = useSurveys({
    enabled: !!organization?.id && visible,
  });

  // Define columns for surveys table
  const surveyColumns = [
    {
      title: 'Survey Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Link to={`/surveys/${record.id}/hub`} target="_blank">
          {text}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'survey_status',
      sorter: (a, b) => a.survey_status.localeCompare(b.survey_status),
      render: (status) => (
        <Space>
          <Badge color={status === SurveyStatus.UNPUBLISHED ? 'orange' : 'green'} />
          {SurveyStatusText[status]}
        </Space>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (date) => dayjs(date).format('MMM D, YYYY h:mm A'),
    },
    {
      title: 'Archived',
      dataIndex: 'is_archived',
      sorter: (a, b) => a.is_archived - b.is_archived,
      render: (isArchived) => (isArchived ? 'Yes' : 'No'),
    },
  ];

  return (
    <Modal
      title={`Uploads - ${organization?.name}`}
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="close" onClick={onCancel}>
          Close
        </Button>,
      ]}
      width={1200}
    >
      {organization && (
        <div>
          <Table
            dataSource={surveys?.data.filter(
              (s) => s.organization_name === organization.name && s.is_demo === false,
            )}
            columns={surveyColumns}
            rowKey="id"
            loading={isLoading}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showTotal: (total) => `Total ${total} surveys`,
            }}
          />
        </div>
      )}
    </Modal>
  );
}

UploadsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default UploadsModal;
