import { Checkbox, Empty, Form, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { questionType } from '../../../../types';

function QuestionSelection({ openEndedQuestions, hidden }) {
  return (
    <>
      {openEndedQuestions.length ? (
        <Form.Item
          label="Select the questions to be exported"
          name="questions"
          tooltip="Export is only available for completed questions."
          hidden={hidden}
          rules={[{ required: true, message: 'Please select at least one question' }]}
        >
          <Checkbox.Group>
            <Space direction="vertical">
              {openEndedQuestions.map((q) => (
                <Checkbox key={q.id} value={q.id}>
                  {q.text}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="There are no completed questions."
        />
      )}
    </>
  );
}

QuestionSelection.propTypes = {
  openEndedQuestions: PropTypes.arrayOf(questionType).isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default QuestionSelection;
