import { useAuth0 } from '@auth0/auth0-react';
import '@fontsource/roboto';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Excelexport from 'fusioncharts/fusioncharts.excelexport';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import React from 'react';
import ReactFC from 'react-fusioncharts';
import {
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import './App.css';
import PermissionedRoute from './auth/PermissionedRoute';
import PermissionedSurveyRoute from './auth/PermissionedSurveyRoute';
import ProtectedRoute from './auth/ProtectedRoute';
import SignupRoute from './auth/SignupRoute';
import SuperuserRoute from './auth/SuperuserRoute';
import ThemeEngineRoute from './auth/ThemeEngineRoute';
import UnprotectedRoute from './auth/UnprotectedRoute';
import Loading from './components/Loading';
import PageLayout from './components/PageLayout';
import { INTERCOM_API_BASE, INTERCOM_APP_ID, UserRoleType } from './constants';
// Defaults to weight 400 with normal variant.
import { FusionChartTheme } from './fusion-charts/FusionChartTheme';
import AdminPanel from './pages/AdminPanel';
import MySurveys from './pages/MySurveys';
import NotFound from './pages/NotFound';
import ResponseExplorer from './pages/ResponseExplorer';
import Settings from './pages/Settings';
import Signup from './pages/Signup';
import SurveyDashboard from './pages/SurveyDashboard';
import SurveyHub from './pages/SurveyHub';
import ThemeEngine from './pages/ThemeEngine';
import UploadSurvey from './pages/UploadSurvey';
import ValidateInvitation from './pages/ValidateInvitation';

let SentryRoutes;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryEnvironment = process.env.REACT_APP_ENVIRONMENT;
if (sentryDsn && ['dev', 'staging', 'production'].includes(sentryEnvironment)) {
  Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnvironment,
    tracesSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    beforeSend(event) {
      // This is a known error in react virtuoso: https://github.com/petyosi/react-virtuoso/issues/254
      // Ignoring it so we don't spam sentry
      const resizeError = event?.exception?.values?.find(({ value }) =>
        value.match(/ResizeObserver loop/i),
      );
      if (resizeError) {
        return null;
      }
      return event;
    },
  });
  SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
} else {
  SentryRoutes = Routes;
}

FusionCharts.options.license({
  key: process.env.REACT_APP_FUSION_CHARTS_LICENSE_KEY,
  creditLabel: false,
});
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, Excelexport);
FusionChartTheme.registerFusionChartTheme();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { user, isLoading, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      api_base: INTERCOM_API_BASE,
      email: user.email,
      created_at: Date.now(),
      hide_default_launcher: false,
    });

    Sentry.setUser({
      username: user.name,
      email: user.email,
    });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SentryRoutes>
        <Route element={<UnprotectedRoute />}>
          <Route path="/invitations/:token" element={<PageLayout />}>
            <Route index element={<ValidateInvitation />} />
          </Route>
        </Route>
        <Route element={<SignupRoute />}>
          <Route path="/signup" element={<PageLayout />}>
            <Route index element={<Signup />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<PageLayout signupComplete />}>
            <Route path="*" element={<NotFound />} />
            <Route index element={<MySurveys />} />
            <Route element={<PermissionedSurveyRoute />}>
              <Route path="/surveys/:surveyId" element={<SurveyDashboard />} />
              <Route
                path="/surveys/:surveyId/questions/:questionId/themes/:themeId/responses"
                element={<ResponseExplorer />}
              />
            </Route>
            <Route element={<ThemeEngineRoute />}>
              <Route
                element={
                  <PermissionedRoute roles={[UserRoleType.ADMINISTRATOR, UserRoleType.EDITOR]} />
                }
              >
                <Route path="/surveys/:surveyId/hub" element={<SurveyHub />} />
              </Route>
            </Route>
            <Route element={<PermissionedRoute roles={[UserRoleType.ADMINISTRATOR]} />}>
              <Route path="/settings" element={<Settings />} />
              <Route element={<SuperuserRoute />}>
                <Route path="/lonsdale" element={<AdminPanel />} />
              </Route>
            </Route>
            <Route
              element={
                <PermissionedRoute roles={[UserRoleType.ADMINISTRATOR, UserRoleType.EDITOR]} />
              }
            >
              <Route path="/surveys/upload" element={<UploadSurvey />} />
            </Route>
          </Route>
          <Route path="/" element={<PageLayout showNavBar={false} />}>
            <Route element={<ThemeEngineRoute />}>
              <Route
                element={
                  <PermissionedRoute roles={[UserRoleType.ADMINISTRATOR, UserRoleType.EDITOR]} />
                }
              >
                <Route
                  path="/surveys/:surveyId/questions/:questionId/codeframes/:codeFrameId/themeEngine"
                  element={<ThemeEngine />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </SentryRoutes>
    </QueryClientProvider>
  );
}

export default App;
