import {
  CloseOutlined,
  DownOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Badge, Button, Dropdown, Flex, Modal, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useGroupThemes } from '../../../api/CodeFrames';
import { useTagResponses } from '../../../api/Responses';
import { useDeleteThemes } from '../../../api/Themes';
import { QCPhase, TESelectionType, ThemeEnginePanel } from '../../../constants';
import useParams from '../../../hooks/useParams';
import { Blue4, Blue5, BluePrimary, Gray5, White } from '../../../styles';
import { displayKeyType, searchFilterType, selectedThemesType, themeType } from '../../../types';
import AddToModal from '../components/AddToModal';
import BaseCollapse from '../components/BaseCollapse';
import CreateEditGroupModal from '../components/CreateEditGroupModal';
import CreateEditThemeModal from '../components/CreateEditThemeModal';
import MergeModal from '../components/MergeModal';
import ThemesDisplay from './ThemesDisplay';

const { Text } = Typography;
const { confirm } = Modal;

const StyledCollapse = styled(BaseCollapse)`
  .ant-collapse-header.cf-header {
    background: ${BluePrimary};
    border-radius: 8px !important;
  }
`;

const StyledText = styled(Text)`
  font-size: 16px;
  color: ${White};
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    box-shadow: unset;
  }
`;

const StyledButton = styled(Button)`
  background: ${Blue5};
  color: ${White} !important;
  border: 0;

  &&.ant-btn:hover {
    background: ${Blue4};
  }
`;

const StyledCloseButton = styled(Button)`
  color: ${Gray5};
`;

function MyCodeFrame({
  themes,
  phase,
  fullScreen = null,
  setFullScreen,
  showDescriptions,
  themeSpan,
  displayKey,
  setDisplayKey,
  selectedResponseIds,
  setSelectedResponseIds,
  searchFilters,
  selectedThemes,
  setSelectedThemes,
  collapseKey,
  setCollapseKey,
  isExpanded,
  scrollNeeded,
  setScrollNeeded,
  setActiveFilter,
  createUpdateThemes,
}) {
  const { codeFrameId } = useParams();
  const containerRef = useRef(null);
  const bottomRef = useRef(null);

  const [newThemeModalVisible, setNewThemeModalVisible] = useState(false);
  const [newGroupModalVisible, setNewGroupModalVisible] = useState(false);
  const [newGroupWithSelectionModalVisible, setNewGroupWithSelectionModalVisible] = useState(false);
  const [addToModalVisible, setAddToModalVisible] = useState(false);
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [editGroupModalVisible, setEditGroupModalVisible] = useState(false);

  const { mutate: deleteThemes } = useDeleteThemes({ codeFrameId });
  const { mutate: groupThemes } = useGroupThemes({ codeFrameId });
  const { mutate: tagResponses } = useTagResponses({ codeFrameId });

  useEffect(() => {
    if (scrollNeeded) {
      bottomRef.current?.scrollIntoView({ behavior: 'instant' });
      setScrollNeeded(false);
    }
  }, [scrollNeeded, setScrollNeeded]);

  const handleCreateTheme = ({ name, description }) => {
    createUpdateThemes.mutate(
      { data: [{ name, description, code_frame_id: codeFrameId }] },
      {
        onSuccess: ({ data: themeData }) => {
          setScrollNeeded(true);
          if (selectedResponseIds.length > 0) {
            tagResponses({
              data: {
                response_ids: selectedResponseIds,
                theme_id: themeData.theme_ids[0],
                code_frame_id: codeFrameId,
              },
            });
            setSelectedResponseIds([]);
          }
        },
      },
    );
  };

  const handleCreateGroup = ({ name, themeIds }) => {
    createUpdateThemes.mutate(
      { data: [{ name, code_frame_id: codeFrameId }] },
      {
        onSuccess: ({ data: themeData }) => {
          groupThemes({ data: { group_id: themeData.theme_ids[0], theme_ids: themeIds } });
        },
      },
    );
  };

  const addToGroup = (groupId) => {
    groupThemes({ data: { group_id: groupId, theme_ids: selectedThemes.ids } });
    setSelectedThemes({ type: null, ids: [] });
  };

  const mergeThemes = async ({ themeId, name, description }) => {
    const responseIds = [
      ...new Set(selectedThemes.ids.flatMap((id) => themes.find((t) => t.id === id).response_ids)),
    ];
    createUpdateThemes.mutate(
      { data: [{ id: themeId, name, description, code_frame_id: codeFrameId }] },
      { onSuccess: () => setSelectedThemes({ type: null, ids: [] }) },
    );
    tagResponses({
      data: { response_ids: responseIds, theme_id: themeId, code_frame_id: codeFrameId },
    });
    deleteThemes({ themeIds: selectedThemes.ids.filter((id) => id !== themeId) });
  };

  const newGroupWithSelection = ({ name }) => {
    createUpdateThemes.mutate(
      { data: [{ name, code_frame_id: codeFrameId }] },
      {
        onSuccess: ({ data: themeData }) => {
          groupThemes({
            data: { group_id: themeData.theme_ids[0], theme_ids: selectedThemes.ids },
          });
          setSelectedThemes({ type: null, ids: [] });
        },
      },
    );
  };

  const handleEditGroup = ({ name }) => {
    createUpdateThemes.mutate(
      {
        data: [
          {
            id: selectedThemes.ids[0],
            name,
            code_frame_id: codeFrameId,
          },
        ],
      },
      {
        onSuccess: () => {
          setSelectedThemes({ type: null, ids: [] });
        },
      },
    );
  };

  const handleDeleteThemes = () => {
    if (selectedThemes.ids.includes(displayKey.key)) {
      setDisplayKey({ type: null, key: null });
    }
    deleteThemes({ themeIds: selectedThemes.ids });
    setSelectedThemes({ type: null, ids: [] });
  };

  const confirmDelete = () => {
    confirm({
      title: 'Are you sure you want to delete your selected themes?',
      content: 'This cannot be undone.',
      okText: 'Delete',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: handleDeleteThemes,
    });
  };

  const confirmDeleteGroup = () => {
    confirm({
      title: 'Are you sure you want to delete your selected group?',
      content: (
        <Text>
          This will <Text strong>not</Text> delete the themes in the group.
        </Text>
      ),
      okText: 'Delete',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: handleDeleteThemes,
    });
  };

  const onCollapseChange = () => {
    if (collapseKey.length > 1) {
      setCollapseKey([ThemeEnginePanel.MY_CODE_FRAME]);
    } else if (collapseKey.includes(ThemeEnginePanel.MY_CODE_FRAME)) {
      setCollapseKey([ThemeEnginePanel.EXPLORE_THEMES, ThemeEnginePanel.MY_CODE_FRAME]);
    } else if (collapseKey.includes(ThemeEnginePanel.EXPLORE_THEMES)) {
      setCollapseKey([ThemeEnginePanel.MY_CODE_FRAME]);
    }
  };

  const actionButtons = [
    ...(selectedThemes.type === TESelectionType.GROUP
      ? [
          {
            text: 'Delete',
            onClick: confirmDeleteGroup,
            color: 'danger',
            variant: 'outlined',
            danger: true,
          },
        ]
      : [
          {
            text: 'Delete',
            onClick: confirmDelete,
            color: 'danger',
            variant: 'outlined',
            danger: true,
          },
        ]),
    ...(selectedThemes.type === TESelectionType.THEME && selectedThemes.ids.length > 0
      ? [
          ...(selectedThemes.ids.length > 1
            ? [{ text: 'Merge', onClick: () => setMergeModalVisible(true) }]
            : []),
          {
            text: 'New group with selection',
            onClick: () => setNewGroupWithSelectionModalVisible(true),
          },
          { text: 'Add to...', onClick: () => setAddToModalVisible(true) },
        ]
      : []),
    ...(selectedThemes.type === TESelectionType.GROUP
      ? [{ text: 'Edit', onClick: () => setEditGroupModalVisible(true) }]
      : []),
  ];

  const item = {
    key: ThemeEnginePanel.MY_CODE_FRAME,
    label: (
      <Space>
        <StyledText strong>My code frame</StyledText>
        <StyledBadge
          count={
            createUpdateThemes.isLoading ? undefined : themes.filter((t) => !t.is_parent).length
          }
          color={Blue5}
          showZero
        />
      </Space>
    ),
    content: (
      <div ref={containerRef}>
        <ThemesDisplay
          phase={phase}
          selectedThemes={selectedThemes}
          setSelectedThemes={setSelectedThemes}
          showDescriptions={showDescriptions}
          themes={themes}
          themeSpan={themeSpan}
          displayKey={displayKey}
          setDisplayKey={setDisplayKey}
          selectedResponseIds={selectedResponseIds}
          setSelectedResponseIds={setSelectedResponseIds}
          searchFilters={searchFilters}
          setActiveFilter={setActiveFilter}
        />
        <div ref={bottomRef} />
      </div>
    ),
    extra: (
      <Space size={12}>
        {phase !== QCPhase.COMPLETE &&
          (isExpanded ? (
            <Space>
              <StyledButton onClick={() => setNewThemeModalVisible(true)}>New theme</StyledButton>
              <StyledButton onClick={() => setNewGroupModalVisible(true)}>New group</StyledButton>
            </Space>
          ) : (
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'New theme',
                    key: 'theme',
                    onClick: () => setNewThemeModalVisible(true),
                  },
                  {
                    label: 'New group',
                    key: 'group',
                    onClick: () => setNewGroupModalVisible(true),
                  },
                ],
              }}
            >
              <StyledButton>
                <Space>
                  New
                  <DownOutlined />
                </Space>
              </StyledButton>
            </Dropdown>
          ))}
        <StyledButton
          icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={() => setFullScreen(fullScreen ? null : ThemeEnginePanel.MY_CODE_FRAME)}
        />
      </Space>
    ),
    footer: phase !== QCPhase.COMPLETE &&
      selectedThemes.type !== TESelectionType.SUGGESTED_THEME &&
      selectedThemes.ids.length > 0 && (
        <Flex justify="space-between" align="center">
          <Flex gap={4}>
            <StyledCloseButton
              type="text"
              size="small"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => {
                setSelectedThemes({ type: null, ids: [] });
              }}
            />
            <Text type="secondary">{selectedThemes.ids.length} selected</Text>
          </Flex>
          {isExpanded ? (
            <Space>
              {actionButtons.map(({ text, onClick, ...props }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Button key={text} onClick={onClick} {...props}>
                  {text}
                </Button>
              ))}
            </Space>
          ) : (
            <Dropdown
              menu={{
                items: actionButtons.map(({ text, onClick, ...props }) => ({
                  label: text,
                  key: text,
                  onClick,
                  ...props,
                })),
              }}
            >
              <Button>
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        </Flex>
      ),
    classNames: { header: 'cf-header' },
  };

  const expandIcon = () =>
    fullScreen || phase !== QCPhase.SELECT_THEMES ? null : (
      <StyledButton
        icon={
          collapseKey.length > 1 || !collapseKey.includes(ThemeEnginePanel.MY_CODE_FRAME) ? (
            <PlusOutlined />
          ) : (
            <MinusOutlined />
          )
        }
      />
    );

  return (
    <>
      <StyledCollapse
        collapsible="icon"
        expandIcon={expandIcon}
        expandIconPosition="end"
        bordered={false}
        activeKey={fullScreen ? [fullScreen] : collapseKey}
        onChange={onCollapseChange}
        item={item}
        loading={createUpdateThemes.isLoading}
        collapsed={!fullScreen && !collapseKey.includes(ThemeEnginePanel.MY_CODE_FRAME)}
        visible={fullScreen !== ThemeEnginePanel.EXPLORE_THEMES}
      />
      <CreateEditThemeModal
        phase={phase}
        type={TESelectionType.THEME}
        visible={newThemeModalVisible}
        setVisible={setNewThemeModalVisible}
        onOk={handleCreateTheme}
      />
      <CreateEditGroupModal
        visible={newGroupModalVisible}
        setVisible={setNewGroupModalVisible}
        onOk={handleCreateGroup}
        themes={themes}
      />
      <AddToModal
        panel={ThemeEnginePanel.MY_CODE_FRAME}
        visible={addToModalVisible}
        setVisible={setAddToModalVisible}
        onMove={addToGroup}
        groups={themes.filter((t) => t.is_parent)}
      />
      <CreateEditGroupModal
        visible={newGroupWithSelectionModalVisible}
        setVisible={setNewGroupWithSelectionModalVisible}
        onOk={newGroupWithSelection}
      />
      <MergeModal
        panel={ThemeEnginePanel.MY_CODE_FRAME}
        visible={mergeModalVisible}
        setVisible={setMergeModalVisible}
        onMerge={mergeThemes}
        themes={themes.filter((t) => selectedThemes.ids.includes(t.id))}
      />
      <CreateEditGroupModal
        group={themes.find((t) => selectedThemes.ids[0] === t.id)}
        visible={editGroupModalVisible}
        setVisible={setEditGroupModalVisible}
        onOk={handleEditGroup}
      />
    </>
  );
}

MyCodeFrame.propTypes = {
  themes: PropTypes.arrayOf(themeType).isRequired,
  phase: PropTypes.string.isRequired,
  fullScreen: PropTypes.string,
  setFullScreen: PropTypes.func.isRequired,
  showDescriptions: PropTypes.bool.isRequired,
  themeSpan: PropTypes.number.isRequired,
  displayKey: displayKeyType.isRequired,
  setDisplayKey: PropTypes.func.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedResponseIds: PropTypes.func.isRequired,
  searchFilters: searchFilterType.isRequired,
  selectedThemes: selectedThemesType.isRequired,
  setSelectedThemes: PropTypes.func.isRequired,
  collapseKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCollapseKey: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  scrollNeeded: PropTypes.bool.isRequired,
  setScrollNeeded: PropTypes.func.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  createUpdateThemes: PropTypes.shape({
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default MyCodeFrame;
