import { Col, Flex, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { TESelectionType, ThemeEnginePanel, ThemeSortOptions } from '../../../constants';
import {
  displayKeyType,
  searchFilterType,
  selectedThemesType,
  suggestedGroupType,
  suggestedThemeType,
} from '../../../types';
import { matchesSearch } from '../components/SearchBar';
import ThemeCard from '../components/ThemeCard';
import ThemeGroupCard from '../components/ThemeGroupCard';

function SuggestedThemesDisplay({
  phase,
  activeSort,
  selectedThemes,
  setSelectedThemes,
  showDescriptions,
  suggestedGroups = undefined,
  suggestedThemes = undefined,
  themeSpan,
  searchFilters,
  displayKey,
  setDisplayKey,
}) {
  const filteredThemes = suggestedThemes.filter((theme) =>
    matchesSearch(`${theme.name} ${theme.description}`, ['themes', 'keywords'], searchFilters),
  );

  const themeCards = filteredThemes.map((t) => ({
    theme: t,
    card: (
      <Col key={t.id} span={themeSpan}>
        <ThemeCard
          panel={ThemeEnginePanel.EXPLORE_THEMES}
          phase={phase}
          theme={t}
          checked={
            selectedThemes.type === TESelectionType.SUGGESTED_THEME &&
            selectedThemes.ids.includes(t.id)
          }
          showDescriptions={showDescriptions}
          setSelectedThemes={setSelectedThemes}
          displayKey={displayKey}
          setDisplayKey={setDisplayKey}
        />
      </Col>
    ),
  }));

  const renderContent = () => {
    if (activeSort === ThemeSortOptions.SUGGESTED_GROUPS) {
      return (
        <Flex vertical gap={10}>
          {suggestedGroups
            ?.filter((g) => themeCards.filter(({ theme }) => theme.group_id === g.id).length)
            .map((g) => (
              <ThemeGroupCard
                key={g.id}
                phase={phase}
                panel={ThemeEnginePanel.EXPLORE_THEMES}
                group={g}
                themeCards={themeCards
                  .filter(({ theme }) => theme.group_id === g.id)
                  .map(({ card }) => card)}
              />
            ))}
        </Flex>
      );
    }

    const sortedThemeCards = themeCards.sort((a, b) => {
      if (activeSort === ThemeSortOptions.SIZE) {
        return b.theme.response_ids.length - a.theme.response_ids.length;
      }
      return a.theme.id - b.theme.id; // default to unsorted (sort by ID)
    });

    return <Row gutter={[10, 10]}>{sortedThemeCards.map(({ card }) => card)}</Row>;
  };

  return renderContent();
}

SuggestedThemesDisplay.propTypes = {
  phase: PropTypes.string.isRequired,
  activeSort: PropTypes.string.isRequired,
  selectedThemes: selectedThemesType.isRequired,
  setSelectedThemes: PropTypes.func.isRequired,
  showDescriptions: PropTypes.bool.isRequired,
  suggestedGroups: PropTypes.arrayOf(suggestedGroupType),
  suggestedThemes: PropTypes.arrayOf(suggestedThemeType),
  themeSpan: PropTypes.number.isRequired,
  searchFilters: searchFilterType.isRequired,
  displayKey: displayKeyType.isRequired,
  setDisplayKey: PropTypes.func.isRequired,
};

export default SuggestedThemesDisplay;
