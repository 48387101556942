import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { UserRoleType } from '../../../../constants';
import useParams from '../../../../hooks/useParams';
import useRoles from '../../../../hooks/useRoles';
import { summaryType } from '../../../../types';
import EditSurveySummaryModal from './EditSurveySummaryModal';

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
  margin-top: 8px;
`;

const StyledText = styled(Text)`
  white-space: pre-line;
`;

function SurveySummary({ summary }) {
  const { surveyId } = useParams();
  const { roles } = useRoles();

  const [isEditModalVisible, setEditModalVisible] = useState(false);

  return (
    <>
      <Flex justify="space-between" align="baseline" gap={8}>
        <Flex align="baseline" gap={8}>
          <StyledTitle level={4}>Survey insights</StyledTitle>
          <Tooltip title="Summary is generated by an AI model based on questions, themes, and responses">
            <Tag icon={<InfoCircleOutlined />}>Beta feature</Tag>
          </Tooltip>
        </Flex>
        {(roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)) && (
          <Button size="small" onClick={() => setEditModalVisible(true)}>
            Edit summary
          </Button>
        )}
      </Flex>
      <Card>
        <StyledText>{summary.summary}</StyledText>
      </Card>
      <EditSurveySummaryModal
        visible={isEditModalVisible}
        closeModal={() => setEditModalVisible(false)}
        summary={summary.summary}
        surveyId={surveyId}
      />
    </>
  );
}

SurveySummary.propTypes = {
  summary: summaryType.isRequired,
};

export default SurveySummary;
