import { useMutation } from '@tanstack/react-query';
import { Col, Modal, Row, Steps, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useOrganization } from '../../api/Onboarding';
import { useCreateSurvey } from '../../api/Surveys';
import Loading from '../../components/Loading';
import CategorizeQuestions from './CategorizeQuestions';
import FileUploadForm from './FileUploadForm';
import UploadFailed from './UploadFailed';
import UploadSuccessful from './UploadSuccessful';

const { confirm, error } = Modal;
const { Paragraph } = Typography;

const StyledRow = styled(Row)`
  width: 100%;
  padding: 32px 0;
`;

function UploadSurvey() {
  const [current, setCurrent] = useState(0);
  const [surveyData, setSurveyData] = useState({});
  const navigate = useNavigate();
  const createSurvey = useMutation(useCreateSurvey());
  const { data: organization, isLoading: organizationLoading } = useOrganization({
    onSuccess: (data) => {
      if (data.data.available_tokens === 0) {
        error({
          title: 'No tokens, no problem!',
          content: (
            <>
              <Paragraph>You&apos;re out of tokens, but we can fix that fast.</Paragraph>
              <Paragraph>
                Contact <a href="mailto:support@fathomthat.ai">support@fathomthat.ai</a> to add
                tokens and get your data processing right away!
              </Paragraph>
            </>
          ),
          icon: null,
          onOk() {
            navigate('/');
          },
        });
      }
    },
  });

  const showConfirm = () => {
    confirm({
      title: 'Are you sure?',
      content: "This can't be undone. You upload will not be processed by Fathom.",
      okText: 'Cancel upload',
      okButtonProps: { danger: true, type: 'primary' },
      cancelText: 'Continue with upload',
      onOk() {
        navigate('/');
      },
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const retryUpload = () => {
    createSurvey.reset();
    prev();
  };

  if (organizationLoading) {
    return <Loading />;
  }

  if (createSurvey.isSuccess) {
    return <UploadSuccessful />;
  }

  if (createSurvey.isError) {
    return <UploadFailed retryUpload={retryUpload} />;
  }

  const steps = [
    {
      title: 'Import survey',
      content: (
        <FileUploadForm
          setSurveyData={setSurveyData}
          surveyData={surveyData}
          next={next}
          showConfirm={showConfirm}
        />
      ),
    },
    {
      title: 'Categorize',
      content: (
        <CategorizeQuestions
          surveyData={surveyData}
          prev={prev}
          setSurveyData={setSurveyData}
          showConfirm={showConfirm}
          createSurvey={createSurvey}
          availableTokens={organization?.data.available_tokens}
        />
      ),
    },
  ];

  return (
    <StyledRow align="center">
      <Col span={18}>
        <Steps progressDot current={current} items={steps} />
      </Col>
      <Col span={18}>{steps[current].content}</Col>
    </StyledRow>
  );
}

export default UploadSurvey;
