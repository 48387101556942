import { RightOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { QCPhase, QCPhaseText } from '../../constants';
import { BluePrimary, Gray5 } from '../../styles';

const phaseBadgeColors = {
  [QCPhase.PROCESSING]: [Gray5, Gray5, Gray5, Gray5],
  [QCPhase.READY]: [BluePrimary, Gray5, Gray5, Gray5],
  [QCPhase.SELECT_THEMES]: [BluePrimary, Gray5, Gray5, Gray5],
  [QCPhase.REMAPPING]: [BluePrimary, BluePrimary, Gray5, Gray5],
  [QCPhase.REMAPPING_COMPLETE]: [BluePrimary, BluePrimary, BluePrimary, Gray5],
  [QCPhase.REVIEW]: [BluePrimary, BluePrimary, BluePrimary, Gray5],
  [QCPhase.COMPLETE]: [BluePrimary, BluePrimary, BluePrimary, BluePrimary],
};

function OpenEndedQuestionPhase({ currentPhase }) {
  return (
    <Space size={6}>
      {QCPhaseText[currentPhase]}
      {phaseBadgeColors[currentPhase].map((color, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Badge color={color} key={idx} />
      ))}
      <RightOutlined style={{ color: BluePrimary }} />
    </Space>
  );
}

OpenEndedQuestionPhase.propTypes = {
  currentPhase: PropTypes.string.isRequired,
};

export default OpenEndedQuestionPhase;
