import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Blue1 } from '../../../styles';

const StyledDivider = styled(Flex)`
  align-items: center;
`;

const StyledLine = styled.div`
  border-left: 1px solid ${Blue1};
  flex-grow: 1;
  width: 1px;
`;

const StyledButton = styled(Button)`
  && {
    width: 8px;
    height: 32px;
  }

  .anticon {
    font-size: 8px !important;
  }
`;

function ExpandPanelToggle({ isThemesPanelExpanded, onClick }) {
  return (
    <StyledDivider vertical>
      <StyledLine />
      <StyledButton
        type="primary"
        icon={isThemesPanelExpanded ? <LeftOutlined /> : <RightOutlined />}
        onClick={onClick}
      />
      <StyledLine />
    </StyledDivider>
  );
}

ExpandPanelToggle.propTypes = {
  isThemesPanelExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExpandPanelToggle;
