import { CloseOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Popover, Space, Switch, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const { Text } = Typography;

const StyledSpace = styled(Space.Compact)`
  width: 100%;
`;

const StyledFlex = styled(Flex)`
  width: 300px;
  padding: 0px 8px 8px;
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

export const matchesSearch = (text, filters, searchFilters) => {
  const lowerText = text.toLowerCase();

  // normalize search terms for each filter
  const searchTerms = filters.flatMap((filterKey) => {
    const filterValue = searchFilters[filterKey]?.trim().toLowerCase();
    return filterValue ? filterValue.split(' ').filter(Boolean) : [];
  });

  // if no search terms exist, return true (show all results)
  if (searchTerms.length === 0) {
    return true;
  }

  return searchTerms.some((term) => lowerText.includes(term));
};

function SearchBar({ setSearchFilters, advancedSearchEnabled, setAdvancedSearchEnabled }) {
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ themes: '', responses: '' });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const filterEntries = {};
    const regex = /(themes|responses):\(([^)]+)\)|(themes|responses):(\S+)/g;
    const matches = [...searchText.matchAll(regex)];

    matches.forEach((match) => {
      const key = match[1] || match[3];
      const value = match[2] || match[4];
      if (key && value) {
        filterEntries[key] = value;
      }
    });

    // remove filters from the search text
    const cleanedSearchText = searchText.replace(regex, '').trim();

    setFilters({ themes: filterEntries.themes || '', responses: filterEntries.responses || '' });
    setSearchFilters({
      themes: filterEntries.themes || '',
      responses: filterEntries.responses || '',
      keywords: cleanedSearchText, // remaining text as general search content
    });
  }, [searchText, setSearchFilters]);

  const updateSearchText = (updatedFilters) => {
    const filterEntries = Object.entries(updatedFilters)
      .filter(([, value]) => value) // only include non-empty filters
      .map(([key, value]) => `${key}:${value}`);

    // replace any existing filters in the search text or add new filters
    // multi-word filters are wrapped in parentheses
    let updatedSearchText = searchText;
    filterEntries.forEach((filter) => {
      const [key, value] = filter.split(':');
      const formattedValue = value.includes(' ') ? `(${value})` : value;
      const filterPattern = new RegExp(`${key}:(\\([^)]*\\)|\\S+)`, 'g');

      if (updatedSearchText.match(filterPattern)) {
        updatedSearchText = updatedSearchText.replace(filterPattern, `${key}:${formattedValue}`);
      } else {
        updatedSearchText = `${key}:${formattedValue} ${updatedSearchText}`.trim();
      }
    });

    // remove filters with empty values from search text
    const filterPattern = /(?:themes|responses):(\([^)]+\)|\S+)/g;
    updatedSearchText = updatedSearchText
      .replace(filterPattern, (match) => {
        const key = match.split(':')[0];
        if (!updatedFilters[key]) {
          return '';
        }
        return match;
      })
      .trim();

    setSearchText(updatedSearchText);
  };

  const handleFilterChange = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);

    updateSearchText(updatedFilters);
  };

  const handleClearSearch = () => {
    setFilters({ themes: '', responses: '' });
    setSearchText('');
  };

  const filterContent = (
    <StyledFlex vertical gap={8}>
      <Text type="secondary">Themes</Text>
      <Input
        value={filters.themes}
        onChange={(e) => handleFilterChange('themes', e.target.value)}
      />
      <Flex justify="space-between" align="center">
        <Text type="secondary">Responses</Text>
        <StyledText type="secondary">
          Advanced search{' '}
          <Switch
            size="small"
            checked={advancedSearchEnabled}
            onChange={setAdvancedSearchEnabled}
          />
        </StyledText>
      </Flex>
      <Input
        value={filters.responses}
        onChange={(e) => handleFilterChange('responses', e.target.value)}
      />
    </StyledFlex>
  );

  return (
    <StyledSpace>
      <Popover content={filterContent} trigger="click" open={open} onOpenChange={setOpen}>
        <Button icon={<FilterOutlined />} />
      </Popover>
      <Input
        placeholder="Search themes or responses..."
        prefix={<SearchOutlined />}
        suffix={searchText && <CloseOutlined onClick={handleClearSearch} />}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </StyledSpace>
  );
}

SearchBar.propTypes = {
  setSearchFilters: PropTypes.func.isRequired,
  advancedSearchEnabled: PropTypes.bool.isRequired,
  setAdvancedSearchEnabled: PropTypes.func.isRequired,
};

export default SearchBar;
