import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Flex, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RedPrimary } from '../../styles';

const { Text, Title } = Typography;

const StyledFlex = styled(Flex)`
  margin-top: calc(100vh * 0.175);
`;

const StyledCloseCircle = styled(CloseCircleFilled)`
  color: ${RedPrimary};
  font-size: 70px;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

function UploadFailed({ retryUpload }) {
  const navigate = useNavigate();
  return (
    <StyledFlex vertical align="center" gap={16}>
      <Flex vertical align="center" gap={2}>
        <StyledCloseCircle />
        <Title level={3}>Upload failed</Title>
        <StyledText type="secondary">
          Something went wrong and your upload could not be completed.
        </StyledText>
        <StyledText type="secondary">Please try to upload the file again.</StyledText>
      </Flex>
      <Space>
        <Button type="primary" onClick={retryUpload}>
          Retry Upload
        </Button>
        <Button onClick={() => navigate('/')}>Go to My Surveys</Button>
      </Space>
    </StyledFlex>
  );
}

UploadFailed.propTypes = {
  retryUpload: PropTypes.func.isRequired,
};

export default UploadFailed;
