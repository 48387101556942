import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys, SURVEY_ID_HEADER } from '../constants';
import useFetch from './useFetch';

export function useCreateSurvey() {
  const fetcher = useFetch();
  return ({ data }) => fetcher({ path: 'v1/surveys/create/', method: 'POST', data });
}

export function useArchiveSurvey() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/archive/`,
      method: 'PUT',
      data: JSON.stringify(data),
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
}

export function useUpdateSurveySummary() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/update-summary/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

export function useCreatePersistedSegments() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/persisted-segments/create/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
      method: 'POST',
      data: JSON.stringify(data),
    });
}

export function useUpdatePersistedSegment() {
  const fetcher = useFetch();
  return ({ surveyId, segmentId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/persisted-segments/${segmentId}/update/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

export function useDeletePersistedSegments() {
  const fetcher = useFetch();
  return ({ surveyId, segmentIds }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/persisted-segments/delete/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
      method: 'DELETE',
      data: JSON.stringify(segmentIds),
    });
}

export function useClearAllPersistedSegments() {
  const fetcher = useFetch();
  return ({ surveyId }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/persisted-segments/clear/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
      method: 'DELETE',
    });
}

export function useCreateSavedSegment() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/segments/create/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
      method: 'POST',
      data: JSON.stringify(data),
    });
}

export function useUpdateSavedSegment() {
  const fetcher = useFetch();
  return ({ surveyId, segmentId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/segments/${segmentId}/update/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

export function useDeleteSavedSegment() {
  const fetcher = useFetch();
  return ({ surveyId, segmentId }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/segments/${segmentId}/delete/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
      method: 'DELETE',
    });
}

// CUSTOM REACT-QUERY HOOKS BELOW - WE SHOULD TRANSITION TO THESE

export function useSurveys(options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SURVEYS],
    queryFn: () => fetcher({ path: 'v1/surveys/' }),
    ...options,
  });
}

export function useQuestions({ surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.QUESTIONS, { surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/surveys/${surveyId}/questions/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useSurveyClients({ surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SURVEY_CLIENTS, { surveyId }],
    queryFn: () => fetcher({ path: `v1/surveys/${surveyId}/clients/` }),
    ...options,
  });
}

export function useSurveyInvitations({ surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SURVEY_INVITATIONS, { surveyId }],
    queryFn: () => fetcher({ path: `v1/surveys/${surveyId}/invitations/` }),
    ...options,
  });
}

export function useSurveySummary({ surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SURVEY_SUMMARY, { surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/surveys/${surveyId}/summary/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function usePersistedSegments({ surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.PERSISTED_SEGMENTS, { surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/surveys/${surveyId}/persisted-segments/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function usePersistedSegment({ surveyId, segmentId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.PERSISTED_SEGMENT, { surveyId, segmentId }],
    queryFn: () =>
      fetcher({
        path: `v1/surveys/${surveyId}/persisted-segments/${segmentId}`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useSavedSegments({ surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SAVED_SEGMENTS, { surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/surveys/${surveyId}/segments/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useSurvey({ surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SURVEY, { surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/surveys/${surveyId}/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export const usePublishSurvey = ({ surveyId }) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => fetcher({ path: `v1/surveys/${surveyId}/publish/`, method: 'PUT' }),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.SURVEY, { surveyId }]);
      queryClient.invalidateQueries([QueryKeys.SURVEYS]);
    },
    onError: () => {
      message.error('Something went wrong publishing survey');
    },
  });
};

export const useUpdateSurvey = ({ surveyId }) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({
        path: `v1/surveys/${surveyId}/update/`,
        method: 'PATCH',
        data: JSON.stringify(data),
      }),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.SURVEY, { surveyId }]);
      queryClient.invalidateQueries([QueryKeys.SURVEYS]);
    },
    onError: () => {
      message.error('Something went wrong updating survey');
    },
  });
};

export const useUpdateGuest = () => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, surveyId, data }) =>
      fetcher({
        path: `v1/surveys/${surveyId}/clients/${userId}/update/`,
        method: 'PATCH',
        data: JSON.stringify(data),
      }),
    onSettled: (_data, _error, { surveyId }) =>
      queryClient.invalidateQueries([QueryKeys.SURVEY_CLIENTS], { surveyId }),
    onError: () => {
      message.error('Something went wrong updating guest');
    },
  });
};

export const useDeleteGuest = () => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, surveyId }) =>
      fetcher({
        path: `v1/surveys/${surveyId}/clients/${userId}/delete/`,
        method: 'DELETE',
      }),
    onSettled: (_data, _error, { surveyId }) =>
      queryClient.invalidateQueries([QueryKeys.SURVEY_CLIENTS], { surveyId }),
    onError: () => {
      message.error('Something went wrong removing guest');
    },
  });
};
