import { FilterOutlined, FilterTwoTone, SortAscendingOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FilterSortType, ResponseFilterOptions } from '../../../constants';
import { Gray5 } from '../../../styles';

const { Text } = Typography;

const DotText = styled(Text)`
  color: ${Gray5};
`;

function FilterSortDropdown({ type, options, activeOption, onChange, isExpanded }) {
  const getIcon = () => {
    if (type === FilterSortType.FILTER) {
      if (activeOption === ResponseFilterOptions.ALL) {
        return <FilterOutlined />;
      }
      return <FilterTwoTone />;
    }
    return <SortAscendingOutlined />;
  };

  return (
    <Dropdown
      menu={{
        items: Object.entries(options).map(([, label]) => ({
          key: label,
          label,
          onClick: () => onChange(label),
        })),
        selectable: true,
        selectedKeys: [activeOption],
      }}
    >
      <span data-cy={type === FilterSortType.FILTER ? 'filter-dropdown' : 'sort-dropdown'}>
        {isExpanded ? (
          <Space>
            <Text type="secondary">{getIcon()}</Text>
            <Space size={4}>
              <Text type="secondary">{type}</Text>
              <DotText>•</DotText>
              <Text>{activeOption}</Text>
            </Space>
          </Space>
        ) : (
          <Text type="secondary">{getIcon()}</Text>
        )}
      </span>
    </Dropdown>
  );
}

FilterSortDropdown.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.string).isRequired,
  activeOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default FilterSortDropdown;
