import { Card, Checkbox, Flex, Progress, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useQuestion } from '../../../api/Questions';
import { QCPhase, TESelectionType, ThemeEnginePanel } from '../../../constants';
import useParams from '../../../hooks/useParams';
import {
  TealProgress,
  suggestedThemeCardBorderColor,
  suggestedThemeCardColor,
  themeCardBorderColors,
  themeCardColors,
} from '../../../styles';

const { Text } = Typography;

const StyledCard = styled(Card)`
  background: ${(props) => props.$color};
  border: 1px solid ${(props) => props.$borderColor};

  .ant-card-body {
    padding: 8px 10px;
  }
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

const GroupNameText = styled(Text)`
  font-weight: 600;
`;

function ThemeGroupCard({
  panel,
  phase,
  group,
  themeCards = [],
  selectedGroupId = null,
  setSelectedThemes = () => {},
  setDisplayKey = () => {},
}) {
  const { questionId, surveyId } = useParams();

  const { data: question, isLoading: questionLoading } = useQuestion({ questionId, surveyId });

  const responsePercentage =
    panel === ThemeEnginePanel.MY_CODE_FRAME && question?.data
      ? group.response_ids.length / question.data.response_count
      : 0;

  const handleCheckboxChange = (e) => {
    setDisplayKey({ type: null, key: null });
    setSelectedThemes(
      e.target.checked ? { type: TESelectionType.GROUP, ids: [group.id] } : { type: null, ids: [] },
    );
  };

  return (
    <StyledCard
      size="small"
      loading={questionLoading}
      $color={
        panel === ThemeEnginePanel.MY_CODE_FRAME
          ? themeCardColors[group.id % themeCardColors.length]
          : suggestedThemeCardColor
      }
      $borderColor={
        panel === ThemeEnginePanel.MY_CODE_FRAME
          ? themeCardBorderColors[group.id % themeCardBorderColors.length]
          : suggestedThemeCardBorderColor
      }
    >
      <Flex vertical gap={8}>
        <Flex justify="space-between" gap={8}>
          <Flex gap={8}>
            {panel === ThemeEnginePanel.MY_CODE_FRAME && phase !== QCPhase.COMPLETE && (
              <Checkbox checked={selectedGroupId === group.id} onClick={handleCheckboxChange} />
            )}
            <GroupNameText>{group.name}</GroupNameText>
          </Flex>
          {panel === ThemeEnginePanel.MY_CODE_FRAME && phase !== QCPhase.SELECT_THEMES && (
            <Flex gap={4}>
              <Progress
                type="circle"
                percent={Math.round(responsePercentage * 100)}
                size={20}
                strokeColor={TealProgress}
              />
              <StyledText type="secondary">{Math.round(responsePercentage * 100)}%</StyledText>
            </Flex>
          )}
        </Flex>
        <Row gutter={[10, 10]}>{themeCards}</Row>
      </Flex>
    </StyledCard>
  );
}

ThemeGroupCard.propTypes = {
  panel: PropTypes.string.isRequired,
  phase: PropTypes.string.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    response_ids: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  themeCards: PropTypes.arrayOf(PropTypes.element.isRequired),
  selectedGroupId: PropTypes.number,
  setSelectedThemes: PropTypes.func,
  setDisplayKey: PropTypes.func,
};

export default ThemeGroupCard;
