import { Col, Empty, Flex, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { QCPhase, TESelectionType, ThemeEnginePanel } from '../../../constants';
import { displayKeyType, searchFilterType, selectedThemesType, themeType } from '../../../types';
import { matchesSearch } from '../components/SearchBar';
import ThemeCard from '../components/ThemeCard';
import ThemeGroupCard from '../components/ThemeGroupCard';
import SentimentDisplay from './SentimentDisplay';

const StyledRow = styled(Row)`
  padding: 0 10px;
`;

function ThemesDisplay({
  phase,
  selectedThemes,
  setSelectedThemes,
  showDescriptions,
  themes,
  themeSpan,
  displayKey,
  setDisplayKey,
  selectedResponseIds,
  setSelectedResponseIds,
  searchFilters,
  setActiveFilter,
}) {
  const filteredThemes = themes.filter((t) =>
    matchesSearch(`${t.name} ${t.description}`, ['themes', 'keywords'], searchFilters),
  );

  const themeCards = filteredThemes
    .filter((t) => !t.is_parent)
    .map((t) => ({
      theme: t,
      card: (
        <Col key={t.id} span={themeSpan}>
          <ThemeCard
            panel={ThemeEnginePanel.MY_CODE_FRAME}
            phase={phase}
            theme={t}
            checked={
              selectedThemes.type === TESelectionType.THEME && selectedThemes.ids.includes(t.id)
            }
            showDescriptions={showDescriptions}
            setSelectedThemes={setSelectedThemes}
            displayKey={displayKey}
            setDisplayKey={setDisplayKey}
            selectedResponseIds={selectedResponseIds}
            setSelectedResponseIds={setSelectedResponseIds}
            setActiveFilter={setActiveFilter}
          />
        </Col>
      ),
    }));

  return (
    <Flex vertical gap={10}>
      {themes.length > 0 ? (
        <>
          {themes
            .filter(
              (t) =>
                t.is_parent &&
                themeCards.filter(({ theme }) => theme.parent_theme_id === t.id).length,
            )
            .map((g) => (
              <ThemeGroupCard
                key={g.id}
                phase={phase}
                panel={ThemeEnginePanel.MY_CODE_FRAME}
                group={g}
                themeCards={themeCards
                  .filter(({ theme }) => theme.parent_theme_id === g.id)
                  .map(({ card }) => card)}
                selectedGroupId={
                  (selectedThemes.type === TESelectionType.GROUP && selectedThemes.ids[0]) || null
                }
                setSelectedThemes={setSelectedThemes}
                setDisplayKey={setDisplayKey}
              />
            ))}
          <StyledRow gutter={[10, 10]}>
            {themeCards.filter(({ theme }) => !theme.parent_theme_id).map(({ card }) => card)}
          </StyledRow>
          {phase !== QCPhase.SELECT_THEMES &&
            Object.values(searchFilters).every((v) => v === '') && (
              <SentimentDisplay
                themeSpan={themeSpan}
                displayKey={displayKey}
                setDisplayKey={setDisplayKey}
                setSelectedThemes={setSelectedThemes}
                setActiveFilter={setActiveFilter}
              />
            )}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="It looks like there are no themes yet. Start by creating your first theme!"
        />
      )}
    </Flex>
  );
}

ThemesDisplay.propTypes = {
  phase: PropTypes.string.isRequired,
  selectedThemes: selectedThemesType.isRequired,
  setSelectedThemes: PropTypes.func.isRequired,
  showDescriptions: PropTypes.bool.isRequired,
  themes: PropTypes.arrayOf(themeType).isRequired,
  themeSpan: PropTypes.number.isRequired,
  displayKey: displayKeyType.isRequired,
  setDisplayKey: PropTypes.func.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedResponseIds: PropTypes.func.isRequired,
  searchFilters: searchFilterType.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
};

export default ThemesDisplay;
