import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Flex, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SUMMARY_COLLAPSE_KEY, UserRoleType } from '../../../../constants';
import useParams from '../../../../hooks/useParams';
import useRoles from '../../../../hooks/useRoles';
import { questionType, summaryType } from '../../../../types';
import EditQuestionSummaryModal from './EditQuestionSummaryModal';

const { Title, Text } = Typography;

const StyledText = styled(Text)`
  font-size: 12px;
  white-space: pre-line;
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding-bottom: 0 !important;
  }
`;

function QuestionSummary({ question, summary }) {
  const { surveyId } = useParams();
  const { roles } = useRoles();

  const [summaryCollapseKey, setSummaryCollapseKey] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const themeOverview = [
    {
      key: SUMMARY_COLLAPSE_KEY,
      label: 'Theme overview',
      children: <StyledText>{summary.theme_overview}</StyledText>,
    },
  ];

  return (
    <div data-cy="question-summary">
      <Flex align="baseline" justify="space-between" gap={8}>
        <Flex align="baseline" gap={8}>
          <Title level={5}>Question summary</Title>
          <Tooltip title="Summary is generated by an AI model based on themes and responses">
            <Tag icon={<InfoCircleOutlined />}>Beta feature</Tag>
          </Tooltip>
        </Flex>
        {(roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)) && (
          <Button size="small" onClick={() => setEditModalVisible(true)}>
            Edit summary
          </Button>
        )}
      </Flex>
      <Card size="small">
        <StyledText>{summary.summary}</StyledText>
        <StyledCollapse
          collapsible="icon"
          activeKey={summaryCollapseKey}
          onChange={setSummaryCollapseKey}
          ghost
          items={themeOverview}
        />
      </Card>
      <EditQuestionSummaryModal
        visible={isEditModalVisible}
        closeModal={() => setEditModalVisible(false)}
        summary={summary}
        codeFrameId={question.code_frame_id}
        surveyId={surveyId}
      />
    </div>
  );
}

QuestionSummary.propTypes = {
  question: questionType.isRequired,
  summary: summaryType.isRequired,
};

export default QuestionSummary;
