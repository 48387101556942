import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTagResponses } from '../../../api/Responses';
import { QCPhase } from '../../../constants';
import { Black, Gray4, Gray5, themeTagColors } from '../../../styles';
import { responseType, themeType } from '../../../types';

const StyledTag = styled(Tag)`
  background: ${(props) => props.$color};
  border-color: ${(props) => props.$color};
`;

const StyledTagButton = styled(Button)`
  background: ${Gray4};
  border: 0;
  box-shadow: unset;

  &&.ant-btn:hover {
    background: ${Gray5};
    color: ${Black};
  }
`;

function ThemeTagList({ phase, response, themes, setResponseToTagId, codeFrameId }) {
  const { mutate: tagResponses } = useTagResponses({ codeFrameId });

  return (
    <Row gutter={[8, 8]}>
      {response.theme_ids
        ?.sort((a, b) =>
          themes.find((t) => t.id === a).name.localeCompare(themes.find((t) => t.id === b).name),
        )
        .map((id) => (
          <StyledTag
            key={id}
            closeIcon={phase !== QCPhase.COMPLETE}
            $color={
              themes.find((t) => t.id === id).parent_theme_id
                ? themeTagColors[
                    themes.find((t) => t.id === id).parent_theme_id % themeTagColors.length
                  ]
                : Gray4
            }
            onClose={(e) => {
              e.preventDefault();
              tagResponses({
                data: {
                  response_ids: [response.id],
                  theme_id: id,
                  code_frame_id: codeFrameId,
                  is_theme: false,
                },
              });
            }}
          >
            {themes.find((t) => t.id === id).name}
          </StyledTag>
        ))}
      {phase !== QCPhase.COMPLETE && (
        <StyledTagButton
          size="small"
          icon={<PlusOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            setResponseToTagId(response.id);
          }}
        />
      )}
    </Row>
  );
}

ThemeTagList.propTypes = {
  phase: PropTypes.string.isRequired,
  response: responseType.isRequired,
  themes: PropTypes.arrayOf(themeType).isRequired,
  setResponseToTagId: PropTypes.func.isRequired,
  codeFrameId: PropTypes.number.isRequired,
};

export default ThemeTagList;
