import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../api/Onboarding';

export default function useSelfServeOrganization() {
  const { isLoading: auth0Loading } = useAuth0();
  const { data: organization, isLoading: organizationLoading } = useOrganization();

  return {
    selfServeEnabled: organization?.data.self_serve_enabled,
    isLoading: organizationLoading || auth0Loading,
  };
}
