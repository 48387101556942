import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Flex, Form, Input, Switch, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { QCPhase, TESelectionType } from '../../../constants';
import { BluePrimary, White } from '../../../styles';
import { suggestedThemeType, themeType } from '../../../types';
import BaseModal from './BaseModal';

const { Text } = Typography;
const { TextArea } = Input;

const StyledForm = styled(Form)`
  padding: 0 12px;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledAlert = styled(Alert)`
  background: ${BluePrimary};
  border: none;
  color: ${White};
`;

function CreateEditThemeModal({ phase, type = null, theme = null, visible, setVisible, onOk }) {
  const [form] = Form.useForm();
  const [hasTitleChanged, setTitleChanged] = useState(false);

  const getTitle = () => {
    if (theme) {
      return type === TESelectionType.THEME ? 'Edit theme' : 'Add theme';
    }
    return 'Create theme';
  };

  const getOkText = () => {
    if (theme) {
      return type === TESelectionType.THEME ? 'Edit' : 'Add';
    }
    return 'Create';
  };

  return (
    <BaseModal
      title={getTitle()}
      open={visible}
      destroyOnClose
      closable={false}
      okText={getOkText()}
      onCancel={() => setVisible(false)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
            setVisible(false);
          })
          .catch(() => {});
      }}
    >
      <StyledForm
        form={form}
        layout="vertical"
        autoComplete="off"
        preserve={false}
        requiredMark={false}
        onValuesChange={(changedValues) => {
          if (theme) {
            if (changedValues.name && theme.name !== changedValues.name) {
              setTitleChanged(true);
            } else if (changedValues.name) {
              setTitleChanged(false);
            }
          }
        }}
      >
        <Flex vertical gap={16}>
          <Form.Item
            label={<Text type="secondary">Theme name</Text>}
            name="name"
            initialValue={theme?.name}
            rules={[{ required: true, message: 'name is required' }]}
          >
            <Input type="text" />
          </Form.Item>
          {theme && hasTitleChanged && phase === QCPhase.SELECT_THEMES && (
            <StyledAlert
              message={
                <Flex gap={8} justify="center">
                  <InfoCircleOutlined />
                  You have changed the title. Responses may not be relevant.
                </Flex>
              }
            />
          )}
          <Form.Item
            label={<Text type="secondary">Description</Text>}
            name="description"
            initialValue={theme?.description}
            rules={[{ required: true, message: 'description is required' }]}
          >
            <TextArea rows={6} placeholder="Describe the theme in a sentence" />
          </Form.Item>
          {phase !== QCPhase.SELECT_THEMES && type === TESelectionType.THEME && theme && (
            <Form.Item>
              <Flex align="center" gap={8}>
                <Text type="secondary">Omitted</Text>
                <Form.Item name="omitted" initialValue={theme.is_omitted} noStyle>
                  <Switch />
                </Form.Item>
              </Flex>
            </Form.Item>
          )}
        </Flex>
      </StyledForm>
    </BaseModal>
  );
}

CreateEditThemeModal.propTypes = {
  phase: PropTypes.string.isRequired,
  type: PropTypes.string,
  theme: PropTypes.oneOfType([themeType, suggestedThemeType]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default CreateEditThemeModal;
