import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactFC from 'react-fusioncharts';
import { useCodeFrame } from '../../../api/Questions';
import { ChartValueType } from '../../../constants';
import { getThemesBarChart } from '../../../fusion-charts/ChartHelper';

function ThemeChartModal({ visible, closeModal, questionId, surveyId }) {
  const {
    data: themeChart,
    isFetching: themeChartFetching,
    refetch,
  } = useCodeFrame(
    { questionId, surveyId },
    {
      enabled: visible,
      select: (data) =>
        getThemesBarChart({
          themes: data.data.themes_data.themes,
          questionId,
          surveyId,
          chartValueType: ChartValueType.PERCENTAGE,
          clickable: false,
        }),
    },
  );

  useEffect(() => {
    if (visible) {
      refetch();
    }
  }, [visible, refetch]);

  return (
    <Modal
      open={visible}
      onCancel={closeModal}
      footer={null}
      loading={themeChartFetching}
      width={780}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ReactFC {...themeChart} />
    </Modal>
  );
}

ThemeChartModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  questionId: PropTypes.number.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default ThemeChartModal;
