import { UserAddOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Empty, Flex, Modal, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCreateCodeFrame } from '../../api/Questions';
import { usePublishSurvey, useQuestions, useSurvey } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import { QCPhase, SurveyStatus, UserRoleType } from '../../constants';
import useMatomo from '../../hooks/useMatomo';
import useParams from '../../hooks/useParams';
import useRoles from '../../hooks/useRoles';
import EditSurveyDrawer from './EditSurveyDrawer';
import FilteringQuestionDrawer from './FilteringQuestionDrawer';
import InviteGuestModal from './InviteGuestModal';
import OpenEndedQuestionPhase from './OpenEndedQuestionPhase';

const { Title, Text, Paragraph } = Typography;
const { confirm, warning } = Modal;

const StyledDiv = styled.div`
  padding: 0 24px 24px;
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 16px 24px;
  }
`;

const StyledFlex = styled(Flex)`
  min-width: 0;
`;

const StyledText = styled(Text)`
  flex: 1 1;
  font-size: 16px;
  margin-right: 24px;
  display: block;
`;

function SurveyHub() {
  const { surveyId } = useParams();
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const { roles } = useRoles();

  const [isInviteModalVisible, setInviteModalVisible] = useState(false);
  const [editSurveyDrawerVisible, setEditSurveyDrawerVisible] = useState(false);
  const [selectedFilteringQuestion, setSelectedFilteringQuestion] = useState(null);
  const [refetchInterval, setRefetchInterval] = useState(false);

  const { data: questions, isLoading: questionsLoading } = useQuestions({ surveyId });
  const { data: survey, isLoading: surveyLoading } = useSurvey(
    { surveyId },
    {
      refetchInterval,
      onSuccess: (data) => {
        if (!refetchInterval && data.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS) {
          setRefetchInterval(3000);
        } else if (
          refetchInterval &&
          data.data.survey_status !== SurveyStatus.PUBLISH_IN_PROGRESS
        ) {
          setRefetchInterval(false);
        }
      },
    },
  );
  const { mutate: publishSurvey, isLoading: publishSurveyLoading } = usePublishSurvey({ surveyId });
  const { mutate: createCodeFrame } = useCreateCodeFrame({ surveyId });

  const incompleteOEQuestions = questions?.data.filter(
    (q) => q.has_themes && q.qc_phase !== QCPhase.COMPLETE,
  );

  const handleClickOEQuestion = (question) => {
    if (question.code_frame_id) {
      navigate(
        `/surveys/${surveyId}/questions/${question.id}/codeframes/${question.code_frame_id}/themeEngine/`,
      );
    } else {
      createCodeFrame(
        { questionId: question.id },
        {
          onSuccess: (response) => {
            navigate(
              `/surveys/${surveyId}/questions/${question.id}/codeframes/${response.data.code_frame_id}/themeEngine/`,
            );
          },
        },
      );
    }
  };

  const handlePublishSurvey = () => {
    setRefetchInterval(3000);
    publishSurvey();
  };

  const confirmPublishSurvey = () => {
    confirm({
      title: 'Ready to analyze your coded data?',
      content: (
        <>
          <Paragraph>
            Once you confirm, we&apos;ll run the stats and surface key insights.
          </Paragraph>
          <Paragraph>Make sure your code frame is final and your coding is complete!</Paragraph>
        </>
      ),
      okText: 'Confirm',
      okType: 'danger',
      onOk: handlePublishSurvey,
    });
  };

  const codingIncompleteModal = () => {
    warning({
      title: 'Almost there!',
      content: (
        <>
          <Paragraph>It looks like coding is still in process for:</Paragraph>
          <Paragraph>
            {incompleteOEQuestions.map((q) => (
              <div key={q.id}>- {q.text}</div>
            ))}
          </Paragraph>
          <Paragraph>
            Make sure your code frame is final and marked &quot;coding complete&quot; to proceed
            with analysis.
          </Paragraph>
        </>
      ),
    });
  };

  const confirmUpdateAnalysis = () => {
    confirm({
      title: 'Are you sure?',
      content:
        "Updating your analysis will replace your previous results. Only do this if you've made meaningful changes to your code frame or coding.",
      okText: 'Confirm',
      okType: 'danger',
      onOk: handlePublishSurvey,
    });
  };

  useEffect(() => {
    trackPageView('Survey Hub');
  }, [trackPageView]);

  if (questionsLoading || surveyLoading) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <Flex justify="space-between" align="baseline">
        <BackButton onClick={() => navigate('/')} text="Back to My Surveys" />
        {roles.includes(UserRoleType.ADMINISTRATOR) && (
          <Button type="link" icon=<UserAddOutlined /> onClick={() => setInviteModalVisible(true)}>
            Manage guests
          </Button>
        )}
      </Flex>
      <Flex justify="space-between" align="baseline">
        <Title level={4}>Survey Hub: {survey.data.name}</Title>
        <Space>
          {questions.data.length > 0 && (
            <>
              <Button onClick={() => setEditSurveyDrawerVisible(true)}>Edit details</Button>
              {survey.data.survey_status === SurveyStatus.PUBLISHED ? (
                <Button onClick={confirmUpdateAnalysis} loading={publishSurveyLoading} danger>
                  Update analysis
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={
                    incompleteOEQuestions.length ? codingIncompleteModal : confirmPublishSurvey
                  }
                  loading={
                    publishSurveyLoading ||
                    survey.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS
                  }
                  danger
                >
                  {publishSurveyLoading ||
                  survey.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS
                    ? 'Analyzing'
                    : 'Analyze results'}
                </Button>
              )}
              <Button type="primary" onClick={() => navigate(`/surveys/${surveyId}`)}>
                View dashboard
              </Button>
            </>
          )}
        </Space>
      </Flex>
      {questions.data.length > 0 ? (
        <>
          <Flex vertical gap={8}>
            <Title level={5}>Open-ended questions</Title>
            {questions.data.some((q) => q.has_themes && q.qc_phase === QCPhase.PROCESSING) && (
              <Alert
                message="Processing in progress!"
                description="Fathom AI is carefully reviewing your data to uncover key themes. No need to hover; we'll email you when it's time to confirm your code frame!"
                type="info"
                showIcon
              />
            )}
            {questions.data
              .filter((question) => question.has_themes)
              .map((question) => (
                <StyledCard
                  key={question.id}
                  hoverable={question.qc_phase !== QCPhase.PROCESSING}
                  onClick={
                    question.qc_phase !== QCPhase.PROCESSING
                      ? () => handleClickOEQuestion(question)
                      : null
                  }
                >
                  <Flex justify="space-between">
                    <StyledFlex vertical flex={1}>
                      <StyledText ellipsis={{ tooltip: true }}>{question.text}</StyledText>
                      <Text type="secondary">{question.response_count} responses</Text>
                    </StyledFlex>
                    <OpenEndedQuestionPhase currentPhase={question.qc_phase} />
                  </Flex>
                </StyledCard>
              ))}
          </Flex>
          <Flex vertical gap={8}>
            <Title level={5}>Filtering questions</Title>
            {questions.data
              .filter((question) => question.is_filterable)
              .map((question) => (
                <StyledCard
                  hoverable
                  key={question.id}
                  onClick={() => setSelectedFilteringQuestion(question)}
                >
                  <StyledText>{question.text}</StyledText>
                </StyledCard>
              ))}
          </Flex>
          <FilteringQuestionDrawer
            question={selectedFilteringQuestion}
            setSelectedFilteringQuestion={setSelectedFilteringQuestion}
            surveyId={surveyId}
          />
          <EditSurveyDrawer
            survey={survey.data}
            questions={questions.data}
            open={editSurveyDrawerVisible}
            onClose={() => setEditSurveyDrawerVisible(false)}
          />
          <InviteGuestModal
            visible={isInviteModalVisible}
            closeModal={() => setInviteModalVisible(false)}
            surveyId={surveyId}
          />
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Data is currently being processed. It should be ready in a few minutes. Thank you for your patience."
        />
      )}
    </StyledDiv>
  );
}

export default SurveyHub;
