import { Flex, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { QCPhase } from '../../../constants';
import { Gray6 } from '../../../styles';
import { responseType, themeType } from '../../../types';
import ThemeTagList from './ThemeTagList';

const { Text } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    max-height: 70vh;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-content {
    padding: 20px 32px 32px;
  }

  .ant-modal-close {
    background: ${Gray6};
    border: 0;
    box-shadow: unset;
  }
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

function ResponseDetailView({
  phase,
  response = null,
  visible,
  closeModal,
  themes = [],
  setResponseToTagId,
  codeFrameId,
}) {
  return (
    <StyledModal open={visible} destroyOnClose onCancel={closeModal} footer={null} width={780}>
      <Flex vertical gap={16}>
        <Flex justify="center">
          <StyledText type="secondary">Response</StyledText>
        </Flex>
        <Text>{response?.text}</Text>
        {response && phase !== QCPhase.SELECT_THEMES && (
          <Flex vertical gap={12}>
            <StyledText type="secondary">Tagged with themes:</StyledText>
            <ThemeTagList
              phase={phase}
              response={response}
              themes={themes}
              setResponseToTagId={setResponseToTagId}
              codeFrameId={codeFrameId}
            />
          </Flex>
        )}
      </Flex>
    </StyledModal>
  );
}

ResponseDetailView.propTypes = {
  phase: PropTypes.string.isRequired,
  response: responseType,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  themes: PropTypes.arrayOf(themeType),
  setResponseToTagId: PropTypes.func.isRequired,
  codeFrameId: PropTypes.number.isRequired,
};

export default ResponseDetailView;
