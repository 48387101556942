import { Card, Collapse, Flex } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Gray2, Gray4, White } from '../../../styles';

const StyledCollapse = styled(Collapse)`
  background: ${White};
  ${(props) => (props.$collapsed ? undefined : 'height: 100%;')};
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
  overflow: ${(props) => (props.$collapsed ? 'visible' : 'auto')};

  .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    padding: 11px 13px;
  }

  .ant-collapse-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    outline: 1px solid ${Gray4};
    outline-offset: -1px;
    border-radius: 8px !important;
    background: ${Gray2};
    border-bottom: 1px solid ${Gray4} !important;
  }

  .ant-collapse-content {
    overflow: auto;
    height: 100%;
  }

  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 0;
  }

  .ant-collapse-expand-icon {
    padding: 0 0 0 8px !important;
  }
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const StyledContent = styled.div`
  overflow: auto;
  padding: 4px 12px 12px;
`;

const StyledFooter = styled.div`
  border-top: 1px solid ${Gray4};
  padding: 8px 9px;
`;

function BaseCollapse({ collapsed, visible, item, loading, ...props }) {
  const items = [
    {
      key: item.key,
      label: item.label,
      children: (
        <StyledFlex vertical justify="space-between">
          <StyledContent>{loading ? <Card size="small" loading /> : item.content}</StyledContent>
          {item.footer && <StyledFooter data-cy="contextual-menu">{item.footer}</StyledFooter>}
        </StyledFlex>
      ),
      extra: item.extra,
      classNames: item.classNames,
    },
  ];

  return (
    <StyledCollapse
      items={items}
      $collapsed={collapsed}
      $visible={visible}
      // we want to be able to use ant design Collapse props
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

BaseCollapse.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    extra: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    footer: PropTypes.node,
    classNames: PropTypes.shape({
      header: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default BaseCollapse;
