import { Button, Modal, Table } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useUsers } from '../../api/Onboarding';

function UsersModal({ visible, onCancel, organization = null }) {
  const { data: users, isLoading } = useUsers(
    { organizationId: organization?.id },
    { enabled: !!organization?.id && visible },
  );

  // Define columns for users table
  const userColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: 'Last Active',
      dataIndex: 'last_active',
      render: (date) => (date ? dayjs(date).format('MMM D, YYYY h:mm A') : '--'),
      sorter: (a, b) => new Date(a.last_active) - new Date(b.last_active),
    },
  ];

  return (
    <Modal
      title={`Users - ${organization?.name}`}
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="close" onClick={onCancel}>
          Close
        </Button>,
      ]}
      width={1200}
    >
      {organization && (
        <div>
          <Table
            dataSource={users?.data || []}
            columns={userColumns}
            rowKey="id"
            loading={isLoading}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showTotal: (total) => `Total ${total} users`,
            }}
          />
        </div>
      )}
    </Modal>
  );
}

UsersModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default UsersModal;
