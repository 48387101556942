import { Flex, Form, Select, Typography } from 'antd';
import PropTypes, { oneOfType } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { suggestedThemeType, themeType } from '../../../types';
import BaseModal from './BaseModal';

const { Text } = Typography;

const StyledForm = styled(Form)`
  padding: 0 12px;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

function AddThemeModal({ visible, closeModal, onAdd, themes = [] }) {
  const [form] = Form.useForm();

  return (
    <BaseModal
      title="Add theme"
      open={visible}
      destroyOnClose
      closable={false}
      okText="Add"
      onCancel={closeModal}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onAdd(values);
            closeModal();
          })
          .catch(() => {});
      }}
    >
      <StyledForm
        form={form}
        layout="vertical"
        autoComplete="off"
        preserve={false}
        requiredMark={false}
      >
        <Flex vertical gap={16}>
          <Form.Item
            label={<Text type="secondary">Theme</Text>}
            name="themeId"
            rules={[{ required: true, message: 'theme is required' }]}
          >
            <Select
              showSearch
              options={themes.map((t) => ({ label: t.name, value: t.id }))}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Flex>
      </StyledForm>
    </BaseModal>
  );
}

AddThemeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  themes: PropTypes.arrayOf(oneOfType([themeType, suggestedThemeType])),
};

export default AddThemeModal;
