import { Avatar, Button, Card, Checkbox, Flex, Modal, Progress, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuestion } from '../../../api/Questions';
import { useTagResponses } from '../../../api/Responses';
import {
  QCPhase,
  ResponseFilterOptions,
  TESelectionType,
  ThemeEnginePanel,
} from '../../../constants';
import useParams from '../../../hooks/useParams';
import {
  Blue1,
  BluePrimary,
  TealProgress,
  ThemeSizeLarge,
  ThemeSizeMedium,
  ThemeSizeSmall,
} from '../../../styles';
import { displayKeyType } from '../../../types';

const { Text } = Typography;
const { confirm } = Modal;

const StyledCard = styled(Card)`
  height: 100%;
  cursor: pointer;
  ${(props) => props.$displayed && `background: ${Blue1};`};
  ${(props) => props.$displayed && `border: 2px solid ${BluePrimary};`};

  .ant-card-body {
    padding: 6px 8px;
  }
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

const StyledSizeAvatar = styled(Avatar)`
  width: 22px;
  height: 22px;
  font-size: 11px;
  background: ${(props) => props.$background};
  color: ${(props) => props.$color};
  flex-shrink: 0;
`;

const StyledPercentageText = styled(Text)`
  font-size: 12px;
  text-wrap: nowrap;
`;

const getSizeAvatarProps = (percentage) => {
  if (percentage <= 0.05) {
    return { ...ThemeSizeSmall, label: 'S' };
  }
  if (percentage <= 0.2) {
    return { ...ThemeSizeMedium, label: 'M' };
  }
  return { ...ThemeSizeLarge, label: 'L' };
};

function ThemeCard({
  panel,
  phase,
  theme,
  checked,
  showDescriptions,
  setSelectedThemes,
  displayKey,
  setDisplayKey,
  selectedResponseIds = [],
  setSelectedResponseIds = () => {},
  setActiveFilter = () => {},
}) {
  const { codeFrameId, questionId, surveyId } = useParams();
  const [isHovered, setHovered] = useState(false);

  const { data: question, isLoading: questionLoading } = useQuestion({ questionId, surveyId });
  const { mutate: tagResponses } = useTagResponses({ codeFrameId });

  const responsePercentage = question?.data
    ? theme.response_ids.length / question.data.response_count
    : 0;

  const { background, color, label } = getSizeAvatarProps(responsePercentage);

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    setDisplayKey({ type: null, key: null });
    setSelectedThemes((prev) => {
      const currType =
        panel === ThemeEnginePanel.EXPLORE_THEMES
          ? TESelectionType.SUGGESTED_THEME
          : TESelectionType.THEME;

      return prev.type === currType
        ? {
            ...prev,
            ids: e.target.checked
              ? [...prev.ids, theme.id]
              : prev.ids.filter((id) => id !== theme.id),
          }
        : {
            type: currType,
            ids: [theme.id],
          };
    });
  };

  const handleCardClick = () => {
    const type =
      panel === ThemeEnginePanel.EXPLORE_THEMES
        ? TESelectionType.SUGGESTED_THEME
        : TESelectionType.THEME;
    setDisplayKey(
      displayKey.key === theme.id ? { type: null, key: null } : { type, key: theme.id },
    );
    setSelectedThemes(
      displayKey.key === theme.id ? { type: null, ids: [] } : { type, ids: [theme.id] },
    );
    if (phase !== QCPhase.SELECT_THEMES) {
      setActiveFilter(ResponseFilterOptions.ALL);
    }
  };

  const confirmRemove = () => {
    confirm({
      title: 'Are you sure you want to remove the theme from the selected responses?',
      content: 'This cannot be undone.',
      okText: 'Remove',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: () => {
        tagResponses({
          data: {
            response_ids: selectedResponseIds,
            theme_id: theme.id,
            code_frame_id: codeFrameId,
            is_theme: false,
          },
        });
        setSelectedResponseIds([]);
      },
    });
  };

  const cardAction = () => {
    if (phase !== QCPhase.SELECT_THEMES) {
      if (isHovered && selectedResponseIds.length) {
        return (
          <Flex vertical gap={4}>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                tagResponses({
                  data: {
                    response_ids: selectedResponseIds,
                    theme_id: theme.id,
                    code_frame_id: codeFrameId,
                    is_theme: true,
                  },
                });
                setSelectedResponseIds([]);
              }}
            >
              Add to theme
            </Button>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                confirmRemove();
              }}
            >
              Remove
            </Button>
          </Flex>
        );
      }
      return (
        <Flex gap={4}>
          <Progress
            type="circle"
            percent={Math.round(responsePercentage * 100)}
            size={20}
            strokeColor={TealProgress}
          />
          <StyledPercentageText type="secondary">
            {Math.round(responsePercentage * 100)}%
          </StyledPercentageText>
        </Flex>
      );
    }
    if (responsePercentage > 0) {
      return (
        <StyledSizeAvatar $background={background} $color={color}>
          {label}
        </StyledSizeAvatar>
      );
    }
    return undefined;
  };

  return (
    <StyledCard
      size="small"
      bordered={false}
      loading={questionLoading}
      onClick={handleCardClick}
      $clickable={panel === ThemeEnginePanel.EXPLORE_THEMES || phase !== QCPhase.SELECT_THEMES}
      $displayed={displayKey.key === theme.id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Flex justify="space-between" align={showDescriptions ? 'start' : 'center'} gap={8}>
        <Flex align="start" gap={8}>
          {phase !== QCPhase.COMPLETE && (
            <Checkbox checked={checked} onClick={handleCheckboxChange} />
          )}
          <Flex vertical gap={2}>
            {theme.name}
            {showDescriptions && <StyledText type="secondary">{theme.description}</StyledText>}
          </Flex>
        </Flex>
        {cardAction()}
      </Flex>
    </StyledCard>
  );
}

ThemeCard.propTypes = {
  panel: PropTypes.string.isRequired,
  phase: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    response_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  showDescriptions: PropTypes.bool.isRequired,
  setSelectedThemes: PropTypes.func.isRequired,
  displayKey: displayKeyType.isRequired,
  setDisplayKey: PropTypes.func.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number),
  setSelectedResponseIds: PropTypes.func,
  setActiveFilter: PropTypes.func,
};

export default ThemeCard;
