import { Card, Col, Divider, Flex, Progress, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useQuestion, useSentiments } from '../../../api/Questions';
import { ResponseFilterOptions, TESelectionType } from '../../../constants';
import useParams from '../../../hooks/useParams';
import { Blue1, BluePrimary, TealProgress } from '../../../styles';
import { displayKeyType } from '../../../types';

const { Text } = Typography;

const StyledDivider = styled(Divider)`
  margin: 4px 0;
`;
const StyledFlex = styled(Flex)`
  padding: 0 10px;
`;

const StyledCard = styled(Card)`
  height: 100%;
  cursor: pointer;
  ${(props) => props.$displayed && `background: ${Blue1};`};
  ${(props) => props.$displayed && `border: 2px solid ${BluePrimary};`};

  .ant-card-body {
    padding: 12px;
  }
`;

const StyledPercentageText = styled(Text)`
  font-size: 12px;
  text-wrap: nowrap;
`;

function SentimentDisplay({
  themeSpan,
  displayKey,
  setDisplayKey,
  setSelectedThemes,
  setActiveFilter,
}) {
  const { questionId, surveyId } = useParams();

  const { data: question, isLoading: questionLoading } = useQuestion({ questionId, surveyId });
  const { data: sentiments, isLoading: sentimentsLoading } = useSentiments({
    questionId,
    surveyId,
  });

  const totalResponses = question?.data?.response_count || 0;
  const sentimentData = sentiments?.data || {};

  const handleCardClick = (sentiment) => {
    setDisplayKey(
      sentiment === displayKey.key
        ? { type: null, key: null }
        : { type: TESelectionType.SENTIMENT, key: sentiment },
    );
    setSelectedThemes({ type: null, ids: [] });
    setActiveFilter(ResponseFilterOptions.ALL);
  };

  const sentimentsArray = [
    {
      label: 'Positive',
      emoji: '🟢',
      percentage: totalResponses ? (sentimentData.positive_count || 0) / totalResponses : 0,
    },
    {
      label: 'Negative',
      emoji: '🔴',
      percentage: totalResponses ? (sentimentData.negative_count || 0) / totalResponses : 0,
    },
    {
      label: 'Neutral',
      emoji: '⚪',
      percentage: totalResponses ? (sentimentData.neutral_count || 0) / totalResponses : 0,
    },
  ];

  return (
    <>
      <StyledDivider />
      <StyledFlex vertical gap={10}>
        Sentiment analysis
        <Row gutter={[10, 10]}>
          {sentimentsArray.map(({ label, emoji, percentage }) => (
            <Col key={label} span={themeSpan}>
              <StyledCard
                size="small"
                bordered={false}
                loading={questionLoading || sentimentsLoading}
                onClick={() => handleCardClick(label)}
                $displayed={displayKey.key === label}
              >
                <Flex justify="space-between" align="center" gap={8}>
                  <Space>
                    {emoji}
                    {label}
                  </Space>
                  <Flex gap={4}>
                    <Progress
                      type="circle"
                      percent={Math.round(percentage * 100)}
                      size={20}
                      strokeColor={TealProgress}
                    />
                    <StyledPercentageText type="secondary">
                      {Math.round(percentage * 100)}%
                    </StyledPercentageText>
                  </Flex>
                </Flex>
              </StyledCard>
            </Col>
          ))}
        </Row>
      </StyledFlex>
    </>
  );
}

SentimentDisplay.propTypes = {
  themeSpan: PropTypes.number.isRequired,
  displayKey: displayKeyType.isRequired,
  setDisplayKey: PropTypes.func.isRequired,
  setSelectedThemes: PropTypes.func.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
};

export default SentimentDisplay;
