import { EditOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Flex, Statistic, Tabs, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useInvitations } from '../../api/Invitations';
import { useOrganization, useUsers } from '../../api/Onboarding';
import BackButton from '../../components/BackButton';
import InviteButton from '../../components/InviteButton';
import InviteMemberModal from '../../components/InviteMemberModal';
import Loading from '../../components/Loading';
import { QueryKeys, SettingsPageTabs, UserRoleType } from '../../constants';
import EditOrganizationModal from './EditOrganizationModal';
import InvitationsTab from './InvitationsTab';
import UsersTab from './UsersTab';

const { Text, Title } = Typography;

const StyledDiv = styled.div`
  padding: 0 24px 24px;
`;

const StyledStatistic = styled(Statistic)`
  padding-right: 24px;
`;

function Settings() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(SettingsPageTabs.USERS);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const { data: organization, isLoading: organizationLoading } = useOrganization();
  const { data: users, isLoading: usersLoading } = useUsers(
    { organizationId: organization?.data.id },
    {
      enabled: !!organization?.data.id,
      select: (data) => ({
        ...data,
        data: data.data.filter((u) => u.role !== UserRoleType.CLIENT),
      }),
    },
  );
  const { data: invitations, isLoading: invitationsLoading } = useInvitations({
    select: (data) => ({
      ...data,
      data: data.data.filter((i) => i.role !== UserRoleType.CLIENT),
    }),
  });

  const toggleInviteModal = () => {
    setShowInviteModal(!showInviteModal);
  };

  const toggleEditOrganizationModal = () => {
    setShowOrganizationModal(!showOrganizationModal);
  };

  const tabData = [
    {
      key: SettingsPageTabs.USERS,
      label: SettingsPageTabs.USERS,
    },
    {
      key: SettingsPageTabs.INVITATIONS,
      label: SettingsPageTabs.INVITATIONS,
    },
  ];

  const onInvite = useCallback(
    (response, data) => {
      const formData = data.data;
      // put the new invitation at the top of the list
      const updatedInvitations = [
        {
          id: response.data.invitation_id,
          token: response.data.token,
          name: formData.name,
          email: formData.email,
          role: formData.role,
          is_expired: false,
        },
        ...invitations.data,
      ];
      queryClient.setQueryData([QueryKeys.INVITATIONS], {
        ...invitations,
        data: updatedInvitations,
      });
    },
    [invitations, queryClient],
  );

  if (organizationLoading || usersLoading || invitationsLoading) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <BackButton onClick={() => navigate(-1)} text="Back" />
      <Flex vertical gap={16}>
        <Flex justify="space-between" align="end">
          <Flex vertical>
            <Title level={4}>Settings</Title>
            <Text>
              <b>Organization:</b> {organization.data.name}
              <Button
                onClick={toggleEditOrganizationModal}
                type="link"
                size="small"
                icon={<EditOutlined />}
              />
            </Text>
          </Flex>
          <StyledStatistic title="Token balance" value={organization.data.available_tokens} />
        </Flex>
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={tabData}
          tabBarExtraContent={<InviteButton onClick={toggleInviteModal} />}
        />
        {activeTab === SettingsPageTabs.USERS && (
          <UsersTab users={users} organizationId={organization.data.id} />
        )}
        {activeTab === SettingsPageTabs.INVITATIONS && <InvitationsTab invitations={invitations} />}
      </Flex>
      <InviteMemberModal
        closeModal={toggleInviteModal}
        visible={showInviteModal}
        onInvite={onInvite}
      />
      <EditOrganizationModal
        closeModal={toggleEditOrganizationModal}
        visible={showOrganizationModal}
        organization={organization}
      />
    </StyledDiv>
  );
}

export default Settings;
