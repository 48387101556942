import {
  CloseOutlined,
  DownOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Flex, Modal, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useGroupThemes } from '../../../api/CodeFrames';
import { useDismissSuggestedThemes } from '../../../api/LLM';
import { useSuggestedGroups } from '../../../api/Questions';
import { useTagResponses } from '../../../api/Responses';
import {
  FilterSortType,
  TESelectionType,
  ThemeEnginePanel,
  ThemeSortOptions,
} from '../../../constants';
import useParams from '../../../hooks/useParams';
import { Gray5, Gray6, White } from '../../../styles';
import {
  displayKeyType,
  searchFilterType,
  selectedThemesType,
  suggestedThemeType,
  themeType,
} from '../../../types';
import AddToModal from '../components/AddToModal';
import BaseCollapse from '../components/BaseCollapse';
import CreateEditGroupModal from '../components/CreateEditGroupModal';
import FilterSortDropdown from '../components/FilterSortDropdown';
import MergeModal from '../components/MergeModal';
import SuggestedThemesDisplay from './SuggestedThemesDisplay';

const { Text } = Typography;
const { confirm } = Modal;

const TitleText = styled(Text)`
  font-weight: 600;
  font-size: 18px;
`;

const StyledButton = styled(Button)`
  background: ${Gray6};
  border: 0;
  box-shadow: unset;
  color: ${White} !important;

  &&.ant-btn:hover {
    background: ${Gray5};
    color: ${White};
  }
`;

const StyledCloseButton = styled(Button)`
  color: ${Gray5};
`;

function ExploreThemes({
  themes,
  suggestedThemes,
  phase,
  fullScreen = null,
  setFullScreen,
  isExpanded,
  showDescriptions,
  themeSpan,
  searchFilters,
  selectedThemes,
  setSelectedThemes,
  collapseKey,
  setCollapseKey,
  displayKey,
  setDisplayKey,
  setScrollNeeded,
  createUpdateThemes,
}) {
  const { questionId, codeFrameId } = useParams();

  const [activeSort, setActiveSort] = useState(ThemeSortOptions.SUGGESTED_GROUPS);
  const [addToModalVisible, setAddToModalVisible] = useState(false);
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [newGroupModalVisible, setNewGroupModalVisible] = useState(false);

  const { data: suggestedGroups, isLoading: suggestedGroupsLoading } = useSuggestedGroups({
    questionId,
  });

  const { mutate: dismissSuggestedThemes } = useDismissSuggestedThemes({ codeFrameId });
  const { mutate: groupThemes } = useGroupThemes({ codeFrameId });
  const { mutate: tagResponses } = useTagResponses({ codeFrameId });

  const addSuggestedThemes = () => {
    setDisplayKey({ type: null, key: null });
    const data = selectedThemes.ids.map((id) => ({
      name: suggestedThemes.find((t) => t.id === id).name,
      code_frame_id: codeFrameId,
      suggested_theme_id: id,
    }));
    createUpdateThemes.mutate(
      { data },
      {
        onSuccess: async ({ data: themeData }) => {
          selectedThemes.ids.forEach((id, index) => {
            tagResponses({
              data: {
                response_ids: suggestedThemes.find((t) => t.id === id).response_ids,
                theme_id: themeData.theme_ids[index],
                code_frame_id: codeFrameId,
              },
            });
          });
          setSelectedThemes({ type: null, ids: [] });
          setScrollNeeded(true);
        },
      },
    );
  };

  const addToGroup = (groupId) => {
    setDisplayKey({ type: null, key: null });
    const data = selectedThemes.ids.map((id) => ({
      name: suggestedThemes.find((t) => t.id === id).name,
      code_frame_id: codeFrameId,
      suggested_theme_id: id,
    }));
    createUpdateThemes.mutate(
      { data },
      {
        onSuccess: ({ data: themeData }) => {
          groupThemes({
            data: {
              group_id: groupId,
              theme_ids: themeData.theme_ids,
            },
          });
          selectedThemes.ids.forEach((id, index) => {
            tagResponses({
              data: {
                response_ids: suggestedThemes.find((t) => t.id === id).response_ids,
                theme_id: themeData.theme_ids[index],
                code_frame_id: codeFrameId,
              },
            });
          });
          setSelectedThemes({ type: null, ids: [] });
        },
      },
    );
  };

  const mergeThemes = async ({ themeId, name, description }) => {
    setDisplayKey({ type: null, key: null });
    const data = [
      {
        name,
        description,
        code_frame_id: codeFrameId,
        suggested_theme_id: themeId,
      },
    ];
    const responseIds = [
      ...new Set(
        selectedThemes.ids.flatMap((id) => suggestedThemes.find((t) => t.id === id).response_ids),
      ),
    ];
    createUpdateThemes.mutate(
      { data },
      {
        onSuccess: async ({ data: themeData }) => {
          tagResponses({
            data: {
              response_ids: responseIds,
              theme_id: themeData.theme_ids[0],
              code_frame_id: codeFrameId,
            },
          });
          dismissSuggestedThemes({
            suggestedThemeIds: selectedThemes.ids.filter((id) => id !== themeId),
          });
          setSelectedThemes({ type: null, ids: [] });
          setScrollNeeded(true);
        },
      },
    );
  };

  const newGroupWithSelection = ({ name }) => {
    setDisplayKey({ type: null, key: null });
    const newGroup = {
      name,
      code_frame_id: codeFrameId,
    };
    const newThemes = selectedThemes.ids.map((id) => ({
      name: suggestedThemes.find((t) => t.id === id).name,
      code_frame_id: codeFrameId,
      suggested_theme_id: id,
    }));
    const data = [newGroup, ...newThemes];
    createUpdateThemes.mutate(
      { data },
      {
        onSuccess: async ({ data: themeData }) => {
          groupThemes({
            data: {
              group_id: themeData.theme_ids[0],
              theme_ids: themeData.theme_ids.slice(1),
            },
          });
          selectedThemes.ids.forEach((id, index) => {
            tagResponses({
              data: {
                response_ids: suggestedThemes.find((t) => t.id === id).response_ids,
                theme_id: themeData.theme_ids[index + 1], // skip the first theme (group)
                code_frame_id: codeFrameId,
              },
            });
          });
          setSelectedThemes({ type: null, ids: [] });
        },
      },
    );
  };

  const deleteSuggestedThemes = () => {
    setDisplayKey({ type: null, key: null });
    dismissSuggestedThemes({ suggestedThemeIds: selectedThemes.ids });
    setSelectedThemes({ type: null, ids: [] });
  };

  const confirmDelete = () => {
    confirm({
      title: 'Are you sure you want to delete the selected AI suggested themes?',
      content: 'This cannot be undone.',
      okText: 'Delete',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: deleteSuggestedThemes,
    });
  };

  const onCollapseChange = () => {
    if (collapseKey.length > 1) {
      setCollapseKey([ThemeEnginePanel.EXPLORE_THEMES]);
    } else if (collapseKey.includes(ThemeEnginePanel.EXPLORE_THEMES)) {
      setCollapseKey([ThemeEnginePanel.EXPLORE_THEMES, ThemeEnginePanel.MY_CODE_FRAME]);
    } else if (collapseKey.includes(ThemeEnginePanel.MY_CODE_FRAME)) {
      setCollapseKey([ThemeEnginePanel.EXPLORE_THEMES]);
    }
  };

  const actionButtons = [
    { text: 'Delete', onClick: confirmDelete, color: 'danger', variant: 'outlined', danger: true },
    ...(selectedThemes.ids.length > 1
      ? [{ text: 'Merge', onClick: () => setMergeModalVisible(true) }]
      : []),
    { text: 'Add as new group', onClick: () => setNewGroupModalVisible(true) },
    { text: 'Add to...', onClick: () => setAddToModalVisible(true) },
    { text: 'Add', onClick: addSuggestedThemes, type: 'primary' },
  ];

  const item = {
    key: ThemeEnginePanel.EXPLORE_THEMES,
    label: <TitleText>AI generated themes</TitleText>,
    content: (
      <SuggestedThemesDisplay
        phase={phase}
        activeSort={activeSort}
        selectedThemes={selectedThemes}
        setSelectedThemes={setSelectedThemes}
        showDescriptions={showDescriptions}
        suggestedGroups={suggestedGroups?.data}
        suggestedThemes={suggestedThemes}
        themeSpan={themeSpan}
        searchFilters={searchFilters}
        displayKey={displayKey}
        setDisplayKey={setDisplayKey}
      />
    ),
    extra: (
      <Space size={14}>
        <FilterSortDropdown
          type={FilterSortType.SORT}
          options={ThemeSortOptions}
          activeOption={activeSort}
          onChange={setActiveSort}
          isExpanded={!!fullScreen || isExpanded}
        />
        <StyledButton
          icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={() => setFullScreen(fullScreen ? null : ThemeEnginePanel.EXPLORE_THEMES)}
        />
      </Space>
    ),
    footer: selectedThemes.type === TESelectionType.SUGGESTED_THEME &&
      selectedThemes.ids.length > 0 && (
        <Flex justify="space-between" align="center">
          <Flex gap={4}>
            <StyledCloseButton
              type="text"
              size="small"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => {
                setSelectedThemes({ type: null, ids: [] });
              }}
            />
            <Text type="secondary">{selectedThemes.ids.length} selected</Text>
          </Flex>
          {isExpanded ? (
            <Space>
              {actionButtons.map(({ text, onClick, ...props }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Button key={text} onClick={onClick} {...props}>
                  {text}
                </Button>
              ))}
            </Space>
          ) : (
            <Dropdown
              menu={{
                items: actionButtons.map(({ text, onClick, ...props }) => ({
                  label: text,
                  key: text,
                  onClick,
                  ...props,
                })),
              }}
            >
              <Button>
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        </Flex>
      ),
  };

  const expandIcon = () =>
    fullScreen ? null : (
      <StyledButton
        icon={
          collapseKey.length > 1 || !collapseKey.includes(ThemeEnginePanel.EXPLORE_THEMES) ? (
            <PlusOutlined />
          ) : (
            <MinusOutlined />
          )
        }
      />
    );

  return (
    <>
      <BaseCollapse
        collapsible="icon"
        expandIcon={expandIcon}
        expandIconPosition="end"
        bordered={false}
        activeKey={fullScreen ? [fullScreen] : collapseKey}
        onChange={onCollapseChange}
        item={item}
        loading={suggestedGroupsLoading}
        collapsed={!fullScreen && !collapseKey.includes(ThemeEnginePanel.EXPLORE_THEMES)}
        visible={fullScreen !== ThemeEnginePanel.MY_CODE_FRAME}
      />
      <AddToModal
        panel={ThemeEnginePanel.EXPLORE_THEMES}
        visible={addToModalVisible}
        setVisible={setAddToModalVisible}
        onMove={addToGroup}
        groups={themes.filter((t) => t.is_parent)}
      />
      <MergeModal
        panel={ThemeEnginePanel.EXPLORE_THEMES}
        visible={mergeModalVisible}
        setVisible={setMergeModalVisible}
        onMerge={mergeThemes}
        themes={suggestedThemes.filter((t) => selectedThemes.ids.includes(t.id))}
      />
      <CreateEditGroupModal
        visible={newGroupModalVisible}
        setVisible={setNewGroupModalVisible}
        onOk={newGroupWithSelection}
      />
    </>
  );
}

ExploreThemes.propTypes = {
  themes: PropTypes.arrayOf(themeType).isRequired,
  suggestedThemes: PropTypes.arrayOf(suggestedThemeType).isRequired,
  phase: PropTypes.string.isRequired,
  fullScreen: PropTypes.string,
  setFullScreen: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  showDescriptions: PropTypes.bool.isRequired,
  themeSpan: PropTypes.number.isRequired,
  searchFilters: searchFilterType.isRequired,
  selectedThemes: selectedThemesType.isRequired,
  setSelectedThemes: PropTypes.func.isRequired,
  collapseKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCollapseKey: PropTypes.func.isRequired,
  displayKey: displayKeyType.isRequired,
  setDisplayKey: PropTypes.func.isRequired,
  setScrollNeeded: PropTypes.func.isRequired,
  createUpdateThemes: PropTypes.shape({
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default ExploreThemes;
