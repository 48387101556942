import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import useSelfServeOrganization from '../../hooks/useSelfServeOrganization';
import { GreenPrimary } from '../../styles';

const { Text, Title } = Typography;

const StyledFlex = styled(Flex)`
  margin-top: calc(100vh * 0.175);
`;

const StyledCheckCircle = styled(CheckCircleFilled)`
  color: ${GreenPrimary};
  font-size: 70px;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

function UploadSuccessful() {
  const navigate = useNavigate();
  const { isLoading: organizationLoading, selfServeEnabled } = useSelfServeOrganization();

  if (organizationLoading) {
    return <Loading />;
  }

  return (
    <StyledFlex vertical align="center" gap={16}>
      <Flex vertical align="center" gap={2}>
        <StyledCheckCircle />
        <Title level={3}>Success!</Title>
        {selfServeEnabled ? (
          <>
            <StyledText type="secondary">
              Your data is in, and Fathom AI is crunching through it to uncover key themes.
            </StyledText>
            <StyledText type="secondary">
              Feel free to step away — we&apos;ll email you when it&apos;s time to confirm your code
              frame!
            </StyledText>
          </>
        ) : (
          <>
            <StyledText type="secondary">We will begin analyzing your responses.</StyledText>
            <StyledText type="secondary">
              Customer Success will reach out within one business day.
            </StyledText>
          </>
        )}
      </Flex>
      <Button type="primary" onClick={() => navigate('/')}>
        Go to My Surveys
      </Button>
    </StyledFlex>
  );
}

export default UploadSuccessful;
