import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Flex,
  Image,
  Modal,
  Row,
  Space,
  Steps,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCodeFrameThemes, useOmitTheme, useSuggestedThemes } from '../../api/CodeFrames';
import { useQuestion, useUpdateQuestionPhase } from '../../api/Questions';
import { useTagResponses } from '../../api/Responses';
import { useSurvey } from '../../api/Surveys';
import { useCreateUpdateThemes } from '../../api/Themes';
import Loading from '../../components/Loading';
import {
  QCPhase,
  QCPhaseText,
  QueryKeys,
  ResponseFilterOptions,
  TESelectionType,
  ThemeEnginePanel,
} from '../../constants';
import useParams from '../../hooks/useParams';
import { BluePrimary, White } from '../../styles';
import ExploreRawData from './ExploreRawData';
import ExploreThemes from './ExploreThemes';
import MyCodeFrame from './MyCodeFrame';
import Remapping from './Remapping';
import ThemeEngineIntro from './ThemeEngineIntro';
import BeginMappingModal from './components/BeginMappingModal';
import ExpandPanelToggle from './components/ExpandPanelToggle';
import SearchBar from './components/SearchBar';
import ThemeChartModal from './components/ThemeChartModal';
import ThemeDetailView from './components/ThemeDetailView';

const { Text, Paragraph } = Typography;
const { success } = Modal;

const PageFlex = styled(Flex)`
  height: 100%;
  padding: 4px 16px 8px;
`;

const StyledText = styled(Text)`
  cursor: pointer;
`;

const StyledSpace = styled(Space)`
  cursor: pointer;
  white-space: nowrap;
`;

const StyledSteps = styled(Steps)`
  padding-top: 16px;

  .ant-steps-item-title {
    display: none;
  }

  .ant-steps-item-active .ant-steps-item-title {
    font-size: 12px;
    display: inline-block;
    color: ${BluePrimary} !important;
  }

  .ant-steps-item-content {
    margin-top: 2px !important;
  }
`;

const StyledButton = styled(Button)`
  box-shadow: unset;
`;

const ContentFlex = styled(Flex)`
  overflow: auto;
  height: 100%;
  padding-top: 4px;
`;

const PanelFlex = styled(Flex)`
  overflow: auto;
  display: ${(props) => (props.$isVisible ? 'flex' : 'none')};
`;

const StyledAlert = styled(Alert)`
  background: ${BluePrimary};
  border: none;
  color: ${White};
`;

const stepMap = {
  [QCPhase.READY]: 0,
  [QCPhase.SELECT_THEMES]: 0,
  [QCPhase.REMAPPING]: 1,
  [QCPhase.REMAPPING_COMPLETE]: 2,
  [QCPhase.REVIEW]: 2,
  [QCPhase.COMPLETE]: 3,
};

function ThemeEngine() {
  const navigate = useNavigate();
  const { surveyId, questionId, codeFrameId } = useParams();
  const queryClient = useQueryClient();

  const [fullScreen, setFullScreen] = useState(null);
  const [isThemesPanelExpanded, setThemesPanelExpanded] = useState(true);
  const [showDescriptions, setShowDescriptions] = useState(true);
  const [beginMappingModalVisible, setBeginMappingModalVisible] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(false);
  const [displayKey, setDisplayKey] = useState({ type: null, key: null });
  const [selectedResponseIds, setSelectedResponseIds] = useState([]);
  const [searchFilters, setSearchFilters] = useState({ themes: '', responses: '', keywords: '' });
  const [advancedSearchEnabled, setAdvancedSearchEnabled] = useState(false);
  const [selectedThemes, setSelectedThemes] = useState({ type: null, ids: [] });
  const [collapseKey, setCollapseKey] = useState([
    ThemeEnginePanel.EXPLORE_THEMES,
    ThemeEnginePanel.MY_CODE_FRAME,
  ]);
  const [themeChartModalVisible, setThemeChartModalVisible] = useState(false);
  const [scrollNeeded, setScrollNeeded] = useState(false);
  const [activeFilter, setActiveFilter] = useState(ResponseFilterOptions.ALL);

  useEffect(() => {
    setDisplayKey({ type: null, key: null });
    setSelectedThemes({ type: null, ids: [] });
  }, [fullScreen]);

  const { data: survey, isLoading: surveyLoading } = useSurvey({ surveyId });
  const { data: themes, isLoading: themesLoading } = useCodeFrameThemes({ codeFrameId });
  const { data: suggestedThemes, isLoading: suggestedThemesLoading } = useSuggestedThemes({
    codeFrameId,
  });
  const { data: question, isLoading: questionLoading } = useQuestion(
    { questionId, surveyId },
    {
      refetchInterval,
      onSuccess: ({ data }) => {
        if (!refetchInterval && data.qc_phase === QCPhase.REMAPPING) {
          setRefetchInterval(3000);
        } else if (refetchInterval && data.qc_phase !== QCPhase.REMAPPING) {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.CODE_FRAME_THEMES, { codeFrameId }],
          });
          setRefetchInterval(false);
        }
      },
    },
  );
  const { mutate: updateQuestionPhase, isLoading: updateQuestionPhaseLoading } =
    useUpdateQuestionPhase({ questionId, surveyId });
  const createUpdateThemes = useCreateUpdateThemes({ codeFrameId });
  const { mutate: omitTheme } = useOmitTheme({ codeFrameId });
  const { mutate: tagResponses } = useTagResponses({ codeFrameId });

  const handleEditTheme = ({ name, description, omitted = null }) => {
    if (displayKey.type === TESelectionType.SUGGESTED_THEME) {
      setDisplayKey({ type: null, key: null });
    }
    createUpdateThemes.mutate(
      {
        data: [
          {
            id: displayKey.type === TESelectionType.THEME ? displayKey.key : null,
            name,
            description,
            code_frame_id: codeFrameId,
            suggested_theme_id:
              displayKey.type === TESelectionType.SUGGESTED_THEME ? displayKey.key : null,
          },
        ],
      },
      {
        onSuccess: ({ data }) => {
          if (displayKey.type === TESelectionType.SUGGESTED_THEME) {
            setScrollNeeded(true);
            tagResponses({
              data: {
                response_ids: suggestedThemes.data.find((t) => t.id === displayKey.key)
                  .response_ids,
                theme_id: data.theme_ids[0],
                code_frame_id: codeFrameId,
                is_theme: true,
              },
            });
          }
        },
      },
    );
    if (
      omitted !== null &&
      omitted !== themes.data.find((t) => t.id === displayKey.key).is_omitted
    ) {
      omitTheme({ themeId: displayKey.key, data: { is_omitted: omitted } });
    }
  };

  const handleCodingComplete = async () => {
    updateQuestionPhase(
      { data: { qc_phase: QCPhase.COMPLETE } },
      {
        onSuccess: () => {
          success({
            title: 'Nice work! 🎉',
            content: (
              <>
                <Paragraph>This question is now ready for analysis.</Paragraph>
                <Paragraph>
                  Once you&apos;ve completed coding for all questions, you can launch analysis to
                  run stats, generate reporting, and highlight key insights.
                </Paragraph>
              </>
            ),
            onOk() {
              navigate(`/surveys/${surveyId}/hub`);
            },
            okText: 'Go to Survey Hub',
            icon: null,
          });
        },
      },
    );
  };

  const viewMenuItems = [
    {
      key: 'toggle-desc',
      label: showDescriptions ? 'Hide theme descriptions' : 'Show theme descriptions',
      icon: showDescriptions ? <EyeInvisibleOutlined /> : <EyeOutlined />,
      onClick: () => setShowDescriptions((prev) => !prev),
    },
  ];

  if (surveyLoading || themesLoading || questionLoading || suggestedThemesLoading) {
    return <Loading />;
  }

  let themeSpan;

  if (fullScreen) {
    themeSpan = 8;
  } else if (isThemesPanelExpanded) {
    themeSpan = 12;
  } else {
    themeSpan = 24;
  }

  return (
    <>
      {question.data.qc_phase === QCPhase.READY ? (
        <ThemeEngineIntro />
      ) : (
        <PageFlex vertical gap={12}>
          <Row align="middle" wrap={false}>
            <Col span={8}>
              <Flex gap={8}>
                <StyledSpace gap={6} onClick={() => navigate('/')}>
                  <Image
                    alt="logo"
                    preview={false}
                    width={25}
                    src={`${process.env.REACT_APP_IMGIX_URL}/static/fathom-icon.svg`}
                  />
                  <Text type="secondary">My Surveys</Text>
                </StyledSpace>
                <Text type="secondary">/</Text>
                <StyledText
                  onClick={() => navigate(`/surveys/${surveyId}/hub`)}
                  ellipsis={{ tooltip: true }}
                >
                  {survey.data.name}
                </StyledText>
                <Text type="secondary">/</Text>
                <Text ellipsis={{ tooltip: true }}>{question.data.text}</Text>
              </Flex>
            </Col>
            <Col span={8}>
              <StyledSteps
                progressDot
                current={stepMap[question.data.qc_phase]}
                items={[
                  { title: QCPhaseText[QCPhase.SELECT_THEMES] },
                  { title: QCPhaseText[QCPhase.REMAPPING] },
                  { title: QCPhaseText[question.data.qc_phase] },
                  { title: QCPhaseText[QCPhase.COMPLETE] },
                ]}
              />
            </Col>
            <Col span={8}>
              <Flex justify="end" gap={8}>
                {question.data.qc_phase === QCPhase.SELECT_THEMES && (
                  <StyledButton type="primary" onClick={() => setBeginMappingModalVisible(true)}>
                    Map code frame
                  </StyledButton>
                )}
                {(question.data.qc_phase === QCPhase.REVIEW ||
                  question.data.qc_phase === QCPhase.COMPLETE) && (
                  <StyledButton onClick={() => setThemeChartModalVisible(true)}>
                    View theme chart
                  </StyledButton>
                )}
                {question.data.qc_phase === QCPhase.REVIEW && (
                  <StyledButton
                    type="primary"
                    onClick={handleCodingComplete}
                    loading={updateQuestionPhaseLoading}
                  >
                    Coding complete
                  </StyledButton>
                )}
                {question.data.qc_phase === QCPhase.COMPLETE && (
                  <StyledButton
                    type="primary"
                    onClick={() => updateQuestionPhase({ data: { qc_phase: QCPhase.REVIEW } })}
                    loading={updateQuestionPhaseLoading}
                  >
                    Revise coding
                  </StyledButton>
                )}
              </Flex>
            </Col>
          </Row>
          {question.data.qc_phase === QCPhase.COMPLETE && (
            <StyledAlert
              message={
                <Flex gap={8} justify="center">
                  <InfoCircleOutlined />
                  Coding for this question is complete and locked in. If you need to adjust
                  anything, click &apos;Revise coding&apos;.
                </Flex>
              }
            />
          )}
          {question.data.qc_phase === QCPhase.REMAPPING ||
          question.data.qc_phase === QCPhase.REMAPPING_COMPLETE ? (
            <Remapping qcPhase={question.data.qc_phase} />
          ) : (
            <>
              <Flex align="center" gap={32}>
                <SearchBar
                  setSearchFilters={setSearchFilters}
                  advancedSearchEnabled={advancedSearchEnabled}
                  setAdvancedSearchEnabled={setAdvancedSearchEnabled}
                />
                <Dropdown menu={{ items: viewMenuItems, selectable: false }}>
                  <Space>
                    View
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </Flex>
              <ContentFlex gap={3}>
                <PanelFlex
                  vertical
                  flex={isThemesPanelExpanded ? 2 : 1}
                  gap={12}
                  $isVisible={fullScreen !== ThemeEnginePanel.EXPLORE_RAW_DATA}
                >
                  <MyCodeFrame
                    themes={themes.data}
                    phase={question.data.qc_phase}
                    fullScreen={fullScreen}
                    setFullScreen={setFullScreen}
                    showDescriptions={showDescriptions}
                    themeSpan={themeSpan}
                    displayKey={displayKey}
                    setDisplayKey={setDisplayKey}
                    selectedResponseIds={selectedResponseIds}
                    setSelectedResponseIds={setSelectedResponseIds}
                    searchFilters={searchFilters}
                    selectedThemes={selectedThemes}
                    setSelectedThemes={setSelectedThemes}
                    collapseKey={collapseKey}
                    setCollapseKey={setCollapseKey}
                    isExpanded={isThemesPanelExpanded}
                    scrollNeeded={scrollNeeded}
                    setScrollNeeded={setScrollNeeded}
                    setActiveFilter={setActiveFilter}
                    createUpdateThemes={createUpdateThemes}
                  />
                  {question.data.qc_phase === QCPhase.SELECT_THEMES && (
                    <ExploreThemes
                      themes={themes.data}
                      suggestedThemes={suggestedThemes.data}
                      phase={question.data.qc_phase}
                      fullScreen={fullScreen}
                      setFullScreen={setFullScreen}
                      isExpanded={isThemesPanelExpanded}
                      showDescriptions={showDescriptions}
                      themeSpan={themeSpan}
                      searchFilters={searchFilters}
                      selectedThemes={selectedThemes}
                      setSelectedThemes={setSelectedThemes}
                      collapseKey={collapseKey}
                      setCollapseKey={setCollapseKey}
                      displayKey={displayKey}
                      setDisplayKey={setDisplayKey}
                      setScrollNeeded={setScrollNeeded}
                      createUpdateThemes={createUpdateThemes}
                    />
                  )}
                </PanelFlex>
                {!fullScreen && (
                  <ExpandPanelToggle
                    isThemesPanelExpanded={isThemesPanelExpanded}
                    onClick={() => setThemesPanelExpanded((prev) => !prev)}
                  />
                )}
                <PanelFlex
                  flex={isThemesPanelExpanded ? 1 : 2}
                  $isVisible={!fullScreen || fullScreen === ThemeEnginePanel.EXPLORE_RAW_DATA}
                >
                  <ExploreRawData
                    phase={question.data.qc_phase}
                    themes={themes.data}
                    suggestedThemes={suggestedThemes.data}
                    fullScreen={fullScreen}
                    setFullScreen={setFullScreen}
                    isExpanded={!isThemesPanelExpanded}
                    displayKey={displayKey}
                    setDisplayKey={setDisplayKey}
                    question={question.data}
                    selectedResponseIds={selectedResponseIds}
                    setSelectedResponseIds={setSelectedResponseIds}
                    searchFilters={searchFilters}
                    advancedSearchEnabled={advancedSearchEnabled}
                    handleEditTheme={handleEditTheme}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                </PanelFlex>
              </ContentFlex>
            </>
          )}
          <BeginMappingModal
            visible={beginMappingModalVisible}
            setVisible={setBeginMappingModalVisible}
            numThemes={themes.data.filter((t) => !t.is_parent).length}
            questionId={questionId}
            surveyId={surveyId}
            onStart={() => setRefetchInterval(3000)}
          />
          <ThemeChartModal
            visible={themeChartModalVisible}
            closeModal={() => setThemeChartModalVisible(false)}
            surveyId={surveyId}
            questionId={questionId}
          />
          <ThemeDetailView
            phase={question.data.qc_phase}
            theme={
              displayKey.type === TESelectionType.THEME
                ? themes?.data.find((t) => t.id === displayKey.key)
                : suggestedThemes?.data.find((t) => t.id === displayKey.key)
            }
            visible={!!(fullScreen && displayKey.type)}
            closeModal={() => setDisplayKey({ type: null, key: null })}
            onOk={handleEditTheme}
            codeFrameId={codeFrameId}
            questionId={questionId}
            surveyId={surveyId}
            displayKey={displayKey}
          />
        </PageFlex>
      )}
    </>
  );
}

export default ThemeEngine;
