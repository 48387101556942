import PropTypes from 'prop-types';

export const surveyType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  survey_tool: PropTypes.string,
  survey_goals: PropTypes.string,
  survey_status: PropTypes.string,
  survey_type: PropTypes.string,
  created_at: PropTypes.string,
  organization_name: PropTypes.string,
});

export const questionType = PropTypes.shape({
  id: PropTypes.number,
  text: PropTypes.string,
  is_filterable: PropTypes.bool,
  has_themes: PropTypes.bool,
  question_order: PropTypes.number,
  qc_phase: PropTypes.string,
  display_sentiment: PropTypes.bool,
  survey_id: PropTypes.number,
  response_options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      order_id: PropTypes.number,
    }),
  ),
});

export const dashboardThemeType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  percentage: PropTypes.number,
  response_count: PropTypes.number,
  positive_count: PropTypes.number,
  negative_count: PropTypes.number,
  neutral_count: PropTypes.number,
});

export const questionThemeType = PropTypes.shape({
  data: dashboardThemeType,
  children: PropTypes.arrayOf(dashboardThemeType),
});

export const themeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  response_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  is_parent: PropTypes.bool.isRequired,
  parent_theme_id: PropTypes.number,
  is_omitted: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
});

export const themeGroupType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  children: PropTypes.arrayOf(themeType),
});

export const surveyDetailsType = PropTypes.shape({
  surveyDetails: PropTypes.shape({
    file: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
    survey_goals: PropTypes.string,
    survey_title: PropTypes.string.isRequired,
    survey_tool: PropTypes.string,
    survey_type: PropTypes.string,
    total_rows: PropTypes.number,
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      updated_question_name: PropTypes.string.isRequired,
    }),
  ),
});

export const queryType = PropTypes.shape({
  response: PropTypes.shape({
    result: PropTypes.string,
    message: PropTypes.string,
    paging: PropTypes.shape({
      total_count: PropTypes.number,
      current_page: PropTypes.number,
      next_page: PropTypes.number,
      previous_page: PropTypes.number,
    }),
  }),
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
});

export const suggestedThemeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  group_id: PropTypes.number.isRequired,
  response_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
});

export const suggestedGroupType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const summaryType = PropTypes.shape({
  summary: PropTypes.string,
  theme_overview: PropTypes.string,
});

export const segmentSummaryType = PropTypes.shape({
  main_insight: PropTypes.string,
  initial_explanation: PropTypes.string,
  option_summaries: PropTypes.arrayOf(
    PropTypes.shape({ segment_name: PropTypes.string, analysis: PropTypes.string }),
  ),
});

export const segmentType = PropTypes.shape({
  id: PropTypes.number,
  active: PropTypes.bool,
  segment: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  segment_type: PropTypes.string,
  label: PropTypes.string,
});

export const responseType = PropTypes.shape({
  text: PropTypes.string,
  theme_ids: PropTypes.arrayOf(PropTypes.number),
});

export const searchFilterType = PropTypes.shape({
  themes: PropTypes.string,
  responses: PropTypes.string,
  keywords: PropTypes.string,
});

export const selectedThemesType = PropTypes.shape({
  type: PropTypes.string,
  ids: PropTypes.arrayOf(PropTypes.number),
});

export const displayKeyType = PropTypes.shape({
  type: PropTypes.string,
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});
