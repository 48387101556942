import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { surveyType } from '../../types';

const DetailItem = styled.div`
  margin-bottom: 20px;
`;

const DetailItemTitle = styled.div`
  font-size: 16px;
`;

const DetailItemDesc = styled.div`
  font-size: 14px;
  color: #00000073;
`;

function SurveyDetailsModal({ survey = null, closeModal }) {
  return (
    <Modal title="Details" open={!!survey} footer={null} onCancel={closeModal}>
      <DetailItem>
        <DetailItemTitle>Survey tool</DetailItemTitle>
        <DetailItemDesc>{survey?.survey_tool}</DetailItemDesc>
      </DetailItem>
      <DetailItem>
        <DetailItemTitle>Survey type</DetailItemTitle>
        <DetailItemDesc>{survey?.survey_type}</DetailItemDesc>
      </DetailItem>
      <DetailItem>
        <DetailItemTitle>Survey title</DetailItemTitle>
        <DetailItemDesc>{survey?.name}</DetailItemDesc>
      </DetailItem>
      <DetailItem>
        <DetailItemTitle>Survey goals</DetailItemTitle>
        <DetailItemDesc>{survey?.survey_goals}</DetailItemDesc>
      </DetailItem>
    </Modal>
  );
}

SurveyDetailsModal.propTypes = {
  survey: surveyType,
  closeModal: PropTypes.func.isRequired,
};

export default SurveyDetailsModal;
