import { Divider, Empty, Flex } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeEnginePanel } from '../../../constants';
import { BlueSelected, White } from '../../../styles';
import { themeGroupType } from '../../../types';
import BaseModal from './BaseModal';

const StyledDiv = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  background: ${(props) => (props.$selected ? BlueSelected : White)};
`;

const StyledDivider = styled(Divider)`
  margin: 4px 0;
`;

function MoveToModal({ panel, visible, setVisible, onMove, groups = [] }) {
  const [selectedGroupId, setSelectedGroupId] = useState(undefined);

  useEffect(() => {
    if (groups.length) {
      setSelectedGroupId(groups[0].id);
    }
  }, [groups]);

  return (
    <BaseModal
      title="Add to..."
      open={visible}
      destroyOnClose
      closable={false}
      okText="Move"
      onCancel={() => setVisible(false)}
      onOk={() => {
        onMove(selectedGroupId);
        setVisible(false);
      }}
      okDisabled={groups.length === 0}
    >
      {groups.length > 0 ? (
        <Flex vertical>
          {panel === ThemeEnginePanel.MY_CODE_FRAME && (
            <>
              <StyledDiv
                key={0}
                onClick={() => setSelectedGroupId(null)}
                $selected={selectedGroupId === null}
              >
                No group
              </StyledDiv>
              <StyledDivider />
            </>
          )}
          {groups.map((g) => (
            <StyledDiv
              key={g.id}
              onClick={() => setSelectedGroupId(g.id)}
              $selected={g.id === selectedGroupId}
            >
              {g.name}
            </StyledDiv>
          ))}
        </Flex>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No groups found. Create a group first to move themes."
        />
      )}
    </BaseModal>
  );
}

MoveToModal.propTypes = {
  panel: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(themeGroupType),
};

export default MoveToModal;
