import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys } from '../constants';
import useFetch from './useFetch';

export function useThemes({ themeIds }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.THEMES, { themeIds }],
    queryFn: () =>
      fetcher({
        path: `v1/themes/${themeIds}/`,
      }),
    ...options,
  });
}

export const useCreateUpdateThemes = ({ codeFrameId }, options = {}) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({ path: 'v1/themes/update/', method: 'PUT', data: JSON.stringify(data) }),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.SUGGESTED_THEMES, { codeFrameId }]);
      queryClient.invalidateQueries([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);
      queryClient.invalidateQueries([QueryKeys.THEME_COVERAGE, { codeFrameId }]);
    },
    onError: () => {
      message.error('Something went wrong creating or updating themes');
    },
    ...options,
  });
};

export const useDeleteThemes = ({ codeFrameId }) => {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ themeIds }) =>
      fetcher({ path: `v1/themes/${themeIds.join()}/delete/`, method: 'DELETE' }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }],
      });
      const prevResponses = queryClient.getQueriesData({
        queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }],
      });
      const prevThemes = queryClient.getQueryData([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);

      prevResponses.forEach(([queryKey]) => {
        queryClient.setQueryData(queryKey, (prev) => {
          if (prev) {
            const { filters } = queryKey[1];
            if (filters.with_theme_ids) {
              return {
                ...prev,
                data: prev.data.map((r) => ({
                  ...r,
                  theme_ids: r.theme_ids.filter((id) => !variables.themeIds.includes(id)),
                })),
              };
            }
            return prev;
          }
          return undefined;
        });
      });

      queryClient.setQueryData([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }], (prev) => {
        return {
          ...prev,
          data: prev.data.filter((t) => !variables.themeIds.includes(t.id)),
        };
      });
      return { prevThemes, prevResponses };
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.SUGGESTED_THEMES, { codeFrameId }]);
      queryClient.invalidateQueries([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);
      queryClient.invalidateQueries([QueryKeys.THEME_COVERAGE, { codeFrameId }]);
      queryClient.invalidateQueries([QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }]);
    },
    onError: (_error, _variables, context) => {
      message.error('Something went wrong deleting themes');
      context.prevResponses.forEach(([queryKey]) => queryClient.invalidateQueries(queryKey));
      queryClient.setQueryData([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }], context.prevThemes);
    },
  });
};
