import React from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../components/Loading';
import useSuperuser from '../hooks/useSuperuser';
import NotFound from '../pages/NotFound';

function SuperuserRoute() {
  const { isSuperuser, isLoading, organizationId } = useSuperuser();

  if (isLoading || !organizationId) {
    return <Loading />;
  }

  return isSuperuser ? <Outlet /> : <NotFound />;
}

export default SuperuserRoute;
