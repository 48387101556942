// ANT DESIGN V5 COLORS
// https://ant.design/docs/spec/colors
export const White = '#FFFFFF';
export const Black = '#000000';

export const Green3 = '#B7EB8F';
export const GreenPrimary = '#52C41A';

export const Red3 = '#FFA39E';
export const RedPrimary = '#F5222D';

export const Cyan3 = '#87E8DE';

export const Blue1 = '#E6F4FF';
export const Blue2 = '#BAE0FF';
export const Blue3 = '#91CAFF';
export const Blue4 = '#69B1FF';
export const Blue5 = '#4096FF';
export const Blue10 = '#001D66';
export const BluePrimary = '#1677FF';
export const BlueSelected = '#1890ff33';

export const Gray2 = '#FAFAFA';
export const Gray3 = '#F5F5F5';
export const Gray4 = '#F0F0F0';
export const Gray5 = '#D9D9D9';
export const Gray6 = '#BFBFBF';
export const Gray7 = '#8C8C8C';

// Other colors
export const TealProgress = '#14BEAA';
export const TealChartDark = '#01889F';

export const ThemeSizeSmall = { background: '#14BEAA12', color: '#48B8AA' };
export const ThemeSizeMedium = { background: '#14BEAA40', color: '#0DA392' };
export const ThemeSizeLarge = { background: '#02B9A380', color: '#008071' };

export const themeCardColors = [
  'linear-gradient(#FFE6E0, #FBF2EF)',
  'linear-gradient(#FFEFC8B3, #FFFDF8B3)',
  'linear-gradient(#D6D5FFB3, #F0EFFAB3)',
  'linear-gradient(#CAEFF2B3, #E8F6F7B3)',
];

export const themeCardBorderColors = ['#F8CFBE', '#F9E0A5', '#D1D4FF', '#B3E3E3'];

export const suggestedThemeCardColor = 'linear-gradient(#EFEFEF, #F8F8F8)';

export const suggestedThemeCardBorderColor = '#E2E2E2';

export const themeTagColors = ['#FFE7E2', '#FDF2D7', '#F1D3FF', '#CCF1F4'];
