import { DownOutlined, MoreOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Dropdown, Modal, Space, message } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useDeleteUser, useUpdateRole } from '../../api/Onboarding';
import SearchableTable from '../../components/SearchableTable';
import { MatomoEvent, QueryKeys, UserRoleType } from '../../constants';
import useMatomo from '../../hooks/useMatomo';
import { queryType } from '../../types';

const StyledButton = styled(Button)`
  padding-left: 0;
`;

function UsersTab({ users, organizationId }) {
  const { user } = useAuth0();
  const { trackEvent } = useMatomo();
  const queryClient = useQueryClient();
  const { mutate: updateRole, isLoading: updateRoleLoading } = useMutation(useUpdateRole());
  const { mutate: deleteUser, isLoading: deleteUserLoading } = useMutation(useDeleteUser());

  const handleUpdateRole = ({ userId, role }) => {
    trackEvent(MatomoEvent.UPDATE_USER_ROLE);

    if (users.data.find((u) => u.id === userId).role !== role) {
      updateRole(
        { userId, data: { role } },
        {
          onSuccess: () => {
            const updatedUsers = users.data.map((u) => (u.id === userId ? { ...u, role } : u));
            queryClient.setQueryData([QueryKeys.USERS, { organizationId }], {
              ...users,
              data: updatedUsers,
            });
            message.success('User type updated');
          },
          onError: () => {
            message.error('Something went wrong updating user type');
          },
        },
      );
    }
  };

  const handleDeleteUser = (userId) => {
    deleteUser(
      { userId },
      {
        onSuccess: () => {
          const remainingUsers = users.data.filter((u) => u.id !== userId);
          queryClient.setQueryData([QueryKeys.USERS, { organizationId }], {
            ...users,
            data: remainingUsers,
          });
          message.success('User successfully deleted');
        },
        onError: () => {
          message.error('Something went wrong deleting user');
        },
      },
    );
  };

  const showConfirmDeleteModal = (userId) => {
    Modal.confirm({
      title: 'Are you sure?',
      autoFocusButton: 'ok',
      content:
        "This can't be undone. If you want this user back, you'll need to invite them again.",
      okText: 'Delete',
      onOk: () => handleDeleteUser(userId),
      okButtonProps: { danger: true, type: 'primary' },
    });
  };

  const userItems = [
    {
      key: UserRoleType.VIEWER,
      label: UserRoleType.VIEWER,
    },
    {
      key: UserRoleType.EDITOR,
      label: UserRoleType.EDITOR,
    },
    {
      key: UserRoleType.ADMINISTRATOR,
      label: UserRoleType.ADMINISTRATOR,
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: (
        <>
          User Type
          <Button
            type="link"
            icon={<QuestionCircleOutlined />}
            onClick={() => window.Intercom('show')}
          />
        </>
      ),
      dataIndex: 'role',
      render: (_, record) => (
        <Dropdown
          menu={{
            items: userItems,
            onClick: (item) => {
              handleUpdateRole({ userId: record.id, role: item.key });
            },
          }}
          disabled={record.email === user.email}
        >
          <StyledButton type="link">
            <Space>
              {record.role}
              <DownOutlined />
            </Space>
          </StyledButton>
        </Dropdown>
      ),
    },
    {
      title: 'Last active',
      dataIndex: 'last_active',
      render: (date) => (date ? dayjs(date).format('MMM D, YYYY h:mm A') : '--'),
      sorter: (a, b) => new Date(a.last_active) - new Date(b.last_active),
    },
    {
      key: 'action',
      width: 50,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: record.id,
                label: 'Delete user',
              },
            ],
            onClick: () => showConfirmDeleteModal(record.id),
          }}
          disabled={record.email === user.email}
        >
          <Button type="text">
            <MoreOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <SearchableTable
      columns={columns}
      baseData={users.data}
      rowKey="id"
      searchPlaceholder="Search users..."
      loading={updateRoleLoading || deleteUserLoading}
      searchColumns={['name']}
    />
  );
}

UsersTab.propTypes = {
  users: queryType.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default UsersTab;
