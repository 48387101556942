import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Input, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useCreateSavedSegment } from '../../../../api/Surveys';
import { QueryKeys } from '../../../../constants';

function SaveSegmentModal({ surveyId, segmentToSave = null, setVisible, saveSegment }) {
  const [form] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState(false);

  const queryClient = useQueryClient();
  const inputRef = useCallback(
    (inputElement) => {
      if (inputElement && segmentToSave) {
        setTimeout(() => inputElement.focus(), 0);
      }
    },
    [segmentToSave],
  );

  const { mutate: createSavedSegment } = useMutation({
    mutationFn: useCreateSavedSegment(),
    onSettled: async ({ data }, error) => {
      if (error) {
        message.error('Something went wrong creating saved segment');
      } else {
        await queryClient.setQueryData([QueryKeys.SAVED_SEGMENTS, { surveyId }], (prev) => ({
          ...prev,
          data: [...prev.data, data],
        }));
      }
    },
  });

  const onSaveSegment = async (values) => {
    setIsModalLoading(true);
    createSavedSegment({ surveyId, data: { label: values.label, segment: segmentToSave } });
    await saveSegment(segmentToSave, values.label);
    setVisible();
    setIsModalLoading(false);
  };

  return (
    <Modal
      title="Save"
      open={segmentToSave !== null}
      onCancel={setVisible}
      destroyOnClose
      maskClosable={false}
      confirmLoading={isModalLoading}
      okText="Save"
      onOk={() => {
        form
          .validateFields()
          .then(onSaveSegment)
          .catch(() => {});
      }}
    >
      <Form form={form} autoComplete="off" preserve={false}>
        <Form.Item
          label="Label"
          name="label"
          initialValue={segmentToSave && Object.values(segmentToSave).flat().join(', ')}
          rules={[{ required: true, message: 'label is required' }]}
        >
          <Input ref={inputRef} type="text" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

SaveSegmentModal.propTypes = {
  surveyId: PropTypes.number.isRequired,
  segmentToSave: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  setVisible: PropTypes.func.isRequired,
  saveSegment: PropTypes.func.isRequired,
};

export default SaveSegmentModal;
