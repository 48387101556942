import { ChartValueType } from '../constants';
import {
  Cyan3,
  Gray5,
  Gray6,
  Gray7,
  Green3,
  GreenPrimary,
  Red3,
  RedPrimary,
  TealChartDark,
} from '../styles';

export const getThemesBarChart = ({
  themes,
  questionId,
  surveyId,
  filters = undefined,
  chartValueType,
  highlightThemeIds = [],
  clickable = true,
}) => {
  const chartData = [];
  themes.forEach((t) => {
    const { name } = t.data;
    chartData.push({
      label: name,
      labelFontColor: 'rgba(0, 0, 0, 0.85)',
      fontSize: '12px',
      value:
        chartValueType === ChartValueType.PERCENTAGE ? t.data.percentage : t.data.response_count,
      color:
        highlightThemeIds.length && !highlightThemeIds.includes(t.data.id) ? Gray7 : TealChartDark,
      tooltext: `${t.data.percentage}% (${t.data.response_count}) of responses contain the theme ${name}`,
      ...(clickable && {
        link: `${window.location.origin}/surveys/${surveyId}/questions/${questionId}/themes/${
          t.data.id
        }/responses${filters ? `?${filters}` : ''}`,
      }),
    });
    t.children.forEach((c) => {
      const childName = c.data.name;
      chartData.push({
        label: childName,
        value:
          chartValueType === ChartValueType.PERCENTAGE ? c.data.percentage : c.data.response_count,
        color: highlightThemeIds.length && !highlightThemeIds.includes(c.data.id) ? Gray5 : Cyan3,
        tooltext: `${c.data.percentage}% (${c.data.response_count}) of responses contain the theme ${childName}`,
        ...(clickable && {
          link: `${window.location.origin}/surveys/${surveyId}/questions/${questionId}/themes/${
            c.data.id
          }/responses${filters ? `?${filters}` : ''}`,
        }),
      });
    });
  });

  const chart = {
    type: 'bar2d',
    width: '100%',
    height: `${Math.max(20 * chartData.length, 500)}`,
    dataFormat: 'json',
    dataSource: {
      chart: {
        theme: 'mySampleTheme',
        exportEnabled: false,
        minLabelWidthPercent: 25,
        maxLabelWidthPercent: 25,
        numberSuffix: chartValueType === ChartValueType.PERCENTAGE ? '%' : '',
        showZeroPlaneValue: 1,
        showLimits: 1,
        axisLineAlpha: 25,
        showAxisLines: 1,
        chartTopMargin: 8,
        chartBottomMargin: 8,
        captionFontSize: 0,
        canvasPadding: 0,
      },
      data: chartData,
    },
    chartLeftMargin: '0',
  };
  return chart;
};

export const getChartForClosedEndedQuestions = (aggregatedResponses, chartTitle) => {
  const chartData = [];
  aggregatedResponses.forEach((response) => {
    const { percentage, respondent_count: count, response_text: text } = response;
    chartData.push({
      label: text,
      value: percentage,
      tooltext: `${count} ${
        count === 1 ? 'person' : 'people'
      } (${percentage}% of all respondents to this question) chose ${text}`,
    });
  });

  const chart = {
    type: 'column2d',
    renderAt: 'chart-container',
    width: '65%',
    height: '400',
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: chartTitle,
        theme: 'mySampleTheme',
        exportFileName: chartTitle,
      },
      data: chartData,
    },
  };
  return chart;
};

export const getSentimentsBarChart = (themes) => {
  const categories = themes.flatMap((t) => {
    const childLabels = t.children.map((c) => ({
      label: c.data.name,
    }));
    return [
      {
        label: t.data.name,
        labelFontColor: 'rgba(0, 0, 0, 0.85)',
      },
    ].concat(childLabels);
  });

  const formatSentimentData = (sentiment, color) =>
    themes.flatMap((t) => {
      const childValues = t.children.map((c) => ({
        value: c.data[sentiment],
        showValue: c.data[sentiment],
        color,
      }));
      return [{ value: t.data[sentiment], showValue: t.data[sentiment] }].concat(childValues);
    });

  const positiveValues = formatSentimentData('positive_count', Green3);
  const negativeValues = formatSentimentData('negative_count', Red3);
  const neutralValues = formatSentimentData('neutral_count', Gray5);

  const sentimentValues = [
    {
      seriesname: 'Positive',
      color: GreenPrimary,
      data: positiveValues,
    },
    {
      seriesname: 'Negative',
      color: RedPrimary,
      data: negativeValues,
    },
    {
      seriesname: 'Neutral',
      color: Gray6,
      data: neutralValues,
    },
  ];

  const chart = {
    type: 'stackedbar2d',
    renderAt: 'chart-container',
    width: '90%',
    // 160 is the height of the chart title and legend
    height: 160 + Math.max(18 * categories.length, 500),
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption: 'Sentiment Distribution',
        subCaption: 'Muted colors represent sub-themes',
        valueFontColor: '#ffffff',
        theme: 'mySampleTheme',
        decimals: 0,
        minLabelWidthPercent: 30,
        maxLabelWidthPercent: 30,
      },
      categories: [{ category: categories }],
      dataset: sentimentValues,
    },
  };
  return chart;
};

export const getThemeSentimentBarChart = ({ positiveCount, negativeCount, neutralCount }) => {
  const totalCount = positiveCount + negativeCount + neutralCount;

  const positivePercentage = totalCount ? Math.round((positiveCount * 100) / totalCount) : 0;
  const negativePercentage = totalCount ? Math.round((negativeCount * 100) / totalCount) : 0;
  const neutralPercentage = totalCount ? Math.round((neutralCount * 100) / totalCount) : 0;

  const sentimentValues = [
    {
      seriesname: `${positivePercentage}% positive`,
      color: GreenPrimary,
      data: [
        { value: positiveCount, toolText: `<b>${positiveCount}</b> responses are <b>Positive</b>` },
      ],
    },
    {
      seriesname: `${negativePercentage}% negative`,
      color: RedPrimary,
      data: [
        { value: negativeCount, toolText: `<b>${negativeCount}</b> responses are <b>Negative</b>` },
      ],
    },
    {
      seriesname: `${neutralPercentage}% neutral`,
      color: Gray6,
      data: [
        { value: neutralCount, toolText: `<b>${neutralCount}</b> responses are <b>Neutral</b>` },
      ],
    },
  ];

  const chart = {
    type: 'stackedbar2d',
    renderAt: 'chart-container',
    width: '100%',
    height: 75,
    dataFormat: 'json',
    dataSource: {
      chart: {
        showValues: '0',
        theme: 'mySampleTheme',
        showHoverEffect: '0',
        plotHoverEffect: '0',
        canvasPadding: '0',
        chartBottomMargin: '0',
        chartTopMargin: '0',
        chartLeftMargin: '0',
        chartRightMargin: '0',
        maxBarHeight: '24',
        interactiveLegend: '0',
      },
      categories: [
        {
          category: [
            {
              label: '',
            },
          ],
        },
      ],
      dataset: sentimentValues,
    },
  };
  return chart;
};

export const getSentimentsDonutChart = ({ caption, sentiments }) => {
  const chart = {
    type: 'doughnut2d',
    renderAt: 'chart-container',
    width: '90%',
    height: 350,
    dataFormat: 'json',
    dataSource: {
      chart: {
        caption,
        decimals: 0,
        theme: 'mySampleTheme',
        captionPadding: 0,
        plotToolText: '<b>$value</b> responses are <b>$label</b>',
        pieRadius: 90,
        doughnutRadius: 60,
      },
      data: [
        {
          label: 'Positive',
          value: sentiments.positive_count,
          color: GreenPrimary,
        },
        {
          label: 'Negative',
          value: sentiments.negative_count,
          color: RedPrimary,
        },
        {
          label: 'Neutral',
          value: sentiments.neutral_count,
          color: Gray6,
        },
      ],
    },
  };
  return chart;
};
