import { Flex, Form, Input, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { themeGroupType, themeType } from '../../../types';
import BaseModal from './BaseModal';

const { Text } = Typography;

const StyledForm = styled(Form)`
  padding: 0 12px;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

function CreateEditGroupModal({ group = null, visible, setVisible, onOk, themes = undefined }) {
  const [form] = Form.useForm();

  const options = themes
    ?.filter((t) => !t.is_parent)
    .reduce(
      (acc, { name, id, parent_theme_id: groupId }) => {
        if (!groupId) {
          acc[0].options.push({ label: name, value: id });
        } else {
          const label = themes.find((t) => t.id === groupId)?.name;
          let section = acc.find((s) => s.label === label);
          if (!section) {
            section = { label, options: [] };
            acc.push(section);
          }
          section.options.push({ label: name, value: id });
        }
        return acc;
      },
      [{ label: 'Ungrouped', options: [] }],
    )
    .filter((section) => section.options.length);

  return (
    <BaseModal
      title={`${group ? 'Edit' : 'New'} group`}
      open={visible}
      destroyOnClose
      closable={false}
      okText={group ? 'Edit' : 'Create'}
      onCancel={() => setVisible(false)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
            setVisible(false);
          })
          .catch(() => {});
      }}
    >
      <StyledForm
        form={form}
        layout="vertical"
        autoComplete="off"
        preserve={false}
        requiredMark={false}
      >
        <Flex vertical gap={16}>
          <Form.Item
            label={<Text type="secondary">Group name</Text>}
            name="name"
            initialValue={group?.name}
            rules={[{ required: true, message: 'name is required' }]}
          >
            <Input type="text" />
          </Form.Item>
          {themes && (
            <Form.Item
              label={<Text type="secondary">Themes</Text>}
              name="themeIds"
              rules={[{ required: true, message: 'themes are required' }]}
            >
              <Select
                mode="multiple"
                allowClear
                options={options}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
        </Flex>
      </StyledForm>
    </BaseModal>
  );
}

CreateEditGroupModal.propTypes = {
  group: themeGroupType,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  themes: PropTypes.arrayOf(themeType),
};

export default CreateEditGroupModal;
