import React from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../components/Loading';
import { UserRoleType } from '../constants';
import useParams from '../hooks/useParams';
import usePermissionedSurveyIds from '../hooks/usePermissionedSurveyIds';
import useRoles from '../hooks/useRoles';
import NotFound from '../pages/NotFound';

// allows access to users who are able to access all surveys
// or who are able to access the current survey
function PermissionedSurveyRoute() {
  const { surveyId } = useParams();
  const { roles, isLoading: rolesLoading } = useRoles();
  const { surveyIds, isLoading: surveyIdsLoading } = usePermissionedSurveyIds();
  const readAllSurveyRoles = [UserRoleType.ADMINISTRATOR, UserRoleType.EDITOR, UserRoleType.VIEWER];

  if (rolesLoading || surveyIdsLoading || roles.length === 0) {
    return <Loading />;
  }

  if (roles.some((value) => readAllSurveyRoles.includes(value))) {
    return <Outlet />;
  }

  return surveyIds.includes(surveyId) ? <Outlet /> : <NotFound />;
}

export default PermissionedSurveyRoute;
