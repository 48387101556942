import PropTypes from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Loading from '../components/Loading';
import useRoles from '../hooks/useRoles';
import NotFound from '../pages/NotFound';

// allows access to users who have any of the specified roles
function PermissionedRoute({ roles }) {
  const { roles: userRoles, isLoading } = useRoles();

  if (isLoading || userRoles.length === 0) {
    return <Loading />;
  }

  return roles.some((value) => userRoles.includes(value)) ? <Outlet /> : <NotFound />;
}

export default PermissionedRoute;

PermissionedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};
