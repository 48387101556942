import { Flex } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BlueSelected, White } from '../../../styles';
import BaseModal from './BaseModal';

const StyledDiv = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  background: ${(props) => (props.$selected ? BlueSelected : White)};
`;

function EditSentimentModal({ visible, closeModal, onEdit }) {
  const [selectedSentiment, setSelectedSentiment] = useState('Positive');

  return (
    <BaseModal
      title="Edit sentiment"
      open={visible}
      destroyOnClose
      closable={false}
      okText="Edit"
      onCancel={closeModal}
      onOk={() => {
        onEdit(selectedSentiment);
        closeModal();
      }}
    >
      <Flex vertical>
        {['Positive', 'Negative', 'Neutral'].map((sentiment) => (
          <StyledDiv
            key={sentiment}
            onClick={() => setSelectedSentiment(sentiment)}
            $selected={sentiment === selectedSentiment}
          >
            {sentiment}
          </StyledDiv>
        ))}
      </Flex>
    </BaseModal>
  );
}

EditSentimentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default EditSentimentModal;
