import { Button, Flex, Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Gray3, White } from '../../../styles';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding: 15px 24px 14px;
    margin: 0;
  }

  .ant-modal-title {
    text-align: center;
    font-size: 18px;
  }

  .ant-modal-body {
    padding: 16px 12px 28px;
    background-image: linear-gradient(180deg, ${Gray3} 0px, ${White} 10px),
      linear-gradient(0deg, ${Gray3} 0px, ${White} 10px);
    background-repeat: no-repeat;
    background-size:
      100% 28px,
      100% 28px;
    background-position: top, bottom;
  }

  .ant-modal-footer {
    padding: 12px;
    margin: 0;
  }
`;

function BaseModal({ children, okText, onCancel, onOk, okDisabled = false, ...props }) {
  return (
    <StyledModal
      footer={[
        <Flex justify="space-between" key="footer">
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={onOk} disabled={okDisabled}>
            {okText}
          </Button>
        </Flex>,
      ]}
      // we want to be able to use ant design Modal props
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </StyledModal>
  );
}

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  okText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  okDisabled: PropTypes.bool,
};

export default BaseModal;
