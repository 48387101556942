import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Progress, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCodeFrameResponses } from '../../api/CodeFrames';
import { useQuestion, useUpdateQuestionPhase } from '../../api/Questions';
import Loading from '../../components/Loading';
import { QCPhase } from '../../constants';
import useParams from '../../hooks/useParams';
import { BluePrimary, Gray4, Gray6, Gray7, GreenPrimary, White } from '../../styles';

const { Text, Title } = Typography;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const StyledHeaderFlex = styled(Flex)`
  padding: 16px 24px;
`;

const StyledButton = styled(Button)`
  background: ${Gray7};
  border: 0;
  box-shadow: unset;

  &&.ant-btn:hover {
    background: ${Gray6};
    border: 0;
  }
`;

const StyledProgress = styled(Progress)`
  align-self: end;
  width: 40%;
  max-width: 300px;
`;

const ProgressText = styled(Text)`
  font-size: 12px;
  color: ${BluePrimary};
`;

const ProgressTextComplete = styled(Text)`
  font-size: 12px;
  color: ${GreenPrimary};
`;

const StyledContentFlex = styled(Flex)`
  overflow: auto;
  flex: 1;
  background: ${(props) =>
    props.$isAtTop ? White : `linear-gradient(180deg, ${Gray4} 0px, ${White} 44px)`};
`;

const StyledInnerFlex = styled(Flex)`
  justify-self: center;
  width: 55%;
  max-width: 620px;
`;

const StyledDiv = styled.div`
  margin-top: 44px;
  margin-bottom: 28px;
`;

const StyledText = styled(Text)`
  font-size: 18px;
`;

const ContinueButton = styled(Button)`
  margin-bottom: 50px;
`;

const StyledCard = styled(Card)`
  filter: drop-shadow(0px 11px 5px rgba(0, 0, 0, 0.01)) drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
`;

function ThemeEngineIntro() {
  const { surveyId, questionId, codeFrameId } = useParams();
  const navigate = useNavigate();

  const containerRef = useRef();
  const [isAtTop, setAtTop] = useState(true);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const { data: question, isLoading: questionLoading } = useQuestion({ surveyId, questionId });

  const { data: responses, isFetching: responsesFetching } = useCodeFrameResponses({
    codeFrameId,
    filters: { order: 'random', page_number: 1, page_size: 100 },
  });

  const { mutate: updateQuestionPhase, isLoading: updateQuestionPhaseLoading } =
    useUpdateQuestionPhase({ questionId, surveyId });

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = container;
    setAtTop(scrollTop === 0);
    setScrollPercentage(((clientHeight + scrollTop) / scrollHeight) * 100);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return undefined;
    }

    if (!questionLoading && !responsesFetching) {
      handleScroll();
    }

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [questionLoading, responsesFetching]);

  const continueToThemeEngine = () => {
    updateQuestionPhase({ data: { qc_phase: QCPhase.SELECT_THEMES } });
  };

  const progressText = (percentage) => {
    if (percentage === 100) {
      return <ProgressTextComplete>Done!</ProgressTextComplete>;
    }
    if (percentage > 75) {
      return <ProgressTextComplete>Almost there...</ProgressTextComplete>;
    }
    return <ProgressText>{`${percentage}% complete`}</ProgressText>;
  };

  return (
    <StyledFlex vertical>
      <StyledHeaderFlex justify="space-between" align="center" $isAtTop={isAtTop}>
        <StyledButton
          size="small"
          type="primary"
          icon={<LeftOutlined />}
          onClick={() => navigate(`/surveys/${surveyId}/hub`)}
        />
        <StyledProgress
          percent={Math.round(scrollPercentage)}
          format={progressText}
          percentPosition={{ align: 'center', type: 'outer' }}
          size="small"
          strokeColor={{ from: BluePrimary, to: GreenPrimary }}
        />
        <Button type="text" onClick={continueToThemeEngine} loading={updateQuestionPhaseLoading}>
          <Text type="secondary">{scrollPercentage === 100 ? 'Continue' : 'Skip'}</Text>
        </Button>
      </StyledHeaderFlex>
      {questionLoading || responsesFetching ? (
        <Loading />
      ) : (
        <StyledContentFlex justify="center" $isAtTop={isAtTop} ref={containerRef}>
          <StyledInnerFlex vertical gap={12}>
            <StyledDiv>
              <Title level={4}>&quot;{question?.data.text}&quot;</Title>
              <StyledText type="secondary">
                To get familiar with the data, here&apos;s a random sample to review
              </StyledText>
            </StyledDiv>
            {responses?.data.map((r) => (
              <StyledCard key={r.id} size="small">
                {r.text}
              </StyledCard>
            ))}
            <Flex vertical align="center">
              <Title level={4}>Well Done!</Title>
              <ContinueButton
                type="primary"
                onClick={continueToThemeEngine}
                loading={updateQuestionPhaseLoading}
              >
                Continue
              </ContinueButton>
            </Flex>
          </StyledInnerFlex>
        </StyledContentFlex>
      )}
    </StyledFlex>
  );
}

export default ThemeEngineIntro;
