import Icon, { EditOutlined } from '@ant-design/icons';
import { Button, Collapse, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SUMMARY_COLLAPSE_KEY } from '../../../constants';
import useParams from '../../../hooks/useParams';
import { questionType, summaryType } from '../../../types';
import EditThemeSummaryModal from './EditThemeSummaryModal';

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 12px;
  white-space: pre-line;
`;

const StyledCollapse = styled(Collapse)`
  background: #ffffff;
  border: 1px solid #f0f0f0;

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-item {
    border: none;
  }
`;

function ThemeSummary({ themeId, question, summary }) {
  const { surveyId } = useParams();

  const [summaryCollapseKey, setSummaryCollapseKey] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const collapseSummary = [
    {
      key: SUMMARY_COLLAPSE_KEY,
      label: (
        <Space>
          <Text strong>Theme summary</Text>
        </Space>
      ),
      children: <StyledText type="secondary">{summary.summary}</StyledText>,
      extra: (
        <Button
          icon={<Icon component={EditOutlined} />}
          type="link"
          size="small"
          onClick={() => setEditModalVisible(true)}
        >
          Edit summary
        </Button>
      ),
    },
  ];

  return (
    <div>
      <StyledCollapse
        collapsible="icon"
        activeKey={summaryCollapseKey}
        onChange={setSummaryCollapseKey}
        items={collapseSummary}
      />
      <EditThemeSummaryModal
        visible={isEditModalVisible}
        closeModal={() => setEditModalVisible(false)}
        expandSummaryCollapse={() => setSummaryCollapseKey(SUMMARY_COLLAPSE_KEY)}
        summary={summary.summary}
        codeFrameId={question.code_frame_id}
        themeId={themeId}
        surveyId={surveyId}
      />
    </div>
  );
}

ThemeSummary.propTypes = {
  themeId: PropTypes.number.isRequired,
  question: questionType.isRequired,
  summary: summaryType.isRequired,
};

export default ThemeSummary;
