import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Progress,
  Spin,
  Switch,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCodeFrameResponses } from '../../../api/CodeFrames';
import { useQuestion } from '../../../api/Questions';
import { PERCENTAGE_RANGES, QCPhase, TESelectionType } from '../../../constants';
import { BluePrimary, TealProgress, White, themeCardBorderColors } from '../../../styles';
import { displayKeyType, suggestedThemeType, themeType } from '../../../types';

const { Text } = Typography;
const { TextArea } = Input;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    max-height: 70vh;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-content {
    padding: 16px 24px 24px;
    background: linear-gradient(180deg, ${(props) => props.$color} 0px, ${White} 70px);
  }
`;

const StyledFlex = styled(Flex)`
  margin-bottom: -12px;
`;

const SentimentFlex = styled(Flex)`
  margin-bottom: 10px;
`;

const TitleText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }
`;

const ResponseDiv = styled.div`
  overflow: auto;
`;

const StyledAlert = styled(Alert)`
  background: ${BluePrimary};
  border: none;
  color: ${White};
`;

const StyledResponseCard = styled(Card)`
  filter: drop-shadow(0px 11px 5px rgba(0, 0, 0, 0.01)) drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));

  .ant-card-body {
    padding: 12px;
  }
`;

function ThemeDetailView({
  phase,
  theme = null,
  visible,
  closeModal,
  onOk,
  codeFrameId,
  questionId,
  surveyId,
  displayKey,
}) {
  const [form] = Form.useForm();
  const [hasTitleChanged, setTitleChanged] = useState(false);

  const { data: question, isLoading: questionLoading } = useQuestion({ questionId, surveyId });

  const { data: responses, isLoading: responsesLoading } = useCodeFrameResponses(
    {
      codeFrameId,
      filters: {
        page_number: 1,
        page_size: 100,
        ...(displayKey.type === TESelectionType.THEME && { theme_id: displayKey.key }),
        ...(displayKey.type === TESelectionType.SUGGESTED_THEME && {
          suggested_theme_id: displayKey.key,
        }),
        ...(displayKey.type === TESelectionType.SENTIMENT && {
          sentiment: displayKey.key.toLowerCase(),
        }),
      },
    },
    { enabled: visible },
  );

  useEffect(() => {
    setTitleChanged(false);
  }, [visible]);

  const responsePercentage =
    question?.data?.response_count > 0
      ? Math.round(
          ((responses?.response?.paging?.total_count ?? 0) / question.data.response_count) * 100,
        )
      : 0;

  return (
    <StyledModal
      open={visible}
      destroyOnClose
      closable={false}
      footer={null}
      width={780}
      $color={
        (displayKey.type === TESelectionType.THEME &&
          theme.parent_theme_id &&
          themeCardBorderColors[theme.parent_theme_id % themeCardBorderColors.length]) ||
        (displayKey.type === TESelectionType.SUGGESTED_THEME &&
          themeCardBorderColors[theme.group_id % themeCardBorderColors.length])
      }
    >
      <StyledForm
        form={form}
        layout="vertical"
        autoComplete="off"
        preserve={false}
        requiredMark={false}
        onValuesChange={(changedValues) => {
          if (changedValues.name && theme.name !== changedValues.name) {
            setTitleChanged(true);
          } else if (changedValues.name) {
            setTitleChanged(false);
          }
        }}
        onFinish={(values) => {
          onOk(values);
          closeModal();
        }}
      >
        {displayKey.type === TESelectionType.SENTIMENT ? (
          <SentimentFlex justify="space-between">
            <TitleText>{displayKey.key}</TitleText>
            <Button onClick={closeModal}>Cancel</Button>
          </SentimentFlex>
        ) : (
          <StyledFlex justify="end" gap={8}>
            <Button onClick={closeModal}>Cancel</Button>
            {phase !== QCPhase.COMPLETE && (
              <Button type="primary" htmlType="submit">
                {displayKey.type === TESelectionType.THEME ? 'Edit theme' : 'Add theme'}
              </Button>
            )}
          </StyledFlex>
        )}
        {displayKey.type !== TESelectionType.SENTIMENT && (
          <>
            <Form.Item
              label={<Text type="secondary">Theme name</Text>}
              name="name"
              initialValue={theme?.name}
              rules={[{ required: true, message: 'name is required' }]}
            >
              <Input type="text" disabled={phase === QCPhase.COMPLETE} />
            </Form.Item>
            <Form.Item
              label={<Text type="secondary">Description</Text>}
              name="description"
              initialValue={theme?.description}
              rules={[{ required: true, message: 'description is required' }]}
            >
              <TextArea
                rows={6}
                placeholder="Describe the theme in a sentence"
                disabled={phase === QCPhase.COMPLETE}
              />
            </Form.Item>
          </>
        )}
        {phase !== QCPhase.SELECT_THEMES && displayKey.type === TESelectionType.THEME && (
          <Form.Item>
            <Flex align="center" gap={8}>
              <Text type="secondary">Omitted</Text>
              <Form.Item name="omitted" initialValue={theme.is_omitted} noStyle>
                <Switch disabled={phase === QCPhase.COMPLETE} />
              </Form.Item>
            </Flex>
          </Form.Item>
        )}
      </StyledForm>
      <ResponseDiv>
        <Spin spinning={questionLoading || responsesLoading}>
          <Flex vertical gap={8}>
            {phase === QCPhase.SELECT_THEMES && hasTitleChanged && (
              <StyledAlert
                message={
                  <Flex gap={8} justify="center">
                    <InfoCircleOutlined />
                    You have changed the title. Responses may not be relevant.
                  </Flex>
                }
              />
            )}
            <Flex justify="space-between">
              <Text type="secondary">
                {phase === QCPhase.SELECT_THEMES
                  ? 'Example responses'
                  : `${responses?.response.paging.total_count || 0} items`}
              </Text>
              {phase === QCPhase.SELECT_THEMES ? (
                <Text type="secondary">
                  {responsePercentage === 0
                    ? '0% of raw data'
                    : `~${PERCENTAGE_RANGES.find(({ max }) => responsePercentage <= max).label}% of raw data`}
                </Text>
              ) : (
                <Flex gap={6}>
                  <Progress
                    type="circle"
                    percent={responsePercentage}
                    size={20}
                    strokeColor={TealProgress}
                  />
                  <Text type="secondary">{responsePercentage}% of raw data</Text>
                </Flex>
              )}
            </Flex>
            {responses?.data.map((response) => (
              <StyledResponseCard key={response.id} size="small">
                {response.text}
              </StyledResponseCard>
            ))}
          </Flex>
        </Spin>
      </ResponseDiv>
    </StyledModal>
  );
}

ThemeDetailView.propTypes = {
  phase: PropTypes.string.isRequired,
  theme: PropTypes.oneOfType([themeType, suggestedThemeType]),
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  codeFrameId: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  surveyId: PropTypes.number.isRequired,
  displayKey: displayKeyType.isRequired,
};

export default ThemeDetailView;
