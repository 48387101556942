import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { QueryKeys, SURVEY_ID_HEADER } from '../constants';
import useFetch from './useFetch';

export function useUpdateThemeSummary() {
  const fetcher = useFetch();
  return ({ codeFrameId, themeId, surveyId, data }) =>
    fetcher({
      path: `v1/codeframes/${codeFrameId}/themes/${themeId}/update-summary/`,
      method: 'PATCH',
      data: JSON.stringify(data),
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
}

export function useUpdateQuestionSummary() {
  const fetcher = useFetch();
  return ({ codeFrameId, data }) =>
    fetcher({
      path: `v1/codeframes/${codeFrameId}/update-summary/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

// CUSTOM REACT-QUERY HOOKS BELOW - WE SHOULD TRANSITION TO THESE

export function useThemeSummary({ codeFrameId, themeId, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.THEME_SUMMARY, { codeFrameId, themeId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/themes/${themeId}/summary/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useQuestionSummary({ codeFrameId, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.QUESTION_SUMMARY, { codeFrameId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/summary/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useSegmentSummary({ codeFrameId, segmentId, surveyId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SEGMENT_SUMMARY, { codeFrameId, segmentId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/segments/${segmentId}/summary/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useSegmentSummaryTheme(
  { codeFrameId, segmentId, themeId, surveyId },
  options = {},
) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SEGMENT_SUMMARY_THEME, { codeFrameId, segmentId, themeId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/segments/${segmentId}/themes/${themeId}/summary/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
    ...options,
  });
}

export function useCodeFrameResponses({ codeFrameId, filters }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId, filters }],
    queryFn: () =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/responses/`,
        filters,
      }),
    ...options,
  });
}

export function useSuggestedThemes({ codeFrameId }) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.SUGGESTED_THEMES, { codeFrameId }],
    queryFn: () =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/suggested-themes/`,
      }),
  });
}

export function useCodeFrameThemes({ codeFrameId }) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.CODE_FRAME_THEMES, { codeFrameId }],
    queryFn: () =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/themes/`,
      }),
    // if theme is a parent set response_ids to all unique child response ids
    select: (data) => ({
      ...data,
      data: data.data.map((theme) =>
        theme.is_parent
          ? {
              ...theme,
              response_ids: [
                ...new Set(
                  data.data
                    .filter((t) => t.parent_theme_id === theme.id)
                    .flatMap((t) => t.response_ids),
                ),
              ],
            }
          : theme,
      ),
    }),
  });
}

export function useGroupThemes({ codeFrameId }) {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/group-themes/`,
        method: 'PUT',
        data: JSON.stringify(data),
      }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);
      const prevThemes = queryClient.getQueryData([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);

      queryClient.setQueryData([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }], (prev) => {
        const { group_id: groupId, theme_ids: themeIds } = variables.data;
        const updatedThemes = prev.data.map((t) => ({
          ...t,
          parent_theme_id: themeIds.includes(t.id) ? groupId : t.parent_theme_id,
        }));

        const orphanedGroupIds = prev.data
          .filter((t) => themeIds.includes(t.id) && t.parent_theme_id)
          .map((t) => t.parent_theme_id)
          .filter((id) => !updatedThemes.some((t) => t.parent_theme_id === id));

        return {
          ...prev,
          data: updatedThemes.filter((t) => !orphanedGroupIds.includes(t.id)),
        };
      });
      return { prevThemes };
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]);
      queryClient.invalidateQueries([QueryKeys.THEME_COVERAGE, { codeFrameId }]);
    },
    onError: (_error, _variables, context) => {
      message.error('Something went wrong creating or updating theme');
      queryClient.setQueryData([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }], context.prevThemes);
    },
  });
}

export function useOmitTheme({ codeFrameId }) {
  const fetcher = useFetch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ themeId, data }) =>
      fetcher({
        path: `v1/codeframes/${codeFrameId}/themes/${themeId}/omit/`,
        method: 'PUT',
        data: JSON.stringify(data),
      }),
    onSettled: () => queryClient.invalidateQueries([QueryKeys.CODE_FRAME_THEMES, { codeFrameId }]),
    onError: () => {
      message.error('Something went wrong omitting theme');
    },
  });
}

export function useThemeCoverage({ codeFrameId }, options = {}) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: [QueryKeys.THEME_COVERAGE, { codeFrameId }],
    queryFn: () => fetcher({ path: `v1/codeframes/${codeFrameId}/theme-coverage/` }),
    ...options,
  });
}
