import { useQueryClient } from '@tanstack/react-query';
import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useCreateUpdateThemes } from '../../api/Themes';
import { QueryKeys, UserRoleType } from '../../constants';
import useRoles from '../../hooks/useRoles';
import { questionThemeType } from '../../types';
import ThemePicker from './ThemePicker';

const StyledModal = styled(Modal)`
  .ant-modal-title {
    margin-bottom: 18px;
  }
  .ant-modal-body {
    overflow-x: scroll;
    max-height: calc(100vh - 250px);
  }
`;

function ManageThemesModal({
  themes = [],
  saveThemes,
  hiddenThemes,
  isModalVisible,
  setIsModalVisible,
  codeFrameId,
  surveyId,
}) {
  const { roles } = useRoles();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [editedThemes, setEditedThemes] = useState({});

  // themes with temporary edited names to be displayed in the ThemePicker
  const themesWithEditedNames = useMemo(() => {
    const editedThemeIds = Object.keys(editedThemes).map((id) => parseInt(id, 10));
    return themes.map((t) => {
      const children = t.children.map((c) => {
        if (editedThemeIds.includes(c.data.id)) {
          return { ...c, data: { ...c.data, name: editedThemes[c.data.id] } };
        }
        return c;
      });
      if (editedThemeIds.includes(t.data.id)) {
        return {
          ...t,
          children,
          data: { ...t.data, name: editedThemes[t.data.id] },
        };
      }
      return { ...t, children };
    });
  }, [themes, editedThemes]);

  const { mutate: updateThemes, isLoading: updateThemesLoading } = useCreateUpdateThemes(
    { codeFrameId },
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [QueryKeys.CODE_FRAME, { surveyId }],
        });
        const prevCodeFrames = queryClient.getQueriesData({
          queryKey: [QueryKeys.CODE_FRAME_RESPONSES, { codeFrameId }],
        });
        queryClient.setQueriesData([QueryKeys.CODE_FRAME, { surveyId }], (prev) => {
          const updatedThemes = prev.data.themes_data.themes.map((t) => {
            const name = editedThemes[t.data.id] || t.data.name;
            const children = t.children.map((c) =>
              editedThemes[c.data.id]
                ? { ...c, data: { ...c.data, name: editedThemes[c.data.id] } }
                : c,
            );
            return { data: { ...t.data, name }, children };
          });
          return {
            ...prev,
            data: {
              ...prev.data,
              themes_data: { ...prev.data.themes_data, themes: updatedThemes },
            },
          };
        });
        return { prevCodeFrames };
      },
      onError: (_error, _variables, context) => {
        context.prevCodeFrames.forEach(([queryKey, data]) => {
          queryClient.setQueryData(queryKey, data);
        });
      },
    },
  );

  const onFinish = (formValues) => {
    if (Object.keys(editedThemes).length) {
      const data = Object.entries(editedThemes).map(([id, name]) => ({
        id,
        name,
        code_frame_id: codeFrameId,
      }));
      updateThemes({ data });
    }
    saveThemes(formValues.themes);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setEditedThemes({});
    setIsModalVisible(false);
  };

  return (
    <StyledModal
      title="Manage themes"
      open={isModalVisible}
      okText="Save"
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch(() => {});
      }}
      onCancel={handleCancel}
      confirmLoading={updateThemesLoading}
      destroyOnClose
      maskClosable={false}
    >
      <Form form={form} preserve={false}>
        <Form.Item name="themes" initialValue={hiddenThemes} valuePropName="deselectedThemes">
          <ThemePicker
            themes={themesWithEditedNames}
            switchTooltip="Show/hide theme"
            editable={
              roles.includes(UserRoleType.ADMINISTRATOR) || roles.includes(UserRoleType.EDITOR)
            }
            handleEdit={(theme, name) => {
              setEditedThemes({
                ...editedThemes,
                [theme.data.id]: name,
              });
            }}
            allowSubthemesOnly={false}
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
}

ManageThemesModal.propTypes = {
  themes: PropTypes.arrayOf(questionThemeType),
  saveThemes: PropTypes.func.isRequired,
  hiddenThemes: PropTypes.arrayOf(PropTypes.number).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  codeFrameId: PropTypes.number.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default ManageThemesModal;
