import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSurvey } from '../api/Surveys';
import Loading from '../components/Loading';
import { SurveyStatus } from '../constants';
import useParams from '../hooks/useParams';
import useSelfServeOrganization from '../hooks/useSelfServeOrganization';
import NotFound from '../pages/NotFound';

function ThemeEngineRoute() {
  const { surveyId } = useParams();
  const { selfServeEnabled, isLoading: selfServeLoading } = useSelfServeOrganization();
  const { data: survey, isLoading: surveyLoading } = useSurvey({ surveyId });

  if (selfServeLoading || surveyLoading) {
    return <Loading />;
  }

  return selfServeEnabled || survey.data.survey_status === SurveyStatus.PUBLISHED ? (
    <Outlet />
  ) : (
    <NotFound />
  );
}

export default ThemeEngineRoute;
