import {
  CloseOutlined,
  EditOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Modal,
  Pagination,
  Progress,
  Space,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import { useCodeFrameResponses } from '../../api/CodeFrames';
import { useTagResponses, useUpdateSentiment } from '../../api/Responses';
import {
  FilterSortType,
  PERCENTAGE_RANGES,
  QCPhase,
  ResponseFilterOptions,
  ResponseSortOptions,
  TESelectionType,
  ThemeEnginePanel,
} from '../../constants';
import useParams from '../../hooks/useParams';
import {
  Gray2,
  Gray5,
  Gray6,
  TealProgress,
  White,
  suggestedThemeCardBorderColor,
  themeCardBorderColors,
} from '../../styles';
import {
  displayKeyType,
  questionType,
  searchFilterType,
  suggestedThemeType,
  themeType,
} from '../../types';
import AddThemeModal from './components/AddThemeModal';
import CreateEditThemeModal from './components/CreateEditThemeModal';
import EditSentimentModal from './components/EditSentimentModal';
import FilterSortDropdown from './components/FilterSortDropdown';
import ResponseDetailView from './components/ResponseDetailView';
import ThemeCoverage from './components/ThemeCoverage';
import ThemeTagList from './components/ThemeTagList';

const { Text } = Typography;
const { confirm } = Modal;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${Gray2};
  height: 100%;
  width: 100%;
  background: ${(props) =>
    props.$color ? `linear-gradient(180deg, ${props.$color} 0px, ${White} 70px)` : Gray2};

  .ant-card-head {
    border-bottom: 0;
    padding: 10px 12px;
    font-weight: unset;
  }

  .ant-card-head-title {
    white-space: unset;
  }

  .ant-card-head-wrapper {
    align-items: unset;
    gap: 8px;
  }

  .ant-card-body {
    flex: 1;
    overflow: auto;
    padding: 4px 12px 12px;
  }

  .ant-card-actions {
    background: ${Gray2};

    > li {
      margin: 8px 0;
    }
  }
`;

const TitleText = styled(Text)`
  font-weight: 600;
  font-size: 18px;
`;

const StyledButton = styled(Button)`
  background: ${Gray6};
  border: 0;
  box-shadow: unset;
  color: ${White};

  &&.ant-btn:hover {
    background: ${Gray5};
    color: ${White};
  }
`;

const StyledFlex = styled(Flex)`
  padding: 0 4px;
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

const StyledResponseCard = styled(Card)`
  filter: drop-shadow(0px 11px 5px rgba(0, 0, 0, 0.01)) drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  ${(props) => props.$clickable && 'cursor: pointer'};

  .ant-card-body {
    padding: 8px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 6px;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const StyledCloseButton = styled(Button)`
  color: ${Gray5};
`;

const EditButton = styled(Text)`
  cursor: pointer;
`;

const StyledHighlighter = styled(Highlighter)`
  .highlighted {
    font-weight: 700;
  }
`;

const MAX_LENGTH = 1000;

const truncateText = (text) => {
  if (text.length <= MAX_LENGTH) {
    return text;
  }
  const truncated = text.slice(0, MAX_LENGTH);
  return `${truncated.slice(0, truncated.lastIndexOf(' '))}...`;
};

function ExploreRawData({
  phase,
  themes,
  suggestedThemes,
  fullScreen = null,
  setFullScreen,
  isExpanded,
  displayKey,
  setDisplayKey,
  question,
  selectedResponseIds,
  setSelectedResponseIds,
  searchFilters,
  advancedSearchEnabled,
  handleEditTheme,
  activeFilter,
  setActiveFilter,
}) {
  const { codeFrameId } = useParams();

  const [activeSort, setActiveSort] = useState(ResponseSortOptions.UNSORTED);
  const [queryFilter, setQueryFilter] = useState(undefined);
  const [querySort, setQuerySort] = useState(undefined);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [responseToTagId, setResponseToTagId] = useState(null);
  const [responseToViewId, setResponseToViewId] = useState(null);
  const [addThemeModalVisible, setAddThemeModalVisible] = useState(false);
  const [editSentimentModalVisible, setEditSentimentModalVisible] = useState(false);
  const [editThemeModalVisible, setEditThemeModalVisible] = useState(false);

  // If advanced search is enabled only include explicitly defined response search terms
  const searchTerms = ['responses', ...(advancedSearchEnabled ? [] : ['keywords'])].flatMap(
    (filterKey) => {
      const filterValue = searchFilters[filterKey].trim().toLowerCase();
      return filterValue ? filterValue.split(' ').filter(Boolean) : [];
    },
  );

  const {
    data: responses,
    isLoading: responsesLoading,
    refetch,
  } = useCodeFrameResponses({
    codeFrameId,
    filters: {
      page_number: pageNumber,
      page_size: pageSize,
      ...(phase !== QCPhase.SELECT_THEMES && { with_theme_ids: true }),
      ...(displayKey.type === TESelectionType.THEME && { theme_id: displayKey.key }),
      ...(displayKey.type === TESelectionType.SUGGESTED_THEME && {
        suggested_theme_id: displayKey.key,
      }),
      ...(displayKey.type === TESelectionType.SENTIMENT && {
        sentiment: displayKey.key.toLowerCase(),
      }),
      ...(searchTerms.length && !advancedSearchEnabled && { q: searchTerms.join(' ') }),
      ...(searchTerms.length && advancedSearchEnabled && { websearch: searchTerms.join(' ') }),
      ...queryFilter,
      ...querySort,
    },
  });

  const { mutate: updateSentiment } = useUpdateSentiment({ codeFrameId });
  const { mutate: tagResponses } = useTagResponses({ codeFrameId });

  useEffect(() => {
    const filterMap = {
      [ResponseFilterOptions.ALL]: undefined,
      [ResponseFilterOptions.UNTAGGED]: { theme_count: 0 },
      [ResponseFilterOptions.ONE_THEME]: { theme_count_gte: 1 },
      [ResponseFilterOptions.TWO_THEMES]: { theme_count_gte: 2 },
      [ResponseFilterOptions.THREE_THEMES]: { theme_count_gte: 3 },
      [ResponseFilterOptions.FOUR_THEMES]: { theme_count_gte: 4 },
    };

    setQueryFilter(filterMap[activeFilter]);
  }, [activeFilter]);

  useEffect(() => {
    const sortMap = {
      [ResponseSortOptions.UNSORTED]: undefined,
      [ResponseSortOptions.RANDOM]: { order: 'random' },
      [ResponseSortOptions.ALPHABETICAL]: { order: 'asc' },
    };

    setQuerySort(sortMap[activeSort]);
  }, [activeSort]);

  useEffect(() => {
    setSelectedResponseIds([]);
  }, [activeSort, activeFilter, displayKey, pageNumber, pageSize, setSelectedResponseIds]);

  const responsePercentage =
    question?.response_count > 0
      ? Math.round(
          ((responses?.response?.paging?.total_count ?? 0) / question.response_count) * 100,
        )
      : 0;

  const onCheckboxChange = (e, responseId) => {
    e.stopPropagation();
    setSelectedResponseIds((prev) =>
      e.target.checked ? [...prev, responseId] : prev.filter((id) => id !== responseId),
    );
  };

  const onCheckAllChange = (e) => {
    setSelectedResponseIds(e.target.checked ? responses.data.map((r) => r.id) : []);
  };

  const confirmRemove = () => {
    confirm({
      title: 'Are you sure you want to remove the theme from the selected responses?',
      content: 'This cannot be undone.',
      okText: 'Remove',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: () => {
        tagResponses({
          data: {
            response_ids: selectedResponseIds,
            theme_id: displayKey.key,
            code_frame_id: codeFrameId,
            is_theme: false,
          },
        });
        setSelectedResponseIds([]);
      },
    });
  };

  const extra = (
    <Space size={14}>
      {displayKey.type &&
        displayKey.type !== TESelectionType.SENTIMENT &&
        phase !== QCPhase.COMPLETE && (
          <EditButton type="secondary" onClick={() => setEditThemeModalVisible(true)}>
            <EditOutlined />
          </EditButton>
        )}
      {phase !== QCPhase.SELECT_THEMES && (
        <FilterSortDropdown
          type={FilterSortType.FILTER}
          options={ResponseFilterOptions}
          activeOption={activeFilter}
          onChange={(option) => {
            if (option !== activeFilter) {
              setActiveFilter(option);
            }
          }}
          isExpanded={!!fullScreen || isExpanded}
        />
      )}
      <FilterSortDropdown
        type={FilterSortType.SORT}
        options={ResponseSortOptions}
        activeOption={activeSort}
        onChange={(option) => {
          if (option !== activeSort) {
            setActiveSort(option);
          } else if (option === ResponseSortOptions.RANDOM) {
            refetch();
            setSelectedResponseIds([]);
          }
        }}
        isExpanded={!!fullScreen || isExpanded}
      />
      <Space>
        <StyledButton
          icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={() => setFullScreen(fullScreen ? null : ThemeEnginePanel.EXPLORE_RAW_DATA)}
        />
        {displayKey.type && (
          <StyledButton
            icon={<CloseOutlined />}
            onClick={() => setDisplayKey({ type: null, key: null })}
          />
        )}
      </Space>
    </Space>
  );

  const getTitle = () => {
    switch (displayKey.type) {
      case TESelectionType.SENTIMENT:
        return <TitleText>{displayKey.key}</TitleText>;
      case TESelectionType.THEME:
        return (
          <Flex vertical gap={2}>
            <TitleText ellipsis={{ tooltip: true }}>
              {themes.find((t) => t.id === displayKey.key).name}
            </TitleText>
            {phase === QCPhase.SELECT_THEMES && (
              <Text type="secondary">
                {themes.find((t) => t.id === displayKey.key).description}
              </Text>
            )}
          </Flex>
        );
      case TESelectionType.SUGGESTED_THEME:
        return (
          <Flex vertical gap={2}>
            <TitleText ellipsis={{ tooltip: true }}>
              {suggestedThemes.find((t) => t.id === displayKey.key).name}
            </TitleText>
            {phase === QCPhase.SELECT_THEMES && (
              <Text type="secondary">
                {suggestedThemes.find((t) => t.id === displayKey.key).description}
              </Text>
            )}
          </Flex>
        );
      default:
        return <TitleText>Explore raw data</TitleText>;
    }
  };

  return (
    <StyledCard
      size="small"
      title={getTitle()}
      extra={extra}
      loading={responsesLoading}
      data-cy="explore-raw-data"
      $color={
        (displayKey.type === TESelectionType.THEME &&
          themes.find((t) => t.id === displayKey.key).parent_theme_id &&
          themeCardBorderColors[
            themes.find((t) => t.id === displayKey.key).parent_theme_id %
              themeCardBorderColors.length
          ]) ||
        (displayKey.type === TESelectionType.SUGGESTED_THEME && suggestedThemeCardBorderColor)
      }
      actions={[
        <>
          {selectedResponseIds.length > 0 && (
            <>
              <StyledFlex justify="space-between" align="center">
                <Flex gap={4}>
                  <StyledCloseButton
                    type="text"
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => setSelectedResponseIds([])}
                  />
                  <Text type="secondary">{selectedResponseIds.length} selected</Text>
                </Flex>
                <Space>
                  {displayKey.type === TESelectionType.THEME && (
                    <Button onClick={confirmRemove}>Remove</Button>
                  )}
                  {displayKey.type !== TESelectionType.SENTIMENT && (
                    <Button onClick={() => setAddThemeModalVisible(true)}>Add to theme</Button>
                  )}
                  {displayKey.type === TESelectionType.SENTIMENT && (
                    <Button onClick={() => setEditSentimentModalVisible(true)}>
                      Edit sentiment
                    </Button>
                  )}
                </Space>
              </StyledFlex>
              <StyledDivider />
            </>
          )}
          <Pagination
            size="small"
            align="center"
            total={responses?.response.paging.total_count}
            showSizeChanger
            pageSize={pageSize}
            pageSizeOptions={[100, 250, 500]}
            onShowSizeChange={(_, size) => {
              setPageSize(size);
              setPageNumber(1);
            }}
            current={pageNumber}
            onChange={setPageNumber}
          />
        </>,
      ]}
    >
      <Flex vertical gap={8}>
        {!displayKey.type && phase !== QCPhase.SELECT_THEMES && <ThemeCoverage />}
        <StyledFlex justify="space-between" align="end">
          <StyledText type="secondary">
            {phase === QCPhase.REVIEW && <StyledCheckbox onChange={onCheckAllChange} />}
            {responses?.data.length} items
          </StyledText>
          {displayKey.type &&
            (phase === QCPhase.SELECT_THEMES ? (
              <StyledText type="secondary">
                {responsePercentage === 0
                  ? '0% of raw data'
                  : `~${PERCENTAGE_RANGES.find(({ max }) => responsePercentage <= max).label}% of raw data`}
              </StyledText>
            ) : (
              <Flex gap={6}>
                <Progress
                  type="circle"
                  percent={responsePercentage}
                  size={20}
                  strokeColor={TealProgress}
                />
                <StyledText type="secondary">{responsePercentage}% of raw data</StyledText>
              </Flex>
            ))}
        </StyledFlex>
        {responses?.data.map((response) => (
          <StyledResponseCard
            key={response.id}
            size="small"
            onClick={
              response.text.length > MAX_LENGTH ? () => setResponseToViewId(response.id) : undefined
            }
            $clickable={response.text.length > MAX_LENGTH}
          >
            <Flex vertical gap={8}>
              <Text>
                {phase === QCPhase.REVIEW && (
                  <StyledCheckbox
                    checked={selectedResponseIds.includes(response.id)}
                    onChange={(e) => onCheckboxChange(e, response.id)}
                  />
                )}
                <StyledHighlighter
                  searchWords={searchTerms}
                  textToHighlight={truncateText(response.text)}
                  highlightClassName="highlighted"
                  highlightTag="span"
                  autoEscape={!!searchTerms.length}
                />
              </Text>
              {phase !== QCPhase.SELECT_THEMES && (
                <ThemeTagList
                  phase={phase}
                  response={response}
                  themes={themes}
                  setResponseToTagId={setResponseToTagId}
                  codeFrameId={codeFrameId}
                />
              )}
            </Flex>
          </StyledResponseCard>
        ))}
      </Flex>
      <AddThemeModal
        visible={!!responseToTagId || addThemeModalVisible}
        closeModal={() => {
          if (!responseToTagId) {
            setAddThemeModalVisible(false);
          } else {
            setResponseToTagId(null);
          }
        }}
        onAdd={({ themeId }) => {
          tagResponses({
            data: {
              response_ids: responseToTagId ? [responseToTagId] : selectedResponseIds,
              theme_id: themeId,
              code_frame_id: codeFrameId,
              is_theme: true,
            },
          });
          if (!responseToTagId) {
            setSelectedResponseIds([]);
          }
        }}
        themes={themes.filter((t) => !t.is_parent)}
      />
      <ResponseDetailView
        phase={phase}
        response={responses?.data.find((r) => r.id === responseToViewId)}
        visible={!!responseToViewId}
        closeModal={() => setResponseToViewId(null)}
        themes={themes}
        setResponseToTagId={setResponseToTagId}
        codeFrameId={codeFrameId}
      />
      <EditSentimentModal
        visible={editSentimentModalVisible}
        closeModal={() => setEditSentimentModalVisible(false)}
        onEdit={(sentiment) => {
          // do not update if the sentiment is the same
          if (sentiment !== displayKey.key) {
            updateSentiment({
              data: { response_ids: selectedResponseIds, sentiment: sentiment.toLowerCase() },
            });
          }
          setSelectedResponseIds([]);
        }}
      />
      <CreateEditThemeModal
        phase={phase}
        type={displayKey.type}
        theme={
          displayKey.type === TESelectionType.THEME
            ? themes.find((t) => t.id === displayKey.key)
            : suggestedThemes.find((t) => t.id === displayKey.key)
        }
        visible={editThemeModalVisible}
        setVisible={setEditThemeModalVisible}
        onOk={handleEditTheme}
        okLoading={false}
      />
    </StyledCard>
  );
}

ExploreRawData.propTypes = {
  phase: PropTypes.string.isRequired,
  themes: PropTypes.arrayOf(themeType).isRequired,
  suggestedThemes: PropTypes.arrayOf(suggestedThemeType).isRequired,
  fullScreen: PropTypes.string,
  setFullScreen: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  displayKey: displayKeyType.isRequired,
  setDisplayKey: PropTypes.func.isRequired,
  question: questionType.isRequired,
  selectedResponseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedResponseIds: PropTypes.func.isRequired,
  searchFilters: searchFilterType.isRequired,
  advancedSearchEnabled: PropTypes.bool.isRequired,
  handleEditTheme: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
};

export default ExploreRawData;
